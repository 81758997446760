@charset "UTF-8";
@import "themes/default";

/*首页走马灯*/

.home-box {
    background-size: cover;
    position: relative;

    .ant-carousel {
        height: 100%;
        width: 100%;
    }

    .ant-carousel > div {
        height: 100%;
        width: 100%;
    }

    .slick-slider > div {
        height: 100%;
        width: 100%;
    }

    .slick-list > div {
        height: 100%;
        width: 100%;
    }

    .slick-slide {
        height: 400px !important;
        width: 100%;

        & > div {
            height: 100%;
            width: 100%;

            .home-bkg {
                height: 100%;
                width: 100%;

                .home-img-box {
                    float: left;
                    width: 100%;
                    background-position: center;
                    display: table;
                    height: 100%;
                    background-size: cover !important;
                }

                .home-inner {
                    height: 100%;

                    color: #fff;
                    padding-top: 40px;

                    vertical-align: middle;
                    background-color: rgba(0, 0, 0, 0.4);

                    .home-content {
                        max-width: 1100px;
                        margin-left: auto;
                        margin-right: auto;

                        .home-title {
                            padding: 0;
                            font-size: 42px;
                            line-height: 1.4em;
                            text-align: left;
                            color: #fff;
                        }

                        .home-sub-text {
                            padding: 5px;
                            font-weight: 300;
                            line-height: 1.8em;
                            color: rgba(255, 255, 255, 0.8);
                            text-align: left;
                            font-size: 32px;
                            color: #3aa4e8;
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }

    .home-tab {
        height: 553px;

        //.ant-tabs-nav-scroll {
        //  text-align: center;
        //}
    }
}

.home-sider-img-box {
    position: relative;
    height: 217px;
    margin: 15px;
    overflow: hidden;
    cursor: pointer;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    &:hover {
        .home-sider-tit-content {
            bottom: 0;
        }

        .home-sider-img-tit {
            transform: translateY(100%);
        }
    }
}

.home-sider-img-tit {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 44px;
    line-height: 44px;
    padding: 0 28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    transition: all 0.2s;
}

.home-sider-tit-content {
    position: absolute;
    bottom: -100%;
    height: 100%;
    width: 100%;
    padding: 5%;
    color: #ffffff;
    background-color: rgba(0, 0, 0, 0.7);
    transition: all 0.5s;

    .home-sider-tit1 {
        font-size: 18px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .home-sider-tit-p {
        font-size: 13px;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

/*首页走马灯-end*/

/*首页*/
.home-navigation {
    position: absolute;
    height: 100%;
    width: 10%;
    // top: 10%;
    z-index: 9;
    overflow: hidden;
}
.home-notice {
    .ant-tag {
        width: 71px;
        height: 32px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 32px;
        text-align: center;
        color: #666666;
        background: rgba(238, 238, 238, 1) !important;
    }
}
.home-content {
    .ant-btn {
        // width: 220px;
        &:hover {
            color: #fff;
            background-color: @primary-color;
            border-color: @primary-color;
        }
    }
}

.home-text-content {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: 100%;
    t-card {
        border: 1px solid rgba(229, 229, 229, 1);
    }
    .index-price-img {
        .images-auto {
            border-radius: 0;
        }
    }
}
.index-activity-card {
    border: 1px solid rgba(229, 229, 229, 1);
    &:hover {
        .title {
            color: @primary-color !important;
        }
    }
    &.ant-card-small > .ant-card-body {
        padding: 21px 18px;
    }
}
.home-arrow {
    display: block;
    height: 100%;
    width: 90%;
    transition: all 0.2s ease-out;
}

.home-img-prev {
    left: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0px 0px 0px 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0px 0px 0px -20%;
        font-size: 50px;
        color: #fff;
        display: flex;
        align-items: center;
    }
}

.home-img-next {
    right: 0;

    &:hover {
        .home-arrow {
            opacity: 0.5;
            margin: 0 0 0 20%;
            cursor: pointer;
        }
    }

    .home-arrow {
        opacity: 0;
        margin: 0 0 0 70%;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 50px;
        justify-content: center;
    }
}

.home-header {
    .home-description {
        font-size: 12px;
        line-height: 2;
        color: #9b9b9b;
        text-align: center;
        margin: 13px 0 0;
    }
}

.index-container {
    margin-top: 25px;
    margin-bottom: 25px;
    position: relative;
    overflow: hidden;

    .home-item {
        background-color: #fff;
        box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
        position: relative;
        overflow: hidden;
        margin: 15px;

        .home-activity-set {
            position: relative;
            height: 217px;
            margin-bottom: 2rem;
            overflow: hidden;
            cursor: pointer;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}

.home-features-title {
    font-size: 16px;
    color: #262626;
    margin: 8px 0 -10px;
    font-weight: bold;
}

.home-new {
    .newtime {
        font-size: 12px;
        position: relative;
        margin: 0;
        padding-left: 15px;

        span {
            background: #cccccc;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            position: absolute;
            left: -4px;
            top: 5px;
        }

        label {
            font-weight: normal;
            margin: 0 2% 0 2%;
            font-size: 14px;
            display: inline-block;
        }
    }

    .box-bor {
        border-left: 2px solid #cccccc;
        padding-left: 15px;
        margin-top: -6px;
        padding-top: 15px;

        .home-news-title {
            color: #333333;
            font-size: 18px;
        }

        .home-news-text {
            color: #9e9e9e;
            font-size: 14px;
        }
    }
}

.home-notice {
    position: relative;
    overflow: hidden;
    height: 64px;
    line-height: 64px;
    background-color: #f9f9f9;

    .home-notice-swiper {
        height: 64px;
        width: 100%;
        .swiper-slide {
            text-align: left;
            line-height: 64px;
            font-size: 16px;
        }
    }
}

.home-body {
    position: relative;
    background-color: #fff;
}

.home-content {
    position: relative;
    overflow: hidden;
    margin-top: 48px;
}

.homt-title {
    position: relative;
    overflow: hidden;
    text-align: center;
    font-size: 36px;
    color: #000000;
    line-height: 34px;
    padding: 40px 0 40px;
    margin-bottom: 40px;
    &::after {
        position: absolute;
        content: "";
        width: 42px;
        border-radius: 2px;
        height: 4px;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #2289cb;
        bottom: 16px;
    }
}

.home-service-list {
    position: relative;
    width: 1200px;
    height: 180px;
    display: flex;
    justify-content: space-around;
}

.home-service-row {
    position: relative;
    display: flex;
    align-items: center;
    height: 122px;
    width: 280px;
    border-radius: 4px;
    color: #333;
    margin-bottom: 30px;
    border: 1px solid #e5e5e5;
    transition: all 0.3s;
    // cursor: pointer;
    > a {
        padding: 20px 25px;
        color: #333;
        display: block;
        width: 100%;
        height: 100%;
        &:hover {
            color: #fff;
        }
    }

    > div {
        width: 100%;
    }

    .size-20 {
        line-height: 1;
    }

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: @primary-color;
        color: #fff;
    }

    .home-service-a {
        cursor: pointer !important;
        background-color: #2eaaf9;
        width: 40px;
        height: 18px;
        line-height: 12px;
        font-size: 14px;
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.home-service-title {
    position: relative;
    overflow: hidden;
    padding: 15px 20px;

    a {
        height: 19px;
        width: 19px;
        text-align: center;
        display: block;
        border: solid 1px #d2d2d2;
        line-height: 11px;

        i {
            font-size: 14px;
        }
    }

    &::after {
        content: "";
        position: absolute;
        height: 16px;
        width: 4px;
        background-color: #1890ff;
        border-radius: 15px;
        left: 4px;
        top: 24px;
    }
}

.swiper-home-politique {
    height: 420px;

    .swiper-slide {
        position: relative;
        width: auto;
        border-bottom: solid 1px #e8e8e8;
        // cursor: pointer;
        transition: all 0.3s;
        &:last-child {
            border-bottom: none;
        }

        &:hover {
            h4 {
                color: @primary-color;
            }
        }

        .ant-row {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            transform: translate(0, -50%);
        }

        .ant-tag {
            transition: none;
            height: 26px;
            line-height: 26px;
            font-size: 14px;
            color: #0e0e0e;
            border: navajowhite;
            width: auto !important;
            background-color: rgba(229, 229, 229, 1);
            &.ant-tag-has-color {
                color: #fff;
            }
        }
    }
}

.home-date-box {
    border-radius: 5px;
    background-color: #fafafa;
    width: 73px;
    height: 73px;
    text-align: center;

    > div {
        height: 100%;
    }

    .day {
        color: #000;
        line-height: 1.1;
    }
    &.type2 {
        background-color: #9fc9b4;
        color: rgba(255, 255, 255, 0.85);
        .day {
            color: rgba(255, 255, 255, 0.85);
        }
    }
}

.home-park {
    .ant-list-split {
        .ant-list-items {
            a {
                &:last-child {
                    .ant-list-item {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .ant-list-item {
            padding: 12px;
            border-bottom: 0;
            cursor: pointer;
            margin-bottom: 35px;
            border-radius: 4px;

            .ant-typography {
                line-height: 28px;
                height: 86px;
            }

            .ant-list-item-meta-title {
                font-size: 22px;
                line-height: 25px;
                height: 50px;
                color: #000;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                margin-bottom: 0;
            }

            &:hover {
                .ant-list-item-meta-title {
                    color: @primary-color !important;
                }
            }
        }
    }
}
.home-dynamic-icon {
    line-height: 14px;
    i {
        vertical-align: bottom;
    }
}
.enterprises-list {
    position: relative;
    text-align: center;
    height: 245px;
}

.enterprises-list-row {
    display: inline-block;
    vertical-align: middle;
    width: 165px;
    text-align: center;
    transition: all 0.3s;
    height: 215px;
    padding-top: 12px;

    &:hover {
        padding: 0;

        i {
            background-color: @primary-color;
            color: #fff;
            height: 160px;
            width: 160px;
        }
        span {
            margin-top: 10px;
        }
    }

    a {
        display: block;
        width: 100%;
        height: 100%;
    }

    i {
        position: relative;
        display: inline-block;
        width: 120px;
        height: 120px;
        background-color: #fafafa;
        border-radius: 50%;
        font-size: 40px;
        color: #333;
        transition: all 0.3s;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    span {
        display: block;
        line-height: 21px;
        font-size: 22px;
        margin-top: 30px;
        color: #000;
        transition: all 0.3s;

        &::before {
            content: "";
            position: relative;
            top: -5px;
            margin: 0 auto;
            width: 10px;
            height: 2px;
            background-color: @primary-color;
            display: none;
        }
    }
}
.notice-title {
    position: relative;
    height: 64px;
    line-height: 64px;
    width: 125px;
    color: #010101;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        height: 24px;
        width: 1px;
        background-color: #8c8c8c;
        transform: translate(-50%, -50%);
    }
}
.home-modal {
    .ant-modal-content {
        height: 100%;
        .ant-modal-body {
            height: calc(~"100% - 108px");
            overflow: auto;
            &::-webkit-scrollbar {
                width: 9px;
                height: 9px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0.1);
                -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
            }
            &::-webkit-scrollbar-track {
                border-radius: 8px;
                background-color: rgba(0, 0, 0, 0);
            }
        }
    }
}
@media (max-width: 1366px) {
    .home-box {
        zoom: 0.8;
    }
}

.a-title {
    color: black;
    font-size: 18px;

    &:hover {
        color: @primary-color;
    }
}

/*首页 -- end*/
