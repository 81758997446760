@import "themes/default";

.phone-menu{
    background-color: @primary-color;
    color:@white-color;
    border-radius: 0 4px 4px 0;
    position: fixed;
    left: 0;
    top:50%;
    transform: translateY(-50%);
    z-index: 100;
    height: 36px;
    width: 20px;
    text-align: center;
    line-height: 36px;
    display: none;
}
@media (max-width: 1200px) {
    .view.container-page.slideInRight{
        animation-name: none;
    }
    html > body{
        min-width: 0;
        -webkit-overflow-scrolling: touch;
    }
    .root{
        overflow-x: hidden;
    }
    .container{
        max-width: none;
        min-width: 0;
    }
    .anchor,.home-ancre{
        display: none;
    }
    .home-head{
        z-index: 100;
        .header-content{
            border-image: none;
            border-bottom: rgba(255,255,255,0.41) solid 1px;
            padding: 5px 15px;
        }
        .home-logo{
            height: 22px;
            img{
                height: 100%;
            }
        }
        .head-info{
            a{
                color:@white-color;
            }
            span{
                color:@white-color;
            }
        }
        .header-mobile-menu{
            display: none;
        }
        .second-header .header-row{
            padding-right: 0;
        }
    }
    .head-info{
        *{
            vertical-align: top;
            font-size: 13px;
        }
        .ant-col-12{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .info-name{
            display: inline-block;
            width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .margin-right{
            margin-right: 10px !important;
        }
        .icon{
            font-size: 13px;
            margin-right: 2px !important;
        }
        .user-info{
            >a {
                .icon, span{
                    vertical-align: middle;
                }
            }
        }
    }
    .header-row{
        min-height: 36px !important;
    }
    .home-nav-page{
        padding-top: 70px;
        height: 410px;
        .ant-col-xs-24{
            padding: 30px 15px 0 !important;
        }
        .ant-col-24{
            padding: 20px 0 !important;
        }
    }
    .home-search {
        border-radius: 8px;
        .ant-select{
            font-size: 13px;
        }
        .ant-input-lg{
            font-size: 13px;
            height: 35px;
        }
        .ant-input-search-large .ant-input-search-button{
            height: 35px;
        }
    }
    .hot-tips{
        margin-top: 12px !important;
        font-size: 13px !important;
    }
    .ant-row.grid-nav{
        margin: 0 -8px !important;
        padding: 0 15px;
        .ant-col-xs-6{
            padding: 5px 8px !important;
        }
        .icon-nav{
            width: 74px;
            height: 74px;
            margin: 0 auto;
        }
        .iocnimg{
            width: 25px;
            height: 20px;
            img{
                max-height: 100%;
            }
        }
        .size-18{
            font-size: 12px !important;
        }
    }
    .project-content{
        margin-top: -40px;
        padding: 0 15px;
        .ant-row{
            margin: 0 -7px !important;
            .ant-col-xs-12{
                padding: 0 7px !important;
            }
            .ant-col{
                &:before{
                    width: 12px;
                    height: 10px;
                    margin-top: -5px;
                }
                &:after{
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: -15px;
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-top: 8px solid transparent;
                    border-left: 16px solid #a4cdfe;
                    border-bottom: 8px solid transparent;
                    z-index: 1;
                    margin-top: -8px;
                }
                &:nth-child(2){
                    &:before{
                        top:auto;
                        bottom:10px;
                        left: 50%;
                        transform: rotate(90deg);
                    }
                    &:after{
                        top:auto;
                        bottom:-5px;
                        left: 50%;
                        transform: rotate(90deg);
                        margin-left: -2px;
                    }
                }
                &:nth-child(3){
                    &:before{
                        right:-11px;
                    }
                    &:after{
                        transform: rotate(180deg);
                        right:1px;
                    }
                }
            }
        }
        .row{
            padding: 18px 5px 0 10px;
            border-radius: 24px;
            height: 180px;
            margin-bottom: 10px;
            &:after{
                height: 5px;
                border-radius: 0 0 24px 24px;
            }
            &.type1{
                background-position: 80px -20px;
                background-size: 90px auto;
            }
            &.type2{
                background-position: 110px -10px;
                background-size: 90px auto;
            }
            &.type3{
                background-position: 110px -10px;
                background-size: 90px auto;
            }
            &.type4{
                background-position: 100px -20px;
                background-size: 90px auto;
            }
        }
        .nmb{
            font-size: 26px;
        }
        .text1{
            font-size: 12px;
        }
        .title{
            font-size: 14px;
        }
        .lable{
            font-size: 12px;
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            margin: 0 4px 4px 0;
        }
    }
    .space-text{
        width: 50px;
        height: 42px;
        font-size: 14px;
        padding: 0 6px;
        background-size: 100% auto !important;
        line-height: 1.2;
        text-align: center;
    }
    .ant-row.demand-row{
        padding: 0 15px;
        > .ant-col{
            margin-bottom: 10px;
        }
        .ant-row{
            margin: 0 -5px !important;
            .ant-col{
                padding: 0 5px !important;
            }
        }
        .size-16{
            font-size: 14px !important;
        }
        .ant-col-20{
            .ant-row{
                display: none;
                &:first-child{
                    display: flex;
                }
            }
        }
        .omit-1 {
            -webkit-line-clamp: 2;
            line-height: 1.3;
        }
    }
    .home-content{
        margin-top: 30px;
    }
    .index-title{
        padding-top: 30px;
    }
    .index-page.type1{
        background-image: none;
        background-color: #fafafb;
        height: auto;
        padding-bottom: 30px;
        .home-content{
            margin-bottom: 0 !important;
        }
        .list-box{
            .images-auto{
                height: 90px !important;
            }
            .img .way{
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                padding: 0 8px;
            }
            .pg20{
                padding: 8px 10px !important;
            }
            .size-18{
                font-size: 14px !important;
            }
            .size-16{
                font-size: 12px !important;
            }
            .pv10{
                padding-top: 4px !important;
                padding-bottom: 2px !important;
            }
            .lable-text{
                height: 20px;
                line-height: 20px;
                padding: 0 5px;
                font-size: 12px;
                margin: 0 3px 3px 0;
            }
        }
    }
    body{
        .ant-tabs.my-tabs {
            padding: 0 15px;
            .ant-tabs-tabpane {
                > .ant-row{
                    margin: 0 -6px !important;
                    > .ant-col{
                        padding: 0 6px !important;
                    }
                    &.mv40{
                        margin-top: 15px !important;
                        margin-bottom: 15px !important;
                        .ant-col{
                            padding: 0 !important;
                        }
                    }
                }
            }
            .ant-tabs-nav .ant-tabs-tab{
                font-size: 14px;
                margin-right: 24px;
                padding: 6px 0;
            }
            .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar{
                height: 2px;
            }
        }
    }
    .index-title{
        span{
            font-size: 18px;
        }
        em{
            font-size: 18px;
            top:-10px;
            letter-spacing: 10px;
        }
    }
    .home-btn.ant-btn{
        padding: 0;
        height: 30px;
        line-height: 28px;
        font-size: 14px;
        color:@primary-color;
        border-color:@primary-color;
        background-color: transparent;
    }
    #page2{
        .home-content{
            margin-top: 0;
        }
        .list-box{
            margin-bottom: 16px;
            > .ant-row{
                margin: 0 -6px !important;
                > .ant-col{
                    padding: 0 6px !important;
                }
            }
            .img .way{
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                padding: 0 8px;
            }
            .images-auto{
                height: 75px !important;
            }
            .size-18{
                font-size: 14px !important;
            }
            .size-16{
                font-size: 12px !important;
            }
            .icon{
                font-size: 12px !important;
            }
            .pv10{
                padding-top: 4px !important;
                padding-bottom: 2px !important;
            }
            .lable-text{
                height: 20px;
                line-height: 20px;
                padding: 0 5px;
                font-size: 12px;
                margin: 0 3px 3px 0;
            }
        }
    }
    .index-page.type2{
        background-color: #edf5f5;
        margin-top: 20px;
        height: auto;
        padding-bottom: 10px;
        .index-title em{
            color:rgba(43,179,177,0.3);
        }
        .mv40{
            margin-top: 15px !important;
            margin-bottom: 15px !important;
        }
        .home-btn.ant-btn{
            color:#2bb3b1;
            border-color:#2bb3b1;
        }
    }
    .home-demand-wd{
        width: 100%;
        overflow-y:hidden;
        overflow-x: auto;
    }
    .home-demand-list{
        padding: 0 9px;
        &.ant-row{
            flex-wrap: nowrap;
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
        .ant-col-12 {
            display: block;
            flex: 0 0 85%;
            max-width: 85%;
            padding: 0 6px !important;
        }
    }
    .shadw-box{
        padding: 15px 15px;
        height: 140px;
        border-radius: 16px 0 16px 0;
        box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.09);
        .size-20 {
            font-size: 14px !important;
        }
        .size-16 {
            font-size: 13px !important;
            color:@gray-two-color;
        }
        .ant-row{
            margin: 0 -5px !important;
            .ant-col{
                padding: 0 5px !important;
            }
        }
        .lable-text{
            height: 20px;
            line-height: 20px;
            padding: 0 5px;
            font-size: 12px;
            margin: 0;
            text-align: center;
            display: block;
        }
        .ant-tag{
            width: 100% !important;
        }
        .blank20 {
            height: 15px !important;
        }
        .home-demand-type{
            .size-16 {
                font-size: 12px !important;
            }
        }
    }
    #page4{
        .home-content{
            margin-top: 0;
        }
        .mv40{
            margin-top: 15px !important;
            margin-bottom: 15px !important;
        }
    }
    .ant-carousel{
        .news-carousel{
            margin-bottom: 15px;
            .news-img{
                .images-auto{
                    height: 200px !important;
                    img{
                        display: none;
                    }
                }
                .title{
                    height: 38px;
                    line-height: 38px;
                    font-size: 14px;
                    padding: 0 15px;
                }
            }
           .slick-dots-bottom{
               bottom:46px;
               left: auto;
               margin: 0;
           }
            .slick-dots li{
                width: 10px;
                height: 5px;
                button{
                    height: 5px;
                    opacity: .6;
                }
            }
        }
    }
    .home-date-box{
        width: 100%;
        height: 50px;
        border-radius: 2px;
        .size-30 {
            font-size: 20px !important;
        }
        .size-14 {
            font-size: 12px !important;
            margin-top: 3px;
        }
    }
    body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row > .ant-col{
        &.news-item{
            padding: 15px 6px !important;
        }
    }
    .news-item{
        padding: 15px 0 !important;
        border-bottom: #f9f9f9 solid 1px;
        &:last-child{
            border-bottom: none;
        }
        > .ant-row{
            margin: 0 -6px !important;
            > .ant-col{
                padding: 0 6px !important;
            }
        }
        .size-20 {
            font-size: 14px !important;
        }
        .size-14.text {
            font-size: 12px !important;
        }
        &.ant-col-20{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row{
        &.news-list-row{
            padding-top: 0;
        }
    }
    .news-list-row{
        padding-top: 30px;
    }
    body .index-page.type3 .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row > .ant-col{
        padding: 0 !important;
    }
    body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row{
        &.recommend-activity{
            margin: 0 !important;
        }
    }
    .index-page.type3{
        background: url("../images/hd-bg.jpg") no-repeat center top;
        background-size: 100% 360px;
        height: auto;
        .recommend-activity{
            .images-auto{
                height: 170px !important;
            }
        }
        .recommend-write{
            padding: 10px 15px 15px;
            height: auto;
            .size-16{
                font-size: 12px !important;
            }
            .recommend-title{
                margin-top: 8px;
                font-size: 14px;
            }
            .recommend-addr{
                margin-top: 5px;
            }
            .margin-top{
                margin-top: 10px !important;
            }
        }
        .recommend-time{
            margin-top: 5px;
            .time-item{
                margin-right: 20px;
                &:after{
                    top: 8px;
                    right: -12px;
                }
                .time-number{
                    width: 45px;
                    height: 45px;
                    line-height: 45px;
                    font-size: 16px;
                }
                .time-write{
                    font-size: 12px;
                }
            }
        }
        .recommend-btn .ant-btn{
            height: 32px;
            line-height: 32px;
            padding: 0 15px;
            font-size: 14px;
        }
    }
    .activity-list.ant-list-split{
        .ant-list-item{
            padding: 10px;
            position: relative;
            line-height: 1.3;
            box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09);
            border:none;
        }
        .activity-date{
            font-size: 12px;
            margin-right: 10px;
            padding-right: 10px;
            span{
                font-size: 20px;
            }
            &:after{
                height: 52px;
            }
        }
        .ant-list-item-meta-title{
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 1.5;
        }
        .activity-describe{
            margin-top: 6px;
            font-size: 12px;
            line-height: 1.4;
            &.ant-row{
                margin:0 !important;
                .ant-col{
                    padding: 0 !important;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
            .icon{
                font-size: 12px !important;
                margin-right: 2px !important;
            }
        }
        .ant-btn{
            font-size: 12px;
            height: 22px;
            line-height: 20px;
            padding: 0 4px;
        }
    }
    .footer{
        padding: 10px 15px;
        font-size: 12px;
        text-align: left;
        .ant-text-center{
            text-align: left;
        }
    }
    .ant-back-top.home-backTop{
        right:10px;
        top:auto;
        bottom:20px;
    }
    //导航菜单
    .index-header{
        display: block;
    }
    .header-mobile-menu {
        width: 16px;
        height: 48px;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 10px;
        z-index: 102;
        display: block;
        padding-top: 16px;
        em {
            display: block;
            width: 100%;
            height: 2px;
            background: #001529;
            margin-top: 4px;
            transition: transform .3s cubic-bezier(.645,.045,.355,1),opacity .3s cubic-bezier(.645,.045,.355,1)
        }
        :first-child {
            margin-top: 0
        }
    }
    .second-header{
        height: 50px;
        .header-content{
            padding:0 15px;
            border-left-width:0;
            border-right-width:0;
            border-bottom-width:0;
            border-top-width: 2px;
            position: relative;
        }
        .logo{
            height: 22px;
            img{
                height: 100%;
            }
        }
        .search-box{
            display: none;
        }
        .header-row{
            height: 48px;
            padding-right: 20px;
        }
        &.open{
            .header-mobile-menu em:nth-child(1) {
                transform: translateY(6px) rotate(45deg)
            }
            .header-mobile-menu em:nth-child(3) {
                transform: translateY(-6px) rotate(-45deg)
            }
            .header-mobile-menu em:nth-child(2) {
                opacity: 0
            }
            .nav{
                height: auto;
                opacity: 1;
            }
        }
        .ant-affix{
            position: static !important;
            width: auto !important;
            height: auto !important;
        }
    }
    .nav{
        height: 0;
        position: absolute;
        top:50px;
        left: 0;
        width: 100%;
        opacity: 0;
        transition: opacity .3s cubic-bezier(.645,.045,.355,1);
        z-index: 101;
        overflow: hidden;
        a{
            font-size: 14px;
        }
        .ant-menu-item, .ant-menu-submenu-title{
            font-size: 14px;
            text-align: left;
            margin: 0;
        }
        .ant-menu-inline .ant-menu-item{
            margin: 0;
        }
        .ant-menu-inline .ant-menu-item:not(:last-child){
            margin-bottom: 0;
        }
        .ant-menu-inline .ant-menu-submenu-title{
            margin: 0;
        }
    }
    .banner-box{
        height: 170px;
        img{
            max-width: 50%;
        }
    }
    .crumb-box{
        padding: 5px 15px;
        .ant-breadcrumb{
            font-size: 13px;
        }
    }
    .page-components .page-components-rows{
        min-height: 52px;
        .page-components-rows-label{
            width: 80px;
            line-height: 1.2;
            .condition-name{
                font-size: 13px;
            }
        }
        &::after {
            width: 80px;
        }
        .page-components-rows-content{
            max-height: none !important;
            padding: 0 10px;
            .page-components-rows-select{
                margin-left: 0;
                padding: 5px 0;
            }
        }
        .ant-input-search {
            width: 100% !important;
            .ant-input{
                height: 30px;
                font-size: 13px;
            }
            .ant-btn{
                height: 30px;
            }
        }
    }
    .classify {
        height: 22px;
        line-height: 22px;
        margin: 0 5px 5px 0;
        padding: 0 10px;
        font-size: 12px;
        background: #f5f5f5;
    }
    .page-header{
        margin: 15px 0;
        padding: 0 15px;
        .ant-col-12{
            flex: 0 0 100%;
            max-width: 100%;
        }
        .ant-btn{
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            padding: 0 10px;
        }
    }
    .sortBox{
        font-size: 12px;
        .sortButton{
            height: 30px;
            line-height: 30px;
            padding: 0 10px;
        }
    }
    .general-list{
        margin: 0 !important;
        .list-box{
            .img{
                .images-auto{
                    height: 180px !important;
                }
            }
            .pg20{
                padding: 12px 15px !important;
            }
            .size-18 {
                font-size: 14px !important;
            }
            .size-16 {
                font-size: 12px !important;
            }
            .icon{
                font-size: 12px;
            }
            .lable-text{
                height: 20px;
                line-height: 20px;
                padding: 0 5px;
                font-size: 12px;
                margin: 0 5px 0 0;
                text-align: center;
            }
            .nmbtext{
                font-size: 14px;
            }
        }
        &.ant-list{
            padding: 0 15px;
            .ant-card.list-box{
                height: auto !important;
                border-radius: 4px;
                .list-title .ant-typography{
                    font-size: 16px;
                    margin-bottom: 5px !important;
                }
            }
        }
    }
    .paging,.list-paging{
        margin: 15px 0 30px;
        .ant-pagination {
            .ant-pagination-item{
                min-width: 26px;
                height: 26px;
                line-height: 24px;
                margin-right: 5px;
            }
            .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
                min-width: 26px;
                height: 26px;
                line-height: 24px;
                margin-right: 5px;
            }
            .ant-pagination-next,.ant-pagination-jump-next{
                margin-right: 0;
            }
        }
        &.mt30{
            margin-top: 15px !important;
        }
    }
    .home-ancre.not-space{
        display: block;
        right: 10px !important;
        top: 225px !important;
        .ant-btn{
            height: 28px;
            line-height: 28px;
            font-size: 12px;
            padding: 0 10px;
        }
    }
    .form-divider-text-size .ant-divider-inner-text{
        font-size: 18px;
    }
    .details-html{
        .details-header{
            margin: 0 15px;
            .images-auto{
                height: 200px !important;
            }
            .ant-card.ant-card-fill{
                margin-left: 0 !important;
            }
            h4.ant-typography, .ant-typography h4{
                font-size: 16px;
            }
            .size-16{
                font-size: 14px !important;
            }
            .size-14 {
                font-size: 13px !important;
                .mt8{
                    margin-top: 2px !important;
                }
            }
            .ant-col-xs-24{
                margin-left: 0 !important;
            }
            .ant-btn{
                height: 28px;
                line-height: 28px;
                font-size: 12px;
                padding: 0 10px;
            }
            .gutter-row:last-child{
                padding-bottom: 15px;
            }
        }
        .ant-card-head{
            min-height: 0;
            .ant-card-head-title {
                font-size: 16px;
                padding: 0 16px 10px 12px !important;
                &::after {
                    top: 6px;
                    height: 14px;
                }
            }
        }
        .ant-card.details-content{
            margin: 0 15px;
            >.ant-card-body{
                padding: 0 0 10px 0;
            }
            .list-box{
                border:none;
            }
        }
    }
    .collect-badge{
        margin: 0 5px;
    }
    .collect-badge,.share-badge{
        font-size: 12px;
        .icon{
            font-size: 12px;
            margin-right: 3px !important;
        }
    }
    body .ant-list-item-meta-title{
        font-size: 16px;
    }
    .list-commonly{
        div.ant-typography, .ant-typography p{
            margin-bottom: 0;
        }
        p{
            margin: 0;
            &.pt18{
                padding-top: 5px !important;
            }
        }
    }
    .ant-card.card-p10{
        padding: 0 15px;
        > .ant-card-body{
            padding: 0 0 12px 0;
        }
        .ant-list-item{
            .ant-card-body{
                padding: 10px 12px;
            }
        }
    }
    .list-box1{
        .ant-list-item{
            &:first-child{
                padding-top: 0;
            }
        }
        .title{
            font-size: 16px;
        }
        .size-16{
            margin-top: 0 !important;
            font-size: 14px !important;
            color:@gray-two-color;
        }
    }
    .aptitude-box{
        .ant-col{
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .ant-tag{
        margin-right: 5px;
    }
    .hot-list{
        .ant-card-body{
            padding: 0 !important;
        }
        .list-box{
            border:none;
            .img{
                .images-auto{
                    height: 180px !important;
                }
            }
            .pg20{
                padding: 12px 15px !important;
            }
            .size-18 {
                font-size: 14px !important;
            }
            .size-16 {
                font-size: 12px !important;
            }
            .icon{
                font-size: 12px;
            }
            .lable-text{
                height: 20px;
                line-height: 20px;
                padding: 0 5px;
                font-size: 12px;
                margin: 0 5px 0 0;
                text-align: center;
            }
            .nmbtext{
                font-size: 14px;
            }
        }
    }
    .ant-steps-vertical > .ant-steps-item{
        .ant-steps-item-title{
            line-height: 24px !important;
            font-size: 16px;
        }
        .ant-steps-item-icon{
            width: 24px;
            height: 24px;
            line-height: 24px;
        }
        .ant-steps-item-content{
            min-height: 36px !important;
        }
    }
    .phone-v{
        padding: 0 15px;
    }
    body .ant-list-vertical .ant-list-item-meta-title{
        font-size: 16px;
    }
    .info-article-name{
        font-size: 18px;
    }
    .success-content{
        margin: 30px 0;
        .success-name{
            font-size: 18px;
        }
    }
    body .ant-card-head{
        font-size: 16px;
    }
    .demand-container .page-components .page-components-rows .page-components-rows-content{
        max-height: none !important;
    }
    .demand-list{
        margin: 15px !important;
        &:first-child{
            margin-top: 0 !important;
        }
        .status{
            .ant-tag{
                margin-right: 0;
            }
        }
        .describe-three .ant-typography{
            height: auto;
        }
        .demand-title{
            font-size: 16px;
        }
        .demand-footer {
            margin: 0 !important;
            .demand-button {
                margin-right: 0;
                &.ant-btn{
                    height: 28px;
                    line-height: 28px;
                    font-size: 12px;
                    padding: 0 10px;
                }
            }
            .ant-col-21 {
                padding-left: 0 !important;
            }
        }
    }
    .list-box2{
        padding: 15px;
    }
    .scoll-top .ant-back-top{
        right:10px;
    }
    .card-tag{
        height: 22px;
        line-height: 22px;
        padding: 0 5px;
        font-size: 12px;
    }
    .check-map{
        height: 24px;
        line-height: 20px;
        padding: 0 5px;
        font-size: 12px;
        width: auto;
    }
    .s-mv15{
        margin: 0 15px !important;
    }
    .popular-content.ant-card{
        padding: 0 15px;
    }
    .demand-details {
        height:auto !important;
        padding-left: 15px !important;
    }
    .expert-list-item{
        .ant-card{
            height: auto !important;
            width: auto !important;
            margin: 0 15px !important;
        }
        .ant-card-body{
            .expert-name{
                font-size: 16px;
            }
            .expert-desc{
                margin-top: 6px;
            }
        }
    }
    .details-html {
        .expert-details{
            padding: 15px !important;
            .images-auto{
                width: 100% !important;
            }
            .expert-main{
                margin-left: 0;
                width: auto;
                height: auto;
                padding-bottom: 28px;
                .expert-tags{
                    flex-wrap: wrap;
                    margin: 15px 0;
                }
            }
            .expert-button {
                right: 20px;
                bottom:15px;
            }
        }
    }
    .index-price-img i{
        font-size: 13px;
        width: 50px;
        height: 50px;
        line-height: 50px;
    }
    .details-html .details-header .index-price-img, .details-html .details-header .price-img {
        min-height: 200px;
    }
    .details-intro-header .rows em{
        margin-right: 5px;
    }
    .ant-list.s-list-horizontal{
        .ant-steps{
            .ant-list-item{
                padding: 15px 0;
            }
        }
        &.body-v{
            .ant-list-item{
                padding: 12px 0;
            }
        }
        .ant-list-item{
            padding: 15px;
        }
        .ant-list-item-meta{
            display: block;
            .ant-list-item-meta-title{
                margin: 5px 0;
            }
            .ant-list-item-meta-avatar{
                margin-right: 0;
            }
            .images-auto{
                width: 100% !important;
            }
        }
        .ant-list-item-meta-content{
            width: 100%;
            .mr30{
                margin-right: 15px !important;
            }
            .icon.mr10{
                font-size: 14px !important;
                margin-right: 2px !important;
            }
            .ant-row.mt12{
                margin-top: 5px !important;
            }
        }
        .ant-list-item-meta-description{
            .ant-tag{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }
    .info-comment{
        .ant-avatar-image{
            width: 40px !important;
            height: 40px !important;
            line-height: 40px !important;
        }
    }
    .comment-box .comment-submit .ant-btn{
        height: 24px;
        font-size: 13px;
    }
    .info-article-type{
        margin-top: 10px;
        .info-type-date{
            >span{
                display: block;
            }
        }
    }
    .info-type-operate{
        &.mt10{
            margin-top: 0 !important;
        }
        span{
            margin-left: 8px;
        }
    }
    .info-second-title{
        font-size: 16px;
        color:#000;
        &:before {
            width: 4px;
            height: 16px;
            border-radius: 2px;
        }
    }
    .hot-title{
        font-size: 16px;
    }
    .popular-content .ant-card-head .ant-card-head-title{
        font-size: 16px;
        padding: 0 16px 10px 12px !important;
    }
    .custom-table{
        overflow-x: auto;
        width: 100%;
        .ant-table{
            min-width: 750px;
            border:none;
            font-size: 14px;
        }
        .ant-table-thead > tr > th{
            padding: 10px;
        }
        .ant-table-tbody > tr > td{
            padding: 10px;
        }
    }
    .info-details{
        .info-article-type{
            &.mt30{
                margin-top: 10px !important;
            }
            .info-article-item{
                margin-bottom: 10px;
            }
        }
    }
    .ant-card.info-details{
        margin: 0 15px;
    }
    .info-article{
       &.mt46{
           margin-top: 0 !important;
       }
    }
    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
        padding: 5px 10px;
        font-size: 14px;
    }
    .info-article-page .ant-typography{
        font-size: 14px;
    }
    .ant-list.circle-list{
        .ant-list-item{
            padding: 15px !important;
            .images-auto{
                width: 100px !important;
                height: 80px !important;
            }
        }
        &.ant-list-vertical .ant-list-item-meta-title{
            margin-bottom: 5px;
        }
        &.ant-list-vertical .ant-list-item-extra{
            margin-top: 0;
            .ant-btn-sm{
                font-size: 12px;
            }
        }
        .circle-type{
            width: 100% !important;
        }
        .ant-list-item-meta-description{
            font-size: 12px;
        }
    }
    .circle-operation{
        .icon{
            font-size: 14px;
        }
        .mr30{
            margin-right: 15px !important;
        }
    }
    .circle-comment-img{
        .ant-col{
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
    .circle-comment{
        .ant-avatar-image{
            width: 40px !important;
            height: 40px !important;
        }
        .circle-comment.pinglun-box .ant-comment-actions > li:last-child{
            position: static;
            font-size: 13px;
        }
    }
    .pinglun-box .ant-comment-content-author{
        padding-right: 80px;
    }
    .document-list{
        margin: 15px !important;
        .ant-col{
            padding-top: 0 !important;
        }
    }
    .list-box2{
        .title{
            font-size: 16px;
        }
        .text1{
            font-size: 14px;
            padding: 0;
            margin: 5px 0;
        }
        .time{
            font-size: 14px;
            em{
                font-style: normal;
            }
        }
    }
    .document-title{
        h2{
            font-size: 18px;
        }
        .document-type{
            margin-top: 10px !important;
            .ant-col{
                &:nth-child(4){
                    margin-top: 10px !important;
                    .ant-btn{
                        height: 28px;
                        line-height: 28px;
                        font-size: 12px;
                        padding: 0 10px;
                    }
                }
            }
        }
    }
    .ant-list.list-ph0{
        .ant-list-item{
            padding: 15px 0;
        }
    }
    .ant-menu.my-menu{
        + .blank16{
            height: 5px !important;
        }
        .ant-menu-item{
            margin: 0 !important;
        }
    }
    .menu-drawer{
        .ant-drawer-body{
            padding: 15px;
        }
    }
    .ant-affix{
        z-index: 101;
        .second-header{
            .header-content{
                box-shadow: 0 0 6px rgba(0,0,0,0.3);
            }
        }
    }
    .home-head{
        .ant-affix{
            .second-header{
                .header-content{
                    background-color: rgba(92,151,254,0.9);
                    box-shadow: none;
                }
            }
        }
    }
    .phone-menu{
        display: inline-block;
    }
    body .ant-card-head-tabs .ant-tabs-tab{
        font-size: 16px !important;
    }
    .swiper-img{
        .ant-col-4{
            flex: 0 0 33.33333333%;
            max-width: 33.33333333%;
            .images-auto{
                height: 90px !important;
            }
        }
    }
    .search-col{
        position: static !important;
        margin-bottom: 15px;
    }
    .ant-typography ul li, .ant-typography ol li{
        margin-left: 5px !important;
    }
    .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon{
        margin-right: 12px !important;
    }
    .list-loose3 .ant-list-item-meta-title .ant-typography{
        font-size: 16px;
    }
    .agree-modal{
        .ant-modal{
            width: 90% !important;
        }
    }
    .ant-form-item{
        margin-bottom: 16px !important;
    }
    .register-box{
        .ant-checkbox{
            + span{
                font-size: 13px;
            }
        }
    }
    .login-box{
        video{
            display: none;
        }
        .login-content-box{
            width: 95%;
            z-index: 10;
        }
    }
    .content{
        min-height: calc(~'100vh - 166px');
    }
    .header-affix{
        .ant-affix{
            z-index: 101;
        }
    }
    .custom-tabs{
        padding: 0 10px;        
    }    
    .tag-tabs-item{
        font-size: 12px;
        height: 60px;
        line-height: 60px;
        border-radius: 5px;
    }
    .platform-result{
        padding: 0 15px;
        &.size-18{
            font-size: 16px !important;
        }
    }
    .platform-row{
        .ant-list{
            margin: 0 15px;
        }
        .size-18{
            font-size: 16px !important;
        }
        .size-15{
            font-size: 13px !important;
        }
        &.mt15{
            margin-top: 6px !important;
        }
        .ant-list.list-horizontal .ant-list-item{
            padding: 10px 15px;
        }
    }
    .white-page{
        margin: 0 15px !important;
    }
    .ant-card.platform-news-card{
        &.card-margin{
            margin: 15px;
        }
    }
}

@media (max-width: 350px) {
    .second-header .logo{
        height: 18px;
    }
    .head-info{
        * {
            font-size: 12px;
        }
        .info-name{
            width: 90px;
        }
        .margin-right{
            margin-right: 5px !important;
        }
        .icon{
            margin-right: 0 !important;
        }
    }
    .ant-row.grid-nav .icon-nav{
        width: 60px;
        height: 60px;
    }
    .second-header{
        .head-info .info-name{
            width: 80px;
        }
    }
}
