.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}

.full-width {
    width: 100%;
}

// 注：单行文本超出部分打点
.ellipsis {
    overflow: hidden;
    white-space: nowrap; // 防止多个单词换行
    text-overflow: ellipsis;
}
