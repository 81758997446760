@import "mixins/height";
.login-box {
    position: relative;
    width: 100%;
    height: 100%;
    .minHeight;
    overflow: hidden;
    .login-mini {
        min-height: 500px;
    }
    video {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        filter: blur(9px);
        filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false);
        width: 102%;
    }
    .login-content-box {
        //  height: 335px;
        .ant-form-item {
            margin-bottom: 14px;
        }
        position: absolute;
        width: 360px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 99;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.15);
        padding: 20px;
        border-radius: 4px;
        .ant-typography {
            margin-bottom: 0.8em;
            text-align: left;
        }
    }
    .login-back {
        background-color: rgba(108, 98, 255, 0.2);
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 2;
    }
}
.login-form {
    .images-auto {
        margin: 0 auto;
    }
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}

.erwe-icon {
    position: absolute;
    top: 18px;
    overflow: hidden;
    right: 20px;
    width: 60px;
    height: 60px;
    vertical-align: top;
    z-index: 14;
    cursor: pointer;
    &::after {
        content: "";
        position: absolute;
        left: 14px;
        bottom: 11px;
        width: 12px;
        height: 19px;
        border-bottom: 49px solid #ededf4;
        border-right: 52px solid transparent;
    }
    i {
        font-size: 45px;
        float: right;
        margin-top: -15px;
        cursor: pointer;
    }
}

.login-tip {
    position: absolute;
    top: 24px;
    right: 70px;
    .poptip {
        border: 1px solid #f3d995;
        height: 27px;
        line-height: 16px;
        padding: 5px 20px 5px 15px;
        background: #fefcee;
        position: relative;
        z-index: 16;
        .poptip-arrow {
            top: 8px;
            right: 0;
            em {
                top: 6px;
                right: -7px;
                border-left-color: #f3d995;
                border-width: 6px 0 6px 6px;
            }
            span {
                border-left-color: #fefcee;
                border-width: 6px 0 6px 6px;
                top: 6px;
                right: -5px;
            }
        }
    }
}

.poptip-arrow em,
.poptip-arrow span {
    position: absolute;
    width: 0;
    height: 0;
    border-color: rgba(255, 255, 255, 0);
    border-color: transparent \0;
    border-style: solid;
    overflow: hidden;
    top: 0;
}
.poptip .poptip-content {
    color: #df9c1f;
    font-size: 12px;
    font-weight: 400;
}

.login-statu {
    text-align: center;
    margin-bottom: 15px;
    margin-top: 15px;
    i {
        vertical-align: text-bottom;
        line-height: 39px;
        margin-right: 10px;
        color: #48a6d9;
    }
    span {
        display: block;
        text-align: left;
    }
    div {
        display: inline-block;
    }
}

.erwe-login-erwe {
    .erwe-icon {
        &::after {
            opacity: 0;
        }
    }
}
