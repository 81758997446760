@import "themes/default";

.form-footer {
    position: relative;
    padding: 24px 0;
}

[data-theme="compact"] .ant-advanced-search-form,
.ant-advanced-search-form {
    padding: 24px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
    display: flex;
}

[data-theme="compact"] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
    flex: 1;
}
.survey-list-form {
    .ant-form-item-control-input-content {
        h3 {
            position: relative;
            padding-left: 8px;
            .color-red {
                position: absolute;
                left: 0;
                top: 3px;
            }
        }
        h3 + * {
            // margin-left: 30px;
        }
    }
}
