@charset "UTF-8";
/*全局字体*/
/*字体大小*/
/*行高*/
/*间距大小*/
/*字体颜色*/
/*品牌色*/
/*hover*/
/*链接颜色*/
/*暗色链接颜色*/
/*白色*/
/*高亮色*/
/*默认背景色*/
/*灰阶*/
/*红色*/
/*默认背景色*/
/*头部背景色*/
/*头部字体颜色*/
/*头部链接字体颜色*/
/*头部链接hover颜色*/
/*底部背景色*/
/*底部字体颜色*/
/*底部字体可点击颜色*/
/*底部字体可点击hover颜色*/
/* 全局背景色*/
html > body {
  min-width: 1220px;
  background-color: #fff;
}
/* 全局背景色end*/
/* 字体修改 */
body .ant-list-bordered .ant-list-item {
  padding: 16px;
}
body .ant-list-vertical .ant-list-item-meta-title {
  font-size: 18px;
}
body .ant-card-head {
  padding: 0 16px;
  font-size: 18px;
}
body .ant-card-head-tabs .ant-tabs-tab {
  font-size: 18px !important;
}
body .ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
body .ant-table {
  font-size: 16px;
}
body .ant-table a {
  color: rgba(0, 0, 0, 0.85);
}
body .ant-table a:hover {
  color: #096dd9;
}
body .ant-card-body {
  padding: 16px;
  zoom: 1;
}
body .ant-list-item-meta-title {
  font-size: 18px;
  line-height: 30px;
}
body .ant-list-item-meta-title a .ant-typography:hover {
  color: #096dd9;
}
body .ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
body .ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.65);
}
body .ant-list-item-meta-description .ant-typography {
  color: rgba(0, 0, 0, 0.65);
}
body .ant-list-item-meta-title .ant-typography {
  color: rgba(0, 0, 0, 0.85);
}
body .ant-tabs-nav .ant-tabs-tab {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 100%;
  margin: 0 32px 0 0;
  padding: 16px 0;
  font-size: 16px;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
body .ant-typography {
  color: unset;
}
body .ant-tabs .ant-tabs-large-bar .ant-tabs-tab {
  padding: 16px 0;
}
/*内容区域*/
.container {
  max-width: 1200px;
  min-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
/*内容区域end*/
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.content {
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 295px);
}
.crumb-box {
  padding: 20px 0;
  overflow: hidden;
}
.crumb-box i {
  color: #000000;
}
.crumb-box .ant-breadcrumb-separator {
  color: #000000;
}
.crumb-box .ant-breadcrumb-link {
  color: #096dd9;
}
.crumb-box .ant-breadcrumb a {
  color: #8e8e8e;
  transition: color 0.3s;
}
/*文字排版*/
.ant-text-left {
  text-align: left;
}
.ant-text-right {
  text-align: right;
}
.ant-text-center {
  text-align: center;
}
/*文字排版end*/
/*分页*/
.paging {
  margin: 22px 0 64px;
}
.paging .ant-pagination {
  text-align: center;
}
.list-paging {
  margin: 10px 0 64px;
}
.list-paging .ant-pagination {
  text-align: center;
}
.list-paging .ant-pagination .ant-pagination-item-active {
  font-weight: 500;
  background: #096dd9;
  border-color: #096dd9;
}
.list-paging .ant-pagination .ant-pagination-item-active a {
  color: #fff;
}
/*分页end*/
.html-content {
  line-height: 2;
}
/*列表边框颜色*/
.ant-list.ant-list-bordered {
  border-color: #e8e8e8;
}
/*列表边框颜色end*/
/*主题色className*/
.primary-color {
  color: #096dd9 !important;
}
/*主题色className end*/
/* 重点颜色 */
.sft-color {
  color: #0fbebe !important;
}
/* 重点颜色 end*/
/*黑色标题字体颜色*/
.title-text {
  color: #000000 !important;
}
.text {
  color: rgba(0, 0, 0, 0.45) !important;
}
.fu-text {
  color: rgba(0, 0, 0, 0.65);
}
/* 间距调整 */
.ant-list-item-meta-description,
.ant-list {
  line-height: 26px !important;
}
.ant-list-vertical .ant-list-item-meta-title {
  line-height: 28px !important;
}
.ant-list-item-meta-title {
  color: rgba(0, 0, 0, 0.85) !important;
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: text-bottom !important;
}
/*标题字体颜色--end*/
/*灰色className*/
.gray-color {
  color: #000000 !important;
}
.gray-one-color {
  color: #333333 !important;
}
.gray-tow-color {
  color: #666666 !important;
}
.gray-three-color {
  color: #999 !important;
}
.text {
  color: rgba(0, 0, 0, 0.45) !important;
}
.gray-four-color {
  color: #bdbdbd !important;
}
.gray-five-color {
  color: #e6e6e6 !important;
}
/*灰色className end*/
/*白色背景色*/
.background-white {
  background-color: #ffffff !important;
}
/*白色背景色end*/
/*边框*/
.border {
  border: #e8e8e8 solid 1px;
}
.border-top {
  border-top: #e8e8e8 solid 1px;
}
.border-bottom {
  border-bottom: #e8e8e8 solid 1px;
}
.border-right {
  border-right: #e8e8e8 solid 1px;
}
/*边框end*/
/*search-搜素栏样式*/
.search-head {
  position: relative;
  overflow: hidden;
  width: 300px;
  padding-bottom: 15px;
}
/*search-搜素栏样式-end*/
.my-ant-table {
  width: 100%;
  border-radius: 4px 4px 0 0;
  border-collapse: collapse;
  border: 1px solid #e8e8e8;
  border-right: 0;
  table-layout: fixed;
  border-bottom: 0;
  text-align: left;
}
.my-ant-table thead > tr {
  transition: all 0.3s, height 0s;
}
.my-ant-table thead > tr:first-child > th:first-child {
  border-top-left-radius: 4px;
}
.my-ant-table thead > tr > th,
.my-ant-table tbody > tr > td {
  border-right: 1px solid #e8e8e8;
  padding: 16px 16px;
}
.my-ant-table thead > tr > th {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  background: #fafafa;
  border-bottom: 1px solid #e8e8e8;
  transition: background 0.3s ease;
}
.my-ant-table tbody > tr > td {
  border-bottom: 1px solid #e8e8e8;
  transition: all 0.3s, border 0s;
}
.home-footer-btn {
  position: relative;
  overflow: hidden;
  text-align: center;
  width: 200px;
  margin: 0 auto;
}
.scoll-top .ant-back-top-content {
  background-color: #096dd9;
}
.scoll-top .ant-back-top-content:hover {
  background-color: #4994fe;
}
/*表单*/
.form-divider-text-size .ant-divider-inner-text {
  font-size: 20px;
}
.form-default-style .ant-form-item-label {
  line-height: 35px;
}
.form-default-style .ant-form-item-label label {
  font-size: 16px;
}
.form-default-style .ant-form-item {
  margin-bottom: 16px;
}
.form-default-style .ant-input {
  font-size: 14px;
}
.form-default-style.state .ant-form-item {
  display: flex;
}
.form-default-style.state .ant-form-item .ant-form-item-label {
  width: 120px;
}
.room-name {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.name-label {
  width: 100px;
  display: inline-block;
  margin-right: 5px;
}
.form-line {
  line-height: 24px;
}
.inline-block {
  display: inline-block;
}
.price {
  color: #cf2525;
  font-size: 18px;
}
/*表单end*/
/*字体 12px-32px*/
.size-11 {
  font-size: 11px !important;
}
.size-12 {
  font-size: 12px !important;
}
.size-13 {
  font-size: 13px !important;
}
.size-14 {
  font-size: 14px !important;
}
.size-15 {
  font-size: 15px !important;
}
.size-16 {
  font-size: 16px !important;
}
.size-17 {
  font-size: 17px !important;
}
.size-18 {
  font-size: 18px !important;
}
.size-19 {
  font-size: 19px !important;
}
.size-20 {
  font-size: 20px !important;
}
.size-21 {
  font-size: 21px !important;
}
.size-22 {
  font-size: 22px !important;
}
.size-23 {
  font-size: 23px !important;
}
.size-24 {
  font-size: 24px !important;
}
.size-25 {
  font-size: 25px !important;
}
.size-26 {
  font-size: 26px !important;
}
.size-27 {
  font-size: 27px !important;
}
.size-28 {
  font-size: 28px !important;
}
.size-29 {
  font-size: 29px !important;
}
.size-30 {
  font-size: 30px !important;
}
.size-31 {
  font-size: 31px !important;
}
.size-32 {
  font-size: 32px !important;
}
.size-33 {
  font-size: 33px !important;
}
.size-34 {
  font-size: 34px !important;
}
.size-35 {
  font-size: 35px !important;
}
.size-36 {
  font-size: 36px !important;
}
.size-37 {
  font-size: 37px !important;
}
.size-38 {
  font-size: 38px !important;
}
.size-39 {
  font-size: 39px !important;
}
.size-40 {
  font-size: 40px !important;
}
.size-41 {
  font-size: 41px !important;
}
.size-42 {
  font-size: 42px !important;
}
.size-43 {
  font-size: 43px !important;
}
.size-44 {
  font-size: 44px !important;
}
.size-45 {
  font-size: 45px !important;
}
.size-46 {
  font-size: 46px !important;
}
.size-47 {
  font-size: 47px !important;
}
.size-48 {
  font-size: 48px !important;
}
.size-49 {
  font-size: 49px !important;
}
.size-50 {
  font-size: 50px !important;
}
/*字体 12px-50px end*/
/*card 内部 body 边距*/
.card-0 .ant-card-body {
  padding: 0 0px !important;
}
.card-1 .ant-card-body {
  padding: 0 1px !important;
}
.card-2 .ant-card-body {
  padding: 0 2px !important;
}
.card-3 .ant-card-body {
  padding: 0 3px !important;
}
.card-4 .ant-card-body {
  padding: 0 4px !important;
}
.card-5 .ant-card-body {
  padding: 0 5px !important;
}
.card-6 .ant-card-body {
  padding: 0 6px !important;
}
.card-7 .ant-card-body {
  padding: 0 7px !important;
}
.card-8 .ant-card-body {
  padding: 0 8px !important;
}
.card-9 .ant-card-body {
  padding: 0 9px !important;
}
.card-10 .ant-card-body {
  padding: 0 10px !important;
}
.card-11 .ant-card-body {
  padding: 0 11px !important;
}
.card-12 .ant-card-body {
  padding: 0 12px !important;
}
.card-13 .ant-card-body {
  padding: 0 13px !important;
}
.card-14 .ant-card-body {
  padding: 0 14px !important;
}
.card-15 .ant-card-body {
  padding: 0 15px !important;
}
.card-16 .ant-card-body {
  padding: 0 16px !important;
}
.card-17 .ant-card-body {
  padding: 0 17px !important;
}
.card-18 .ant-card-body {
  padding: 0 18px !important;
}
.card-19 .ant-card-body {
  padding: 0 19px !important;
}
.card-20 .ant-card-body {
  padding: 0 20px !important;
}
.card-21 .ant-card-body {
  padding: 0 21px !important;
}
.card-22 .ant-card-body {
  padding: 0 22px !important;
}
.card-23 .ant-card-body {
  padding: 0 23px !important;
}
.card-24 .ant-card-body {
  padding: 0 24px !important;
}
.card-25 .ant-card-body {
  padding: 0 25px !important;
}
.card-26 .ant-card-body {
  padding: 0 26px !important;
}
.card-27 .ant-card-body {
  padding: 0 27px !important;
}
.card-28 .ant-card-body {
  padding: 0 28px !important;
}
.card-29 .ant-card-body {
  padding: 0 29px !important;
}
.card-30 .ant-card-body {
  padding: 0 30px !important;
}
.card-31 .ant-card-body {
  padding: 0 31px !important;
}
.card-32 .ant-card-body {
  padding: 0 32px !important;
}
.card-33 .ant-card-body {
  padding: 0 33px !important;
}
.card-34 .ant-card-body {
  padding: 0 34px !important;
}
.card-35 .ant-card-body {
  padding: 0 35px !important;
}
.card-36 .ant-card-body {
  padding: 0 36px !important;
}
.card-37 .ant-card-body {
  padding: 0 37px !important;
}
.card-38 .ant-card-body {
  padding: 0 38px !important;
}
.card-39 .ant-card-body {
  padding: 0 39px !important;
}
.card-40 .ant-card-body {
  padding: 0 40px !important;
}
.card-41 .ant-card-body {
  padding: 0 41px !important;
}
.card-42 .ant-card-body {
  padding: 0 42px !important;
}
.card-43 .ant-card-body {
  padding: 0 43px !important;
}
.card-44 .ant-card-body {
  padding: 0 44px !important;
}
.card-45 .ant-card-body {
  padding: 0 45px !important;
}
.card-46 .ant-card-body {
  padding: 0 46px !important;
}
.card-47 .ant-card-body {
  padding: 0 47px !important;
}
.card-48 .ant-card-body {
  padding: 0 48px !important;
}
.card-49 .ant-card-body {
  padding: 0 49px !important;
}
.card-50 .ant-card-body {
  padding: 0 50px !important;
}
.card-51 .ant-card-body {
  padding: 0 51px !important;
}
.card-52 .ant-card-body {
  padding: 0 52px !important;
}
.card-53 .ant-card-body {
  padding: 0 53px !important;
}
.card-54 .ant-card-body {
  padding: 0 54px !important;
}
.card-55 .ant-card-body {
  padding: 0 55px !important;
}
.card-56 .ant-card-body {
  padding: 0 56px !important;
}
.card-57 .ant-card-body {
  padding: 0 57px !important;
}
.card-58 .ant-card-body {
  padding: 0 58px !important;
}
.card-59 .ant-card-body {
  padding: 0 59px !important;
}
.card-60 .ant-card-body {
  padding: 0 60px !important;
}
.card-61 .ant-card-body {
  padding: 0 61px !important;
}
.card-62 .ant-card-body {
  padding: 0 62px !important;
}
.card-63 .ant-card-body {
  padding: 0 63px !important;
}
.card-64 .ant-card-body {
  padding: 0 64px !important;
}
.card-65 .ant-card-body {
  padding: 0 65px !important;
}
.card-66 .ant-card-body {
  padding: 0 66px !important;
}
.card-67 .ant-card-body {
  padding: 0 67px !important;
}
.card-68 .ant-card-body {
  padding: 0 68px !important;
}
.card-69 .ant-card-body {
  padding: 0 69px !important;
}
.card-70 .ant-card-body {
  padding: 0 70px !important;
}
.card-71 .ant-card-body {
  padding: 0 71px !important;
}
.card-72 .ant-card-body {
  padding: 0 72px !important;
}
.card-73 .ant-card-body {
  padding: 0 73px !important;
}
.card-74 .ant-card-body {
  padding: 0 74px !important;
}
.card-75 .ant-card-body {
  padding: 0 75px !important;
}
.card-76 .ant-card-body {
  padding: 0 76px !important;
}
.card-77 .ant-card-body {
  padding: 0 77px !important;
}
.card-78 .ant-card-body {
  padding: 0 78px !important;
}
.card-79 .ant-card-body {
  padding: 0 79px !important;
}
.card-80 .ant-card-body {
  padding: 0 80px !important;
}
.card-81 .ant-card-body {
  padding: 0 81px !important;
}
.card-82 .ant-card-body {
  padding: 0 82px !important;
}
.card-83 .ant-card-body {
  padding: 0 83px !important;
}
.card-84 .ant-card-body {
  padding: 0 84px !important;
}
.card-85 .ant-card-body {
  padding: 0 85px !important;
}
.card-86 .ant-card-body {
  padding: 0 86px !important;
}
.card-87 .ant-card-body {
  padding: 0 87px !important;
}
.card-88 .ant-card-body {
  padding: 0 88px !important;
}
.card-89 .ant-card-body {
  padding: 0 89px !important;
}
.card-90 .ant-card-body {
  padding: 0 90px !important;
}
.card-91 .ant-card-body {
  padding: 0 91px !important;
}
.card-92 .ant-card-body {
  padding: 0 92px !important;
}
.card-93 .ant-card-body {
  padding: 0 93px !important;
}
.card-94 .ant-card-body {
  padding: 0 94px !important;
}
.card-95 .ant-card-body {
  padding: 0 95px !important;
}
.card-96 .ant-card-body {
  padding: 0 96px !important;
}
.card-97 .ant-card-body {
  padding: 0 97px !important;
}
.card-98 .ant-card-body {
  padding: 0 98px !important;
}
.card-99 .ant-card-body {
  padding: 0 99px !important;
}
.card-100 .ant-card-body {
  padding: 0 100px !important;
}
/*card 内部 body 边距 --end*/
.card-space-0 .ant-card-body {
  padding: 0px 16px !important;
}
.card-space-1 .ant-card-body {
  padding: 1px 16px !important;
}
.card-space-2 .ant-card-body {
  padding: 2px 16px !important;
}
.card-space-3 .ant-card-body {
  padding: 3px 16px !important;
}
.card-space-4 .ant-card-body {
  padding: 4px 16px !important;
}
.card-space-5 .ant-card-body {
  padding: 5px 16px !important;
}
.card-space-6 .ant-card-body {
  padding: 6px 16px !important;
}
.card-space-7 .ant-card-body {
  padding: 7px 16px !important;
}
.card-space-8 .ant-card-body {
  padding: 8px 16px !important;
}
.card-space-9 .ant-card-body {
  padding: 9px 16px !important;
}
.card-space-10 .ant-card-body {
  padding: 10px 16px !important;
}
.card-space-11 .ant-card-body {
  padding: 11px 16px !important;
}
.card-space-12 .ant-card-body {
  padding: 12px 16px !important;
}
.card-space-13 .ant-card-body {
  padding: 13px 16px !important;
}
.card-space-14 .ant-card-body {
  padding: 14px 16px !important;
}
.card-space-15 .ant-card-body {
  padding: 15px 16px !important;
}
.card-space-16 .ant-card-body {
  padding: 16px 16px !important;
}
.card-space-17 .ant-card-body {
  padding: 17px 16px !important;
}
.card-space-18 .ant-card-body {
  padding: 18px 16px !important;
}
.card-space-19 .ant-card-body {
  padding: 19px 16px !important;
}
.card-space-20 .ant-card-body {
  padding: 20px 16px !important;
}
.card-space-21 .ant-card-body {
  padding: 21px 16px !important;
}
.card-space-22 .ant-card-body {
  padding: 22px 16px !important;
}
.card-space-23 .ant-card-body {
  padding: 23px 16px !important;
}
.card-space-24 .ant-card-body {
  padding: 24px 16px !important;
}
.card-space-25 .ant-card-body {
  padding: 25px 16px !important;
}
.card-space-26 .ant-card-body {
  padding: 26px 16px !important;
}
.card-space-27 .ant-card-body {
  padding: 27px 16px !important;
}
.card-space-28 .ant-card-body {
  padding: 28px 16px !important;
}
.card-space-29 .ant-card-body {
  padding: 29px 16px !important;
}
.card-space-30 .ant-card-body {
  padding: 30px 16px !important;
}
.card-space-31 .ant-card-body {
  padding: 31px 16px !important;
}
.card-space-32 .ant-card-body {
  padding: 32px 16px !important;
}
.card-space-33 .ant-card-body {
  padding: 33px 16px !important;
}
.card-space-34 .ant-card-body {
  padding: 34px 16px !important;
}
.card-space-35 .ant-card-body {
  padding: 35px 16px !important;
}
.card-space-36 .ant-card-body {
  padding: 36px 16px !important;
}
.card-space-37 .ant-card-body {
  padding: 37px 16px !important;
}
.card-space-38 .ant-card-body {
  padding: 38px 16px !important;
}
.card-space-39 .ant-card-body {
  padding: 39px 16px !important;
}
.card-space-40 .ant-card-body {
  padding: 40px 16px !important;
}
.card-space-41 .ant-card-body {
  padding: 41px 16px !important;
}
.card-space-42 .ant-card-body {
  padding: 42px 16px !important;
}
.card-space-43 .ant-card-body {
  padding: 43px 16px !important;
}
.card-space-44 .ant-card-body {
  padding: 44px 16px !important;
}
.card-space-45 .ant-card-body {
  padding: 45px 16px !important;
}
.card-space-46 .ant-card-body {
  padding: 46px 16px !important;
}
.card-space-47 .ant-card-body {
  padding: 47px 16px !important;
}
.card-space-48 .ant-card-body {
  padding: 48px 16px !important;
}
.card-space-49 .ant-card-body {
  padding: 49px 16px !important;
}
.card-space-50 .ant-card-body {
  padding: 50px 16px !important;
}
.card-space-51 .ant-card-body {
  padding: 51px 16px !important;
}
.card-space-52 .ant-card-body {
  padding: 52px 16px !important;
}
.card-space-53 .ant-card-body {
  padding: 53px 16px !important;
}
.card-space-54 .ant-card-body {
  padding: 54px 16px !important;
}
.card-space-55 .ant-card-body {
  padding: 55px 16px !important;
}
.card-space-56 .ant-card-body {
  padding: 56px 16px !important;
}
.card-space-57 .ant-card-body {
  padding: 57px 16px !important;
}
.card-space-58 .ant-card-body {
  padding: 58px 16px !important;
}
.card-space-59 .ant-card-body {
  padding: 59px 16px !important;
}
.card-space-60 .ant-card-body {
  padding: 60px 16px !important;
}
.card-space-61 .ant-card-body {
  padding: 61px 16px !important;
}
.card-space-62 .ant-card-body {
  padding: 62px 16px !important;
}
.card-space-63 .ant-card-body {
  padding: 63px 16px !important;
}
.card-space-64 .ant-card-body {
  padding: 64px 16px !important;
}
.card-space-65 .ant-card-body {
  padding: 65px 16px !important;
}
.card-space-66 .ant-card-body {
  padding: 66px 16px !important;
}
.card-space-67 .ant-card-body {
  padding: 67px 16px !important;
}
.card-space-68 .ant-card-body {
  padding: 68px 16px !important;
}
.card-space-69 .ant-card-body {
  padding: 69px 16px !important;
}
.card-space-70 .ant-card-body {
  padding: 70px 16px !important;
}
.card-space-71 .ant-card-body {
  padding: 71px 16px !important;
}
.card-space-72 .ant-card-body {
  padding: 72px 16px !important;
}
.card-space-73 .ant-card-body {
  padding: 73px 16px !important;
}
.card-space-74 .ant-card-body {
  padding: 74px 16px !important;
}
.card-space-75 .ant-card-body {
  padding: 75px 16px !important;
}
.card-space-76 .ant-card-body {
  padding: 76px 16px !important;
}
.card-space-77 .ant-card-body {
  padding: 77px 16px !important;
}
.card-space-78 .ant-card-body {
  padding: 78px 16px !important;
}
.card-space-79 .ant-card-body {
  padding: 79px 16px !important;
}
.card-space-80 .ant-card-body {
  padding: 80px 16px !important;
}
.card-space-81 .ant-card-body {
  padding: 81px 16px !important;
}
.card-space-82 .ant-card-body {
  padding: 82px 16px !important;
}
.card-space-83 .ant-card-body {
  padding: 83px 16px !important;
}
.card-space-84 .ant-card-body {
  padding: 84px 16px !important;
}
.card-space-85 .ant-card-body {
  padding: 85px 16px !important;
}
.card-space-86 .ant-card-body {
  padding: 86px 16px !important;
}
.card-space-87 .ant-card-body {
  padding: 87px 16px !important;
}
.card-space-88 .ant-card-body {
  padding: 88px 16px !important;
}
.card-space-89 .ant-card-body {
  padding: 89px 16px !important;
}
.card-space-90 .ant-card-body {
  padding: 90px 16px !important;
}
.card-space-91 .ant-card-body {
  padding: 91px 16px !important;
}
.card-space-92 .ant-card-body {
  padding: 92px 16px !important;
}
.card-space-93 .ant-card-body {
  padding: 93px 16px !important;
}
.card-space-94 .ant-card-body {
  padding: 94px 16px !important;
}
.card-space-95 .ant-card-body {
  padding: 95px 16px !important;
}
.card-space-96 .ant-card-body {
  padding: 96px 16px !important;
}
.card-space-97 .ant-card-body {
  padding: 97px 16px !important;
}
.card-space-98 .ant-card-body {
  padding: 98px 16px !important;
}
.card-space-99 .ant-card-body {
  padding: 99px 16px !important;
}
.card-space-100 .ant-card-body {
  padding: 100px 16px !important;
}
/*日期选择100%宽度样式*/
.date-type-block.ant-calendar-picker {
  display: block;
}
/*日期选择100%宽度样式end*/
/* 重定义*/
.ant-tag-blue {
  color: #096dd9 !important;
  background: #e6f7ff;
  border-color: #096dd9 !important;
}
/* 重定义*/
/*个人中心menu*/
.my-menu .ant-menu-item {
  margin-bottom: 5.7px !important;
}
.my-menu .ant-menu-item a {
  color: #333333;
}
.my-menu .ant-menu-item .icon {
  margin-right: 10px;
}
.my-menu .ant-menu-item.ant-menu-item-active a,
.my-menu .ant-menu-item.ant-menu-item-selected a {
  color: #096dd9;
}
.my-menu .ant-menu-item-selected {
  background-color: #096dd9 !important;
  color: #fff;
}
.my-menu .ant-menu-item-selected:hover {
  color: #fff;
}
.my-menu .ant-menu-item-selected::after {
  border-right: 3px solid #40a9ff;
}
/*个人中心menu end*/
/*卡片内容区域边距*/
.card-p0 .ant-card-body {
  padding: 0;
}
.card-p10 .ant-card-body {
  padding: 10px;
}
.card-p10-24 .ant-card-body {
  padding: 10px 24px;
}
/*卡片内容区域边距-end*/
/*卡片类型*/
.card-state .ant-card-body {
  padding: 15.2px 24px;
  text-align: center;
}
.card-state p {
  color: #666;
  margin: 0;
  font-size: 12px;
}
/*卡片类型-end*/
/*圆形卡片*/
.rotundity-card {
  padding: 11px 0;
  text-align: center;
  cursor: pointer;
  display: block;
}
.rotundity-card i {
  font-size: 30px;
}
.rotundity-card p {
  margin-bottom: 0 !important;
  margin-top: 5px;
  color: #333333;
}
/*圆形选项卡-end*/
/*大号圆形卡片*/
.big-card-setting {
  width: 156px;
  height: 156px;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
  line-height: 156px;
}
.big-card-setting img {
  width: 100%;
  height: 100%;
}
/*圆形选项卡-end*/
/*折叠面板-*/
.down-table .ant-collapse-header {
  position: absolute !important;
  right: 0;
  top: 70px;
  margin-top: -25px;
}
.down-table .ant-collapse-item {
  border: none !important;
}
.down-table .ant-collapse-content-box {
  padding: 10px 0 0 0 !important;
}
/*折叠面板-end*/
/* table fixed 布局 */
.table-fixed table {
  table-layout: fixed;
}
/* table fixed - end 布局 */
/*margin-centet */
.margin-centet {
  margin: 0 auto;
}
/*margin-centet-end */
/*上一篇 下一篇 返回*/
.info-article-page .ant-typography {
  border-top: #e6e6e6 solid 1px;
  border-bottom: #e6e6e6 solid 1px;
  margin: 0;
  padding: 10px 0;
  color: #333333;
  font-size: 15px;
}
.info-article-page .ant-typography:first-child {
  border-bottom: none;
}
.info-article-page .ant-typography span {
  font-size: 14px;
}
.info-article-page .ant-typography a {
  color: #7d7d7d;
  font-size: 14px;
}
.info-article-page .ant-typography a:hover {
  color: #096dd9;
}
.info-article-page .line {
  margin: 0 10px;
  color: #d9d9d9;
}
.info-back {
  margin-top: 20px;
  text-align: right;
}
/*上一篇 下一篇 返回 end*/
.html-details ul {
  width: 100% !important;
  white-space: normal !important;
}
/*图片轮播Carousel*/
.atlas-cl .ant-carousel .slick-list {
  overflow: hidden;
  width: 830px;
  margin: 0 auto;
}
.atlas-cl .ant-carousel .slick-slide {
  text-align: center;
  pointer-events: auto !important;
  height: 100% !important;
  background-color: #f5f5f5;
  overflow: hidden;
  width: 200px;
  margin-right: 10px;
}
.atlas-cl .ant-carousel .slick-prev,
.atlas-cl .ant-carousel .slick-next {
  background-color: #096dd9;
  color: #ffffff;
  width: 24px;
  height: 40px;
  line-height: 40px;
  margin-top: -20px;
  z-index: 10;
}
.atlas-cl .ant-carousel .slick-prev:focus,
.atlas-cl .ant-carousel .slick-next:focus {
  background-color: #096dd9;
  color: #ffffff;
}
.atlas-cl .ant-carousel .slick-prev:hover,
.atlas-cl .ant-carousel .slick-next:hover {
  background-color: #4994fe;
  color: #ffffff;
}
.atlas-cl .ant-carousel .slick-prev {
  left: 8px;
}
.atlas-cl .ant-carousel .slick-prev:before {
  content: "\e665";
  font-family: "icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.atlas-cl .ant-carousel .slick-next {
  right: 8px;
}
.atlas-cl .ant-carousel .slick-next:before {
  content: "\e861";
  font-family: "icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/*图片轮播Carousel end*/
/*颜色*/
/*白色*/
.white-color {
  color: #ffffff !important;
}
/*高亮样式，橙色*/
.highlight-color {
  color: #ff9a1b;
}
/*等待颜色，主题色*/
.wait-color {
  color: #4190ea;
}
/*提示颜色，橙色*/
.loading-color {
  color: #ff9a1b !important;
}
/*成功，灰色*/
.success-color {
  color: #bdbdbd;
}
/*错误，红色*/
.error-color {
  color: #cf2525;
}
.color-waiting {
  color: #4190ea !important;
}
.color-green {
  color: #00c500 !important;
}
.color-success {
  color: #bdbdbd !important;
}
.color-failure {
  color: #ffbe02 !important;
}
.color-cancel {
  color: #cf2525 !important;
}
.color-black {
  color: #000 !important;
}
.color-red {
  color: red !important;
}
.color-waiting-use {
  color: #2289cb !important;
}
.color-waiting-pay {
  color: #009900 !important;
}
/* 颜色end */
.selected {
  color: #4190ea !important;
  font-size: larger;
}
.unselected {
  color: #bdbdbd !important;
}
/*圆角*/
.radius-4 {
  border-radius: 4px !important;
}
.card-inline > a {
  display: inline-block;
  margin-right: 8px;
}
.card-inline > a:last-child {
  margin-right: 0;
}
.ant-card.card-inline {
  display: inline-block;
}
.ant-card.ant-card-hoverable:hover {
  border-color: rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 14px 8px rgba(0, 0, 0, 0.09);
}
.pagination-center .ant-list-pagination {
  text-align: center;
}
.pagination-center .ant-table-pagination.ant-pagination {
  text-align: center;
  float: inherit;
}
.pinglun-box {
  border-bottom: solid 1px #eee;
}
.pinglun-box .ant-comment-nested {
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 0 15px;
  margin-bottom: 15px;
}
.pinglun-box .ant-comment-nested .ant-comment {
  border-bottom: #eee solid 1px;
}
.pinglun-box .ant-comment-nested .ant-comment:first-child {
  padding-top: 10px;
}
.pinglun-box .ant-comment-nested .ant-comment:last-child {
  border: none;
}
.pinglun-box .ant-comment-nested .ant-comment-inner {
  padding: 15px;
}
.pinglun-box .ant-comment-content-author {
  padding-right: 50px;
}
.pinglun-box .ant-comment-content-detail {
  padding-right: 50px;
}
.pinglun-box .ant-comment-actions > li:first-child {
  position: absolute;
  right: 20px;
  top: 0;
}
.pinglun-box .ant-comment-actions > li:first-child span {
  padding-right: 0;
}
.pinglun-box .ant-comment-actions > li:first-child span i {
  color: #096dd9;
  font-size: 14px;
  margin-right: 5px;
}
.pinglun-box .ant-comment-actions > li:last-child {
  position: absolute;
  right: 20px;
  top: 23px;
}
.pinglun-box .ant-comment-actions > li:last-child span {
  padding-right: 0;
}
.comment-box {
  position: relative;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  overflow: hidden;
}
.comment-box .textArea-box {
  position: unset;
}
.comment-box .textArea-box textarea {
  border: none;
  box-shadow: unset;
}
.comment-box .textArea-box .txtlimit {
  line-height: 20px !important;
  position: absolute;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 45px;
  text-align: center;
  right: 65px;
  font-size: 14px;
  bottom: 15px;
  color: #999999;
}
.comment-box .comment-submit {
  background-color: #f9f9f9;
  padding: 10px;
  text-align: right;
  line-height: 30px;
}
.comment-box .comment-submit .ant-btn {
  height: 30px;
  padding: 0 10px;
}
.carnet-text {
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  padding: 15px 0;
}
.textArea-box {
  position: relative;
}
.textArea-box textarea {
  resize: none;
  margin-bottom: 0 !important;
  outline: none;
  border-radius: 0;
  box-shadow: unset;
}
.txtlimit {
  line-height: 20px !important;
  color: #fff;
  position: absolute;
  padding: 0 10px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 45px;
  text-align: center;
  right: 10px;
  font-size: 12px;
  bottom: 10px;
}
.box {
  position: relative;
  overflow: hidden;
}
.main {
  cursor: pointer;
}
.boxShow {
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
}
.ant-back-top.home-backTop {
  right: 95px;
  top: 700px;
}
.ant-back-top.home-backTop .ant-back-top-content {
  background-color: #096dd9;
}
.ant-back-top.home-backTop .ant-back-top-content:hover {
  background-color: #4994fe;
}
.scoll-top .ant-back-top {
  right: 30px;
}
.backTop-box {
  right: 0px;
  width: 36px;
  height: 36px;
  top: 50%;
  transform: translate(0, 150%);
  position: fixed;
  z-index: 1;
}
.backTop-box span {
  background-color: #4994fe;
  display: block;
  position: absolute;
  min-width: 70px;
  padding-left: 10px;
  height: 36px;
  right: -70px;
  transition: all 0.3s;
  top: 0;
  color: #fff;
  line-height: 36px;
  font-size: 14px;
}
.backTop-box:hover span {
  right: 36px;
}
.hand {
  cursor: pointer;
}
.line-label {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.line-label:hover div.ant-typography,
.line-label:hover .ant-typography p {
  color: #000;
}
.line-label div.ant-typography,
.line-label .ant-typography p {
  margin-bottom: 0;
}
/*暂无数据*/
.no-data {
  width: 100%;
  height: 200px;
  text-align: center;
}
/*暂无数据end*/
.bi-hover {
  cursor: pointer;
  transition: all 0.4s;
}
.bi-hover:hover {
  transform: translateY(-2%);
  box-shadow: 0px 4px 12px 0px #ddd;
}
.bi-hovers {
  transition: all 0.4s;
}
.bi-hovers:hover {
  transform: translateY(-2%);
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
}
.hand-cover {
  cursor: pointer !important;
}
.font-weight-normal {
  font-weight: normal !important;
}
.copy-a-label {
  cursor: pointer !important;
  color: #2289cb;
}
/* banner 图 */
.banner-box {
  position: relative;
  height: 230px;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 32px;
}
.banner-box img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
/* 按钮样式调整*/
.card-box .ant-card-head {
  padding: 0 12px;
  position: relative;
  border: none;
}
.card-box .ant-card-head::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  height: 16px;
  width: 4px;
  background-color: #096dd9;
  border-radius: 2px;
}
.card-box .ant-card-head .ant-card-head-title {
  color: #000000;
  font-size: 20px;
}
.card-box .ant-card-body {
  padding: 0;
}
/*详情页*/
.details-html .ant-btn[disabled] {
  pointer-events: none;
}
.details-html .ant-btn[disabled]:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
.details-html .pingj .ant-list-split .ant-list-item {
  border-bottom: 1px solid #e8e8e8;
}
.details-html .ant-card-bordered {
  border: none;
}
.details-html .details-header {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}
.details-html .details-header .index-price-img,
.details-html .details-header .price-img {
  height: 100%;
  min-height: 260px;
}
.details-html .details-header .index-price-img .images-auto,
.details-html .details-header .price-img .images-auto {
  height: 100% !important;
}
.details-html .details-header .gutter-row:last-child {
  padding-top: 16px;
}
.details-html .details-header .gutter-row:last-child .ant-typography {
  font-size: 24px;
}
.details-html .details-header .details-intro-header .ant-list-item {
  padding: 0;
  margin-bottom: 10px;
}
.details-html .details-header > .ant-card-body {
  padding: 0;
}
.details-html .ant-list-split .ant-list-item {
  border: none;
}
.details-html .ant-card-head {
  padding: 0 !important;
  position: relative;
  border: none;
}
.details-html .ant-card-head .ant-card-head-title {
  position: relative;
  color: #000000;
  font-size: 18px;
  padding: 0 16px 16px 16px !important;
}
.details-html .ant-card-head .ant-card-head-title::after {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  height: 16px;
  width: 4px;
  background-color: #096dd9;
  border-radius: 2px;
}
.popular-content.ant-card {
  border: none;
}
.popular-content .ant-card-body {
  border: 1px solid #e5e5e5;
  padding: 16px;
  border-radius: 4px;
}
.popular-content .ant-card-head {
  padding: 0 !important;
  position: relative;
  border: none;
}
.popular-content .ant-card-head .ant-card-head-title {
  position: relative;
  color: #000000;
  font-size: 20px;
  padding: 0 16px 16px 16px !important;
}
.popular-content .ant-card-head .ant-card-head-title::after {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  height: 16px;
  width: 4px;
  background-color: #096dd9;
  border-radius: 2px;
}
/*多行文本截取*/
.omit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
}
.omit-1 {
  -webkit-line-clamp: 1;
}
.omit-2 {
  -webkit-line-clamp: 2;
}
.omit-3 {
  -webkit-line-clamp: 3;
}
.no-omit {
  white-space: normal;
  word-break: break-all;
}
.no-nowrap {
  white-space: nowrap;
}
.ellipsis-1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 图片弹框 */
.images-modal .ant-modal-body {
  padding: 0;
}
.images-modal .ant-modal-close-x i {
  color: #fff;
}
.scroll-box {
  overflow: auto;
}
.scroll-box::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.scroll-box::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
}
.scroll-box::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
}
body .ant-form-item-label {
  overflow: unset;
}
body .ant-form-item-label .ant-form-item-required:before {
  position: absolute;
  left: -10px;
}
.text-italic {
  font-style: italic;
}
.color-qing {
  color: #2bb3b1;
}
.height-full {
  height: 100%;
}
.ant-card.ant-card-fill {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.ant-card.ant-card-fill .ant-card-body {
  flex: 1;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}
.bg-default {
  background-color: #096dd9 !important;
}
.text-default {
  color: #096dd9 !important;
}
.bg-white {
  background-color: #fff !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.text-white {
  color: #fff !important;
}
.text-gray-light {
  color: #CCCCCC !important;
}
.text-gray {
  color: #999999 !important;
}
.text-gray-dark {
  color: #666666 !important;
}
.text-hover:hover {
  color: #1890ff !important;
}
.bg-red-light {
  background-color: #ff4d4f !important;
}
.bg-red {
  background-color: #f5222d !important;
}
.bg-red-dark {
  background-color: #cf1322 !important;
}
.bg-red-light-h:hover {
  background-color: #ff4d4f !important;
}
.bg-red-h:hover {
  background-color: #f5222d !important;
}
.bg-red-dark-h:hover {
  background-color: #cf1322 !important;
}
.text-red-light {
  color: #ff4d4f !important;
}
.text-red {
  color: #f5222d !important;
}
.text-red-dark {
  color: #cf1322 !important;
}
.bg-volcano-light {
  background-color: #ff7a45 !important;
}
.bg-volcano {
  background-color: #fa541c !important;
}
.bg-volcano-dark {
  background-color: #d4380d !important;
}
.bg-volcano-light-h:hover {
  background-color: #ff7a45 !important;
}
.bg-volcano-h:hover {
  background-color: #fa541c !important;
}
.bg-volcano-dark-h:hover {
  background-color: #d4380d !important;
}
.text-volcano-light {
  color: #ff7a45 !important;
}
.text-volcano {
  color: #fa541c !important;
}
.text-volcano-dark {
  color: #d4380d !important;
}
.bg-orange-light {
  background-color: #ffa940 !important;
}
.bg-orange {
  background-color: #fa8c16 !important;
}
.bg-orange-dark {
  background-color: #d46b08 !important;
}
.bg-orange-light-h:hover {
  background-color: #ffa940 !important;
}
.bg-orange-h:hover {
  background-color: #fa8c16 !important;
}
.bg-orange-dark-h:hover {
  background-color: #d46b08 !important;
}
.text-orange-light {
  color: #ffa940 !important;
}
.text-orange {
  color: #fa8c16 !important;
}
.text-orange-dark {
  color: #d46b08 !important;
}
.bg-gold-light {
  background-color: #ffc53d !important;
}
.bg-gold {
  background-color: #faad14 !important;
}
.bg-gold-dark {
  background-color: #d48806 !important;
}
.bg-gold-light-h:hover {
  background-color: #ffc53d !important;
}
.bg-gold-h:hover {
  background-color: #faad14 !important;
}
.bg-gold-dark-h:hover {
  background-color: #d48806 !important;
}
.text-gold-light {
  color: #ffc53d !important;
}
.text-gold {
  color: #faad14 !important;
}
.text-gold-dark {
  color: #d48806 !important;
}
.bg-yellow-light {
  background-color: #ffec3d !important;
}
.bg-yellow {
  background-color: #fadb14 !important;
}
.bg-yellow-dark {
  background-color: #d4b106 !important;
}
.bg-yellow-light-h:hover {
  background-color: #ffec3d !important;
}
.bg-yellow-h:hover {
  background-color: #fadb14 !important;
}
.bg-yellow-dark-h:hover {
  background-color: #d4b106 !important;
}
.text-yellow-light {
  color: #ffec3d !important;
}
.text-yellow {
  color: #fadb14 !important;
}
.text-yellow-dark {
  color: #d4b106 !important;
}
.bg-lime-light {
  background-color: #bae637 !important;
}
.bg-lime {
  background-color: #a0d911 !important;
}
.bg-lime-dark {
  background-color: #7cb305 !important;
}
.bg-lime-light-h:hover {
  background-color: #bae637 !important;
}
.bg-lime-h:hover {
  background-color: #a0d911 !important;
}
.bg-lime-dark-h:hover {
  background-color: #7cb305 !important;
}
.text-lime-light {
  color: #bae637 !important;
}
.text-lime {
  color: #a0d911 !important;
}
.text-lime-dark {
  color: #7cb305 !important;
}
.bg-green-light {
  background-color: #73d13d !important;
}
.bg-green {
  background-color: #52c41a !important;
}
.bg-green-dark {
  background-color: #389e0d !important;
}
.bg-green-light-h:hover {
  background-color: #73d13d !important;
}
.bg-green-h:hover {
  background-color: #52c41a !important;
}
.bg-green-dark-h:hover {
  background-color: #389e0d !important;
}
.text-green-light {
  color: #73d13d !important;
}
.text-green {
  color: #52c41a !important;
}
.text-green-dark {
  color: #389e0d !important;
}
.bg-cyan-light {
  background-color: #36cfc9 !important;
}
.bg-cyan {
  background-color: #13c2c2 !important;
}
.bg-cyan-dark {
  background-color: #08979c !important;
}
.bg-cyan-light-h:hover {
  background-color: #36cfc9 !important;
}
.bg-cyan-h:hover {
  background-color: #13c2c2 !important;
}
.bg-cyan-dark-h:hover {
  background-color: #08979c !important;
}
.text-cyan-light {
  color: #36cfc9 !important;
}
.text-cyan {
  color: #13c2c2 !important;
}
.text-cyan-dark {
  color: #08979c !important;
}
.bg-blue-light {
  background-color: #40a9ff !important;
}
.bg-blue {
  background-color: #1890ff !important;
}
.bg-blue-dark {
  background-color: #096dd9 !important;
}
.bg-blue-light-h:hover {
  background-color: #40a9ff !important;
}
.bg-blue-h:hover {
  background-color: #1890ff !important;
}
.bg-blue-dark-h:hover {
  background-color: #096dd9 !important;
}
.text-blue-light {
  color: #40a9ff !important;
}
.text-blue {
  color: #1890ff !important;
}
.text-blue-dark {
  color: #096dd9 !important;
}
.bg-geekblue-light {
  background-color: #597ef7 !important;
}
.bg-geekblue {
  background-color: #2f54eb !important;
}
.bg-geekblue-dark {
  background-color: #1d39c4 !important;
}
.bg-geekblue-light-h:hover {
  background-color: #597ef7 !important;
}
.bg-geekblue-h:hover {
  background-color: #2f54eb !important;
}
.bg-geekblue-dark-h:hover {
  background-color: #1d39c4 !important;
}
.text-geekblue-light {
  color: #597ef7 !important;
}
.text-geekblue {
  color: #2f54eb !important;
}
.text-geekblue-dark {
  color: #1d39c4 !important;
}
.bg-purple-light {
  background-color: #9254de !important;
}
.bg-purple {
  background-color: #722ed1 !important;
}
.bg-purple-dark {
  background-color: #531dab !important;
}
.bg-purple-light-h:hover {
  background-color: #9254de !important;
}
.bg-purple-h:hover {
  background-color: #722ed1 !important;
}
.bg-purple-dark-h:hover {
  background-color: #531dab !important;
}
.text-purple-light {
  color: #9254de !important;
}
.text-purple {
  color: #722ed1 !important;
}
.text-purple-dark {
  color: #531dab !important;
}
.bg-magenta-light {
  background-color: #f759ab !important;
}
.bg-magenta {
  background-color: #eb2f96 !important;
}
.bg-magenta-dark {
  background-color: #c41d7f !important;
}
.bg-magenta-light-h:hover {
  background-color: #f759ab !important;
}
.bg-magenta-h:hover {
  background-color: #eb2f96 !important;
}
.bg-magenta-dark-h:hover {
  background-color: #c41d7f !important;
}
.text-magenta-light {
  color: #f759ab !important;
}
.text-magenta {
  color: #eb2f96 !important;
}
.text-magenta-dark {
  color: #c41d7f !important;
}
.bg-grey-light {
  background-color: #d9d9d9 !important;
}
.bg-grey {
  background-color: #bfbfbf !important;
}
.bg-grey-dark {
  background-color: #8c8c8c !important;
}
.bg-grey-light-h:hover {
  background-color: #d9d9d9 !important;
}
.bg-grey-h:hover {
  background-color: #bfbfbf !important;
}
.bg-grey-dark-h:hover {
  background-color: #8c8c8c !important;
}
.text-grey-light {
  color: #d9d9d9 !important;
}
.text-grey {
  color: #bfbfbf !important;
}
.text-grey-dark {
  color: #8c8c8c !important;
}
.bg-primary-light {
  background-color: #40a9ff !important;
}
.bg-primary {
  background-color: #1890ff !important;
}
.bg-primary-dark {
  background-color: #096dd9 !important;
}
.bg-primary-light-h:hover {
  background-color: #40a9ff !important;
}
.bg-primary-h:hover {
  background-color: #1890ff !important;
}
.bg-primary-dark-h:hover {
  background-color: #096dd9 !important;
}
.text-primary-light {
  color: #40a9ff !important;
}
.text-primary {
  color: #1890ff !important;
}
.text-primary-dark {
  color: #096dd9 !important;
}
.bg-success-light {
  background-color: #73d13d !important;
}
.bg-success {
  background-color: #52c41a !important;
}
.bg-success-dark {
  background-color: #389e0d !important;
}
.bg-success-light-h:hover {
  background-color: #73d13d !important;
}
.bg-success-h:hover {
  background-color: #52c41a !important;
}
.bg-success-dark-h:hover {
  background-color: #389e0d !important;
}
.text-success-light {
  color: #73d13d !important;
}
.text-success {
  color: #52c41a !important;
}
.text-success-dark {
  color: #389e0d !important;
}
.bg-error-light {
  background-color: #ff4d4f !important;
}
.bg-error {
  background-color: #f5222d !important;
}
.bg-error-dark {
  background-color: #cf1322 !important;
}
.bg-error-light-h:hover {
  background-color: #ff4d4f !important;
}
.bg-error-h:hover {
  background-color: #f5222d !important;
}
.bg-error-dark-h:hover {
  background-color: #cf1322 !important;
}
.text-error-light {
  color: #ff4d4f !important;
}
.text-error {
  color: #f5222d !important;
}
.text-error-dark {
  color: #cf1322 !important;
}
.bg-warning-light {
  background-color: #ffec3d !important;
}
.bg-warning {
  background-color: #fadb14 !important;
}
.bg-warning-dark {
  background-color: #d4b106 !important;
}
.bg-warning-light-h:hover {
  background-color: #ffec3d !important;
}
.bg-warning-h:hover {
  background-color: #fadb14 !important;
}
.bg-warning-dark-h:hover {
  background-color: #d4b106 !important;
}
.text-warning-light {
  color: #ffec3d !important;
}
.text-warning {
  color: #fadb14 !important;
}
.text-warning-dark {
  color: #d4b106 !important;
}
.bg-grey-lighter {
  background-color: #f5f5f5 !important;
}
.bg-grey-lighter-h:hover {
  background-color: #f5f5f5 !important;
}
.text-grey-lighter {
  color: #f5f5f5 !important;
}
.bg-grey-darker {
  background-color: #262626 !important;
}
.bg-grey-darker-h:hover {
  background-color: #262626 !important;
}
.text-grey-darker {
  color: #262626 !important;
}
.bg-red-1 {
  background-color: #fff1f0 !important;
}
.bg-red-1-h:hover {
  background-color: #fff1f0 !important;
}
.text-red-1 {
  color: #fff1f0 !important;
}
.bg-red-2 {
  background-color: #ffccc7 !important;
}
.bg-red-2-h:hover {
  background-color: #ffccc7 !important;
}
.text-red-2 {
  color: #ffccc7 !important;
}
.bg-red-3 {
  background-color: #ffa39e !important;
}
.bg-red-3-h:hover {
  background-color: #ffa39e !important;
}
.text-red-3 {
  color: #ffa39e !important;
}
.bg-red-4 {
  background-color: #ff7875 !important;
}
.bg-red-4-h:hover {
  background-color: #ff7875 !important;
}
.text-red-4 {
  color: #ff7875 !important;
}
.bg-red-5 {
  background-color: #ff4d4f !important;
}
.bg-red-5-h:hover {
  background-color: #ff4d4f !important;
}
.text-red-5 {
  color: #ff4d4f !important;
}
.bg-red-6 {
  background-color: #f5222d !important;
}
.bg-red-6-h:hover {
  background-color: #f5222d !important;
}
.text-red-6 {
  color: #f5222d !important;
}
.bg-red-7 {
  background-color: #cf1322 !important;
}
.bg-red-7-h:hover {
  background-color: #cf1322 !important;
}
.text-red-7 {
  color: #cf1322 !important;
}
.bg-red-8 {
  background-color: #a8071a !important;
}
.bg-red-8-h:hover {
  background-color: #a8071a !important;
}
.text-red-8 {
  color: #a8071a !important;
}
.bg-red-9 {
  background-color: #820014 !important;
}
.bg-red-9-h:hover {
  background-color: #820014 !important;
}
.text-red-9 {
  color: #820014 !important;
}
.bg-red-10 {
  background-color: #5c0011 !important;
}
.bg-red-10-h:hover {
  background-color: #5c0011 !important;
}
.text-red-10 {
  color: #5c0011 !important;
}
.bg-volcano-1 {
  background-color: #fff2e8 !important;
}
.bg-volcano-1-h:hover {
  background-color: #fff2e8 !important;
}
.text-volcano-1 {
  color: #fff2e8 !important;
}
.bg-volcano-2 {
  background-color: #ffd8bf !important;
}
.bg-volcano-2-h:hover {
  background-color: #ffd8bf !important;
}
.text-volcano-2 {
  color: #ffd8bf !important;
}
.bg-volcano-3 {
  background-color: #ffbb96 !important;
}
.bg-volcano-3-h:hover {
  background-color: #ffbb96 !important;
}
.text-volcano-3 {
  color: #ffbb96 !important;
}
.bg-volcano-4 {
  background-color: #ff9c6e !important;
}
.bg-volcano-4-h:hover {
  background-color: #ff9c6e !important;
}
.text-volcano-4 {
  color: #ff9c6e !important;
}
.bg-volcano-5 {
  background-color: #ff7a45 !important;
}
.bg-volcano-5-h:hover {
  background-color: #ff7a45 !important;
}
.text-volcano-5 {
  color: #ff7a45 !important;
}
.bg-volcano-6 {
  background-color: #fa541c !important;
}
.bg-volcano-6-h:hover {
  background-color: #fa541c !important;
}
.text-volcano-6 {
  color: #fa541c !important;
}
.bg-volcano-7 {
  background-color: #d4380d !important;
}
.bg-volcano-7-h:hover {
  background-color: #d4380d !important;
}
.text-volcano-7 {
  color: #d4380d !important;
}
.bg-volcano-8 {
  background-color: #ad2102 !important;
}
.bg-volcano-8-h:hover {
  background-color: #ad2102 !important;
}
.text-volcano-8 {
  color: #ad2102 !important;
}
.bg-volcano-9 {
  background-color: #871400 !important;
}
.bg-volcano-9-h:hover {
  background-color: #871400 !important;
}
.text-volcano-9 {
  color: #871400 !important;
}
.bg-volcano-10 {
  background-color: #610b00 !important;
}
.bg-volcano-10-h:hover {
  background-color: #610b00 !important;
}
.text-volcano-10 {
  color: #610b00 !important;
}
.bg-orange-1 {
  background-color: #fff7e6 !important;
}
.bg-orange-1-h:hover {
  background-color: #fff7e6 !important;
}
.text-orange-1 {
  color: #fff7e6 !important;
}
.bg-orange-2 {
  background-color: #ffe7ba !important;
}
.bg-orange-2-h:hover {
  background-color: #ffe7ba !important;
}
.text-orange-2 {
  color: #ffe7ba !important;
}
.bg-orange-3 {
  background-color: #ffd591 !important;
}
.bg-orange-3-h:hover {
  background-color: #ffd591 !important;
}
.text-orange-3 {
  color: #ffd591 !important;
}
.bg-orange-4 {
  background-color: #ffc069 !important;
}
.bg-orange-4-h:hover {
  background-color: #ffc069 !important;
}
.text-orange-4 {
  color: #ffc069 !important;
}
.bg-orange-5 {
  background-color: #ffa940 !important;
}
.bg-orange-5-h:hover {
  background-color: #ffa940 !important;
}
.text-orange-5 {
  color: #ffa940 !important;
}
.bg-orange-6 {
  background-color: #fa8c16 !important;
}
.bg-orange-6-h:hover {
  background-color: #fa8c16 !important;
}
.text-orange-6 {
  color: #fa8c16 !important;
}
.bg-orange-7 {
  background-color: #d46b08 !important;
}
.bg-orange-7-h:hover {
  background-color: #d46b08 !important;
}
.text-orange-7 {
  color: #d46b08 !important;
}
.bg-orange-8 {
  background-color: #ad4e00 !important;
}
.bg-orange-8-h:hover {
  background-color: #ad4e00 !important;
}
.text-orange-8 {
  color: #ad4e00 !important;
}
.bg-orange-9 {
  background-color: #873800 !important;
}
.bg-orange-9-h:hover {
  background-color: #873800 !important;
}
.text-orange-9 {
  color: #873800 !important;
}
.bg-orange-10 {
  background-color: #612500 !important;
}
.bg-orange-10-h:hover {
  background-color: #612500 !important;
}
.text-orange-10 {
  color: #612500 !important;
}
.bg-gold-1 {
  background-color: #fffbe6 !important;
}
.bg-gold-1-h:hover {
  background-color: #fffbe6 !important;
}
.text-gold-1 {
  color: #fffbe6 !important;
}
.bg-gold-2 {
  background-color: #fff1b8 !important;
}
.bg-gold-2-h:hover {
  background-color: #fff1b8 !important;
}
.text-gold-2 {
  color: #fff1b8 !important;
}
.bg-gold-3 {
  background-color: #ffe58f !important;
}
.bg-gold-3-h:hover {
  background-color: #ffe58f !important;
}
.text-gold-3 {
  color: #ffe58f !important;
}
.bg-gold-4 {
  background-color: #ffd666 !important;
}
.bg-gold-4-h:hover {
  background-color: #ffd666 !important;
}
.text-gold-4 {
  color: #ffd666 !important;
}
.bg-gold-5 {
  background-color: #ffc53d !important;
}
.bg-gold-5-h:hover {
  background-color: #ffc53d !important;
}
.text-gold-5 {
  color: #ffc53d !important;
}
.bg-gold-6 {
  background-color: #faad14 !important;
}
.bg-gold-6-h:hover {
  background-color: #faad14 !important;
}
.text-gold-6 {
  color: #faad14 !important;
}
.bg-gold-7 {
  background-color: #d48806 !important;
}
.bg-gold-7-h:hover {
  background-color: #d48806 !important;
}
.text-gold-7 {
  color: #d48806 !important;
}
.bg-gold-8 {
  background-color: #ad6800 !important;
}
.bg-gold-8-h:hover {
  background-color: #ad6800 !important;
}
.text-gold-8 {
  color: #ad6800 !important;
}
.bg-gold-9 {
  background-color: #874d00 !important;
}
.bg-gold-9-h:hover {
  background-color: #874d00 !important;
}
.text-gold-9 {
  color: #874d00 !important;
}
.bg-gold-10 {
  background-color: #613400 !important;
}
.bg-gold-10-h:hover {
  background-color: #613400 !important;
}
.text-gold-10 {
  color: #613400 !important;
}
.bg-yellow-1 {
  background-color: #feffe6 !important;
}
.bg-yellow-1-h:hover {
  background-color: #feffe6 !important;
}
.text-yellow-1 {
  color: #feffe6 !important;
}
.bg-yellow-2 {
  background-color: #ffffb8 !important;
}
.bg-yellow-2-h:hover {
  background-color: #ffffb8 !important;
}
.text-yellow-2 {
  color: #ffffb8 !important;
}
.bg-yellow-3 {
  background-color: #fffb8f !important;
}
.bg-yellow-3-h:hover {
  background-color: #fffb8f !important;
}
.text-yellow-3 {
  color: #fffb8f !important;
}
.bg-yellow-4 {
  background-color: #fff566 !important;
}
.bg-yellow-4-h:hover {
  background-color: #fff566 !important;
}
.text-yellow-4 {
  color: #fff566 !important;
}
.bg-yellow-5 {
  background-color: #ffec3d !important;
}
.bg-yellow-5-h:hover {
  background-color: #ffec3d !important;
}
.text-yellow-5 {
  color: #ffec3d !important;
}
.bg-yellow-6 {
  background-color: #fadb14 !important;
}
.bg-yellow-6-h:hover {
  background-color: #fadb14 !important;
}
.text-yellow-6 {
  color: #fadb14 !important;
}
.bg-yellow-7 {
  background-color: #d4b106 !important;
}
.bg-yellow-7-h:hover {
  background-color: #d4b106 !important;
}
.text-yellow-7 {
  color: #d4b106 !important;
}
.bg-yellow-8 {
  background-color: #ad8b00 !important;
}
.bg-yellow-8-h:hover {
  background-color: #ad8b00 !important;
}
.text-yellow-8 {
  color: #ad8b00 !important;
}
.bg-yellow-9 {
  background-color: #876800 !important;
}
.bg-yellow-9-h:hover {
  background-color: #876800 !important;
}
.text-yellow-9 {
  color: #876800 !important;
}
.bg-yellow-10 {
  background-color: #614700 !important;
}
.bg-yellow-10-h:hover {
  background-color: #614700 !important;
}
.text-yellow-10 {
  color: #614700 !important;
}
.bg-lime-1 {
  background-color: #fcffe6 !important;
}
.bg-lime-1-h:hover {
  background-color: #fcffe6 !important;
}
.text-lime-1 {
  color: #fcffe6 !important;
}
.bg-lime-2 {
  background-color: #f4ffb8 !important;
}
.bg-lime-2-h:hover {
  background-color: #f4ffb8 !important;
}
.text-lime-2 {
  color: #f4ffb8 !important;
}
.bg-lime-3 {
  background-color: #eaff8f !important;
}
.bg-lime-3-h:hover {
  background-color: #eaff8f !important;
}
.text-lime-3 {
  color: #eaff8f !important;
}
.bg-lime-4 {
  background-color: #d3f261 !important;
}
.bg-lime-4-h:hover {
  background-color: #d3f261 !important;
}
.text-lime-4 {
  color: #d3f261 !important;
}
.bg-lime-5 {
  background-color: #bae637 !important;
}
.bg-lime-5-h:hover {
  background-color: #bae637 !important;
}
.text-lime-5 {
  color: #bae637 !important;
}
.bg-lime-6 {
  background-color: #a0d911 !important;
}
.bg-lime-6-h:hover {
  background-color: #a0d911 !important;
}
.text-lime-6 {
  color: #a0d911 !important;
}
.bg-lime-7 {
  background-color: #7cb305 !important;
}
.bg-lime-7-h:hover {
  background-color: #7cb305 !important;
}
.text-lime-7 {
  color: #7cb305 !important;
}
.bg-lime-8 {
  background-color: #5b8c00 !important;
}
.bg-lime-8-h:hover {
  background-color: #5b8c00 !important;
}
.text-lime-8 {
  color: #5b8c00 !important;
}
.bg-lime-9 {
  background-color: #3f6600 !important;
}
.bg-lime-9-h:hover {
  background-color: #3f6600 !important;
}
.text-lime-9 {
  color: #3f6600 !important;
}
.bg-lime-10 {
  background-color: #254000 !important;
}
.bg-lime-10-h:hover {
  background-color: #254000 !important;
}
.text-lime-10 {
  color: #254000 !important;
}
.bg-green-1 {
  background-color: #f6ffed !important;
}
.bg-green-1-h:hover {
  background-color: #f6ffed !important;
}
.text-green-1 {
  color: #f6ffed !important;
}
.bg-green-2 {
  background-color: #d9f7be !important;
}
.bg-green-2-h:hover {
  background-color: #d9f7be !important;
}
.text-green-2 {
  color: #d9f7be !important;
}
.bg-green-3 {
  background-color: #b7eb8f !important;
}
.bg-green-3-h:hover {
  background-color: #b7eb8f !important;
}
.text-green-3 {
  color: #b7eb8f !important;
}
.bg-green-4 {
  background-color: #95de64 !important;
}
.bg-green-4-h:hover {
  background-color: #95de64 !important;
}
.text-green-4 {
  color: #95de64 !important;
}
.bg-green-5 {
  background-color: #73d13d !important;
}
.bg-green-5-h:hover {
  background-color: #73d13d !important;
}
.text-green-5 {
  color: #73d13d !important;
}
.bg-green-6 {
  background-color: #52c41a !important;
}
.bg-green-6-h:hover {
  background-color: #52c41a !important;
}
.text-green-6 {
  color: #52c41a !important;
}
.bg-green-7 {
  background-color: #389e0d !important;
}
.bg-green-7-h:hover {
  background-color: #389e0d !important;
}
.text-green-7 {
  color: #389e0d !important;
}
.bg-green-8 {
  background-color: #237804 !important;
}
.bg-green-8-h:hover {
  background-color: #237804 !important;
}
.text-green-8 {
  color: #237804 !important;
}
.bg-green-9 {
  background-color: #135200 !important;
}
.bg-green-9-h:hover {
  background-color: #135200 !important;
}
.text-green-9 {
  color: #135200 !important;
}
.bg-green-10 {
  background-color: #092b00 !important;
}
.bg-green-10-h:hover {
  background-color: #092b00 !important;
}
.text-green-10 {
  color: #092b00 !important;
}
.bg-cyan-1 {
  background-color: #e6fffb !important;
}
.bg-cyan-1-h:hover {
  background-color: #e6fffb !important;
}
.text-cyan-1 {
  color: #e6fffb !important;
}
.bg-cyan-2 {
  background-color: #b5f5ec !important;
}
.bg-cyan-2-h:hover {
  background-color: #b5f5ec !important;
}
.text-cyan-2 {
  color: #b5f5ec !important;
}
.bg-cyan-3 {
  background-color: #87e8de !important;
}
.bg-cyan-3-h:hover {
  background-color: #87e8de !important;
}
.text-cyan-3 {
  color: #87e8de !important;
}
.bg-cyan-4 {
  background-color: #5cdbd3 !important;
}
.bg-cyan-4-h:hover {
  background-color: #5cdbd3 !important;
}
.text-cyan-4 {
  color: #5cdbd3 !important;
}
.bg-cyan-5 {
  background-color: #36cfc9 !important;
}
.bg-cyan-5-h:hover {
  background-color: #36cfc9 !important;
}
.text-cyan-5 {
  color: #36cfc9 !important;
}
.bg-cyan-6 {
  background-color: #13c2c2 !important;
}
.bg-cyan-6-h:hover {
  background-color: #13c2c2 !important;
}
.text-cyan-6 {
  color: #13c2c2 !important;
}
.bg-cyan-7 {
  background-color: #08979c !important;
}
.bg-cyan-7-h:hover {
  background-color: #08979c !important;
}
.text-cyan-7 {
  color: #08979c !important;
}
.bg-cyan-8 {
  background-color: #006d75 !important;
}
.bg-cyan-8-h:hover {
  background-color: #006d75 !important;
}
.text-cyan-8 {
  color: #006d75 !important;
}
.bg-cyan-9 {
  background-color: #00474f !important;
}
.bg-cyan-9-h:hover {
  background-color: #00474f !important;
}
.text-cyan-9 {
  color: #00474f !important;
}
.bg-cyan-10 {
  background-color: #002329 !important;
}
.bg-cyan-10-h:hover {
  background-color: #002329 !important;
}
.text-cyan-10 {
  color: #002329 !important;
}
.bg-blue-1 {
  background-color: #e6f7ff !important;
}
.bg-blue-1-h:hover {
  background-color: #e6f7ff !important;
}
.text-blue-1 {
  color: #e6f7ff !important;
}
.bg-blue-2 {
  background-color: #bae7ff !important;
}
.bg-blue-2-h:hover {
  background-color: #bae7ff !important;
}
.text-blue-2 {
  color: #bae7ff !important;
}
.bg-blue-3 {
  background-color: #91d5ff !important;
}
.bg-blue-3-h:hover {
  background-color: #91d5ff !important;
}
.text-blue-3 {
  color: #91d5ff !important;
}
.bg-blue-4 {
  background-color: #69c0ff !important;
}
.bg-blue-4-h:hover {
  background-color: #69c0ff !important;
}
.text-blue-4 {
  color: #69c0ff !important;
}
.bg-blue-5 {
  background-color: #40a9ff !important;
}
.bg-blue-5-h:hover {
  background-color: #40a9ff !important;
}
.text-blue-5 {
  color: #40a9ff !important;
}
.bg-blue-6 {
  background-color: #1890ff !important;
}
.bg-blue-6-h:hover {
  background-color: #1890ff !important;
}
.text-blue-6 {
  color: #1890ff !important;
}
.bg-blue-7 {
  background-color: #096dd9 !important;
}
.bg-blue-7-h:hover {
  background-color: #096dd9 !important;
}
.text-blue-7 {
  color: #096dd9 !important;
}
.bg-blue-8 {
  background-color: #0050b3 !important;
}
.bg-blue-8-h:hover {
  background-color: #0050b3 !important;
}
.text-blue-8 {
  color: #0050b3 !important;
}
.bg-blue-9 {
  background-color: #003a8c !important;
}
.bg-blue-9-h:hover {
  background-color: #003a8c !important;
}
.text-blue-9 {
  color: #003a8c !important;
}
.bg-blue-10 {
  background-color: #002766 !important;
}
.bg-blue-10-h:hover {
  background-color: #002766 !important;
}
.text-blue-10 {
  color: #002766 !important;
}
.bg-geekblue-1 {
  background-color: #f0f5ff !important;
}
.bg-geekblue-1-h:hover {
  background-color: #f0f5ff !important;
}
.text-geekblue-1 {
  color: #f0f5ff !important;
}
.bg-geekblue-2 {
  background-color: #d6e4ff !important;
}
.bg-geekblue-2-h:hover {
  background-color: #d6e4ff !important;
}
.text-geekblue-2 {
  color: #d6e4ff !important;
}
.bg-geekblue-3 {
  background-color: #adc6ff !important;
}
.bg-geekblue-3-h:hover {
  background-color: #adc6ff !important;
}
.text-geekblue-3 {
  color: #adc6ff !important;
}
.bg-geekblue-4 {
  background-color: #85a5ff !important;
}
.bg-geekblue-4-h:hover {
  background-color: #85a5ff !important;
}
.text-geekblue-4 {
  color: #85a5ff !important;
}
.bg-geekblue-5 {
  background-color: #597ef7 !important;
}
.bg-geekblue-5-h:hover {
  background-color: #597ef7 !important;
}
.text-geekblue-5 {
  color: #597ef7 !important;
}
.bg-geekblue-6 {
  background-color: #2f54eb !important;
}
.bg-geekblue-6-h:hover {
  background-color: #2f54eb !important;
}
.text-geekblue-6 {
  color: #2f54eb !important;
}
.bg-geekblue-7 {
  background-color: #1d39c4 !important;
}
.bg-geekblue-7-h:hover {
  background-color: #1d39c4 !important;
}
.text-geekblue-7 {
  color: #1d39c4 !important;
}
.bg-geekblue-8 {
  background-color: #10239e !important;
}
.bg-geekblue-8-h:hover {
  background-color: #10239e !important;
}
.text-geekblue-8 {
  color: #10239e !important;
}
.bg-geekblue-9 {
  background-color: #061178 !important;
}
.bg-geekblue-9-h:hover {
  background-color: #061178 !important;
}
.text-geekblue-9 {
  color: #061178 !important;
}
.bg-geekblue-10 {
  background-color: #030852 !important;
}
.bg-geekblue-10-h:hover {
  background-color: #030852 !important;
}
.text-geekblue-10 {
  color: #030852 !important;
}
.bg-purple-1 {
  background-color: #f9f0ff !important;
}
.bg-purple-1-h:hover {
  background-color: #f9f0ff !important;
}
.text-purple-1 {
  color: #f9f0ff !important;
}
.bg-purple-2 {
  background-color: #efdbff !important;
}
.bg-purple-2-h:hover {
  background-color: #efdbff !important;
}
.text-purple-2 {
  color: #efdbff !important;
}
.bg-purple-3 {
  background-color: #d3adf7 !important;
}
.bg-purple-3-h:hover {
  background-color: #d3adf7 !important;
}
.text-purple-3 {
  color: #d3adf7 !important;
}
.bg-purple-4 {
  background-color: #b37feb !important;
}
.bg-purple-4-h:hover {
  background-color: #b37feb !important;
}
.text-purple-4 {
  color: #b37feb !important;
}
.bg-purple-5 {
  background-color: #9254de !important;
}
.bg-purple-5-h:hover {
  background-color: #9254de !important;
}
.text-purple-5 {
  color: #9254de !important;
}
.bg-purple-6 {
  background-color: #722ed1 !important;
}
.bg-purple-6-h:hover {
  background-color: #722ed1 !important;
}
.text-purple-6 {
  color: #722ed1 !important;
}
.bg-purple-7 {
  background-color: #531dab !important;
}
.bg-purple-7-h:hover {
  background-color: #531dab !important;
}
.text-purple-7 {
  color: #531dab !important;
}
.bg-purple-8 {
  background-color: #391085 !important;
}
.bg-purple-8-h:hover {
  background-color: #391085 !important;
}
.text-purple-8 {
  color: #391085 !important;
}
.bg-purple-9 {
  background-color: #22075e !important;
}
.bg-purple-9-h:hover {
  background-color: #22075e !important;
}
.text-purple-9 {
  color: #22075e !important;
}
.bg-purple-10 {
  background-color: #120338 !important;
}
.bg-purple-10-h:hover {
  background-color: #120338 !important;
}
.text-purple-10 {
  color: #120338 !important;
}
.bg-magenta-1 {
  background-color: #fff0f6 !important;
}
.bg-magenta-1-h:hover {
  background-color: #fff0f6 !important;
}
.text-magenta-1 {
  color: #fff0f6 !important;
}
.bg-magenta-2 {
  background-color: #ffd6e7 !important;
}
.bg-magenta-2-h:hover {
  background-color: #ffd6e7 !important;
}
.text-magenta-2 {
  color: #ffd6e7 !important;
}
.bg-magenta-3 {
  background-color: #ffadd2 !important;
}
.bg-magenta-3-h:hover {
  background-color: #ffadd2 !important;
}
.text-magenta-3 {
  color: #ffadd2 !important;
}
.bg-magenta-4 {
  background-color: #ff85c0 !important;
}
.bg-magenta-4-h:hover {
  background-color: #ff85c0 !important;
}
.text-magenta-4 {
  color: #ff85c0 !important;
}
.bg-magenta-5 {
  background-color: #f759ab !important;
}
.bg-magenta-5-h:hover {
  background-color: #f759ab !important;
}
.text-magenta-5 {
  color: #f759ab !important;
}
.bg-magenta-6 {
  background-color: #eb2f96 !important;
}
.bg-magenta-6-h:hover {
  background-color: #eb2f96 !important;
}
.text-magenta-6 {
  color: #eb2f96 !important;
}
.bg-magenta-7 {
  background-color: #c41d7f !important;
}
.bg-magenta-7-h:hover {
  background-color: #c41d7f !important;
}
.text-magenta-7 {
  color: #c41d7f !important;
}
.bg-magenta-8 {
  background-color: #9e1068 !important;
}
.bg-magenta-8-h:hover {
  background-color: #9e1068 !important;
}
.text-magenta-8 {
  color: #9e1068 !important;
}
.bg-magenta-9 {
  background-color: #780650 !important;
}
.bg-magenta-9-h:hover {
  background-color: #780650 !important;
}
.text-magenta-9 {
  color: #780650 !important;
}
.bg-magenta-10 {
  background-color: #520339 !important;
}
.bg-magenta-10-h:hover {
  background-color: #520339 !important;
}
.text-magenta-10 {
  color: #520339 !important;
}
.bg-grey-1 {
  background-color: #ffffff !important;
}
.bg-grey-1-h:hover {
  background-color: #ffffff !important;
}
.text-grey-1 {
  color: #ffffff !important;
}
.bg-grey-2 {
  background-color: #fafafa !important;
}
.bg-grey-2-h:hover {
  background-color: #fafafa !important;
}
.text-grey-2 {
  color: #fafafa !important;
}
.bg-grey-3 {
  background-color: #f5f5f5 !important;
}
.bg-grey-3-h:hover {
  background-color: #f5f5f5 !important;
}
.text-grey-3 {
  color: #f5f5f5 !important;
}
.bg-grey-4 {
  background-color: #e8e8e8 !important;
}
.bg-grey-4-h:hover {
  background-color: #e8e8e8 !important;
}
.text-grey-4 {
  color: #e8e8e8 !important;
}
.bg-grey-5 {
  background-color: #d9d9d9 !important;
}
.bg-grey-5-h:hover {
  background-color: #d9d9d9 !important;
}
.text-grey-5 {
  color: #d9d9d9 !important;
}
.bg-grey-6 {
  background-color: #bfbfbf !important;
}
.bg-grey-6-h:hover {
  background-color: #bfbfbf !important;
}
.text-grey-6 {
  color: #bfbfbf !important;
}
.bg-grey-7 {
  background-color: #8c8c8c !important;
}
.bg-grey-7-h:hover {
  background-color: #8c8c8c !important;
}
.text-grey-7 {
  color: #8c8c8c !important;
}
.bg-grey-8 {
  background-color: #595959 !important;
}
.bg-grey-8-h:hover {
  background-color: #595959 !important;
}
.text-grey-8 {
  color: #595959 !important;
}
.bg-grey-9 {
  background-color: #262626 !important;
}
.bg-grey-9-h:hover {
  background-color: #262626 !important;
}
.text-grey-9 {
  color: #262626 !important;
}
.bg-grey-10 {
  background-color: #000000 !important;
}
.bg-grey-10-h:hover {
  background-color: #000000 !important;
}
.text-grey-10 {
  color: #000000 !important;
}
.no-data {
  color: rgba(0, 0, 0, 0.25);
}
.back-a {
  background-color: #F32121;
}
.back-b {
  background-color: #F57070;
}
.back-c {
  background-color: #11B464;
}
.back-d {
  background-color: #EB8319;
}
.back-e {
  background-color: #E7C515;
}
.back-g {
  background-color: #E037F0;
}
.back-h {
  background-color: #33CC99;
}
.back-i {
  background-color: #0099FF;
}
.back-j {
  background-color: #00CC00;
}
.back-k {
  background-color: #3366FF;
}
.back-l {
  background-color: #CC00FF;
}
.back-m {
  background-color: #9999FF;
}
.back-n {
  background-color: #CC6633;
}
.back-o {
  background-color: #CC9933;
}
.back-q {
  background-color: #CCCC33;
}
.back-r {
  background-color: #FF0033;
}
.back-s {
  background-color: #FF9933;
}
.back-t {
  background-color: #FF33CC;
}
.back-u {
  background-color: #CC66FF;
}
.back-v {
  background-color: #99CCFF;
}
.back-w {
  background-color: #33CCFF;
}
.back-y {
  background-color: #33CC33;
}
.back-z {
  background-color: #FFCC66;
}
.nav {
  background-color: #096dd9;
  color: #ffffff;
  font-size: 12px;
}
.nav a {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  display: inline-block;
  vertical-align: top;
}
.nav .ant-menu.ant-menu-dark .ant-menu-item-selected,
.nav .ant-menu.ant-menu-dark .ant-menu-item-active,
.nav .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.nav .ant-menu-dark .ant-menu-submenu-active,
.nav .ant-menu-dark .ant-menu-submenu-open,
.nav .ant-menu-dark .ant-menu-submenu-selected,
.nav .ant-menu-dark .ant-menu-submenu-title:hover {
  background-color: #3586f4;
}
.nav .ant-menu-dark,
.nav .ant-menu-dark .ant-menu-sub {
  background-color: transparent;
  color: #ffffff;
}
.nav .ant-menu-dark .ant-menu-item,
.nav .ant-menu-dark .ant-menu-item-group-title,
.nav .ant-menu-dark .ant-menu-item > a {
  color: #ffffff;
}
.nav .ant-menu-item,
.nav .ant-menu-submenu-title {
  width: 130px;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
  background: #096dd9 !important;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #3586f4 !important;
}
.head-logo {
  height: 32px;
  float: left;
  margin-right: 30px;
}
.head-logo img {
  height: 100%;
}
.header {
  border-bottom: 1px solid #e8e8e8;
  background-color: #ffffff;
  transition: all 0.2s;
}
.header .ant-menu-horizontal {
  border-bottom: none;
}
.header .container {
  display: flex;
  align-items: center;
}
.header .container .ant-menu {
  flex: 1;
}
.header .ant-menu li:hover {
  color: #00c1fb !important;
  border-bottom: 2px solid #00c1fb;
}
.header .ant-menu li:hover > a {
  color: #00c1fb !important;
}
.header .ant-menu li:hover .ant-menu-submenu-title {
  color: #00c1fb !important;
}
.global-search {
  width: 250px;
}
.global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 62px;
}
.global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
}
.global-search.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.global-search .global-search-item-count {
  position: absolute;
  right: 16px;
}
.ant-affix .header {
  box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
}
.head-menu {
  position: relative;
  overflow: hidden;
  z-index: 100;
}
/*申报项目*/
.theme-content {
  margin-top: -56px;
  position: relative;
  z-index: 2;
}
.theme-content .ant-menu-dark {
  background-color: #ffffff;
}
.theme-content .ant-menu-dark .ant-menu-item:hover {
  color: #096dd9;
}
.theme-content .ant-menu {
  border-radius: 6px;
  box-shadow: 0px 3px 16px 0px rgba(184, 201, 243, 0.6) !important;
  overflow: hidden;
  min-height: 590px;
}
.theme-content .ant-menu-inline .ant-menu-item {
  color: #556a7a;
  font-size: 16px;
  height: 56px;
  line-height: 56px;
  margin: 0;
}
.theme-content .ant-menu-inline .ant-menu-item .name {
  display: inline-block;
  width: 125px;
}
.theme-content .ant-menu-inline .ant-menu-item .icon {
  display: inline-block;
  font-size: 20px;
  margin-right: 10px;
  width: 20px;
  text-align: center;
}
.theme-content .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
}
.theme-content .ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #096dd9;
  color: #ffffff;
}
.logo span {
  font-size: 16px;
  margin-left: 10px;
  padding-left: 10px;
  position: relative;
  line-height: 58px;
}
.logo span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 30px;
  background-color: #000;
}
.project-content {
  position: relative;
}
.footer {
  background-color: #686c73;
  color: rgba(255, 255, 255, 0.54);
  padding: 52px 0;
  line-height: 2;
}
.footer .ant-typography {
  color: #ffffff;
}
.footer .footer-desc {
  margin-top: 10px;
  font-size: 14px;
}
.footer .footer-content {
  margin-right: 30px;
}
.footer .footer-content ul,
.footer .footer-content li {
  margin: 0;
  padding: 0 0 5px;
  list-style: none;
}
.footer .footer-content a {
  color: #60606d;
}
.footer .footer-content a:hover {
  color: #9ba5ff;
}
.footer .ant-col:last-child .footer-content {
  margin-right: 0;
}
.footer .footer-desc {
  padding-bottom: 15px;
}
.footer .footer-desc p {
  margin-bottom: 0;
}
.footer .footer-desc p i {
  margin-right: 3px;
}
.footer .footer-desc p i.icon-phone {
  margin-right: 6px;
}
.login-box {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 295px);
  overflow: hidden;
}
.login-box .login-mini {
  min-height: 500px;
}
.login-box video {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  filter: blur(9px);
  filter: progid:DXImageTransform.Microsoft.Blur(PixelRadius=10, MakeShadow=false);
  width: 102%;
}
.login-box .login-content-box {
  position: absolute;
  width: 360px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 0 6px rgba(255, 255, 255, 0.15);
  padding: 20px;
  border-radius: 4px;
}
.login-box .login-content-box .ant-form-item {
  margin-bottom: 14px;
}
.login-box .login-content-box .ant-typography {
  margin-bottom: 0.8em;
  text-align: left;
}
.login-box .login-back {
  background-color: rgba(108, 98, 255, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;
}
.login-form .images-auto {
  margin: 0 auto;
}
.login-form-forgot {
  float: right;
}
.login-form-button {
  width: 100%;
}
.erwe-icon {
  position: absolute;
  top: 18px;
  overflow: hidden;
  right: 20px;
  width: 60px;
  height: 60px;
  vertical-align: top;
  z-index: 14;
  cursor: pointer;
}
.erwe-icon::after {
  content: "";
  position: absolute;
  left: 14px;
  bottom: 11px;
  width: 12px;
  height: 19px;
  border-bottom: 49px solid #ededf4;
  border-right: 52px solid transparent;
}
.erwe-icon i {
  font-size: 45px;
  float: right;
  margin-top: -15px;
  cursor: pointer;
}
.login-tip {
  position: absolute;
  top: 24px;
  right: 70px;
}
.login-tip .poptip {
  border: 1px solid #f3d995;
  height: 27px;
  line-height: 16px;
  padding: 5px 20px 5px 15px;
  background: #fefcee;
  position: relative;
  z-index: 16;
}
.login-tip .poptip .poptip-arrow {
  top: 8px;
  right: 0;
}
.login-tip .poptip .poptip-arrow em {
  top: 6px;
  right: -7px;
  border-left-color: #f3d995;
  border-width: 6px 0 6px 6px;
}
.login-tip .poptip .poptip-arrow span {
  border-left-color: #fefcee;
  border-width: 6px 0 6px 6px;
  top: 6px;
  right: -5px;
}
.poptip-arrow em,
.poptip-arrow span {
  position: absolute;
  width: 0;
  height: 0;
  border-color: rgba(255, 255, 255, 0);
  border-color: transparent \0;
  border-style: solid;
  overflow: hidden;
  top: 0;
}
.poptip .poptip-content {
  color: #df9c1f;
  font-size: 12px;
  font-weight: 400;
}
.login-statu {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 15px;
}
.login-statu i {
  vertical-align: text-bottom;
  line-height: 39px;
  margin-right: 10px;
  color: #48a6d9;
}
.login-statu span {
  display: block;
  text-align: left;
}
.login-statu div {
  display: inline-block;
}
.erwe-login-erwe .erwe-icon::after {
  opacity: 0;
}
.ant-card.register-box {
  margin: 16px 0;
  min-height: calc(100vh - 375px);
  background: #ffffff url("../images/back.png") bottom center repeat-x;
}
.ant-card.register-box .ant-card-body > .ant-row {
  margin: 16px 0;
}
.code-btn {
  height: 40px;
  line-height: 40px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  border: #d9d9d9 solid 1px;
  text-align: center;
}
.code-btn:hover {
  color: #096dd9;
  border-color: #096dd9;
}
.index-header {
  display: flex;
  justify-content: center;
}
/*服务*/
.tag-select {
  margin-top: 20px;
}
.tag-select h3 {
  color: #666666;
  font-weight: normal;
}
/*服务end*/
/*首页园区切换*/
.index-content {
  position: absolute;
  top: 76px;
  left: 0;
  right: 0;
  bottom: 0;
  min-width: 1200px;
  overflow: hidden;
}
.index-content .swiper-wrapper .swiper-slide {
  height: calc(100vh - 76px) !important;
}
.index-content .swiper-wrapper .swiper-slide.auto-height {
  height: 264px !important;
}
.park-select {
  width: 380px;
  overflow: hidden;
}
.park-select .ant-radio-wrapper {
  width: calc(50% - 8px);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.park-select-title {
  height: 35px;
  line-height: 35px;
}
.park-select-title i {
  margin-right: 5px;
}
/*搜索列表*/
.page-header {
  margin-top: 32px;
  margin-bottom: 32px;
  position: relative;
  background-color: #ffffff;
}
.page-header:after {
  content: "";
  display: table;
  clear: both;
}
.page-header .ant-input-searchn {
  margin-left: 16px;
}
/*搜索列表end*/
/*活动详情*/
.collect-badge {
  cursor: pointer;
  margin: 0 10px;
  display: inline-block;
  vertical-align: middle;
}
.collect-badge .icon {
  margin-right: 3px;
  font-size: 14px;
}
.collect-badge:hover,
.collect-badge.active {
  color: #096dd9;
}
.share-badge {
  cursor: pointer;
  margin: 0 0 0 5px;
  display: inline-block;
  vertical-align: middle;
}
.share-badge .icon {
  margin-right: 3px;
  font-size: 14px;
}
.share-badge:hover,
.share-badge.active {
  color: #096dd9;
}
.check-map {
  height: 32px;
  width: 88px;
  display: inline-block;
  border-radius: 4px;
  font-size: 14px;
  color: #096dd9;
  border: 1px solid #096dd9;
  text-align: center;
  line-height: 29px;
}
.check-map:hover {
  background-color: #096dd9;
  color: #fff;
}
.details-intro-header .rows {
  width: 100%;
}
.details-intro-header .rows em {
  display: inline-block;
  font-style: normal;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.45);
}
/*活动详情end*/
/*资讯详情*/
.right-hoving {
  position: fixed;
  max-width: 246px;
  z-index: 9;
  top: 155px;
}
.hovering-box {
  position: fixed;
  width: 1200px;
  left: 50%;
  transform: translate(-50%, 0);
  top: 46px;
  padding-top: 16px;
  text-align: center;
  background-color: #fff;
  z-index: 9;
}
.hovering-box .hovering-name {
  background-color: #fff;
  border: solid 1px #eee;
  padding: 15px;
}
.hovering-box .hovering-name > span {
  color: #333;
  font-size: 18px;
  line-height: 1.6;
  font-weight: bold;
}
.tabs-policy .ant-tabs-left-bar {
  height: 275px !important;
}
.tabs-policy.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 10px !important;
}
.hoveing-tabs.ant-tabs .ant-tabs-bar {
  border: none;
}
.hoveing-tabs.ant-tabs .ant-tabs-nav-wrap {
  position: fixed !important;
  width: 100%;
  top: 148px;
  padding: 46px 0 10px 0;
  background-color: #fff;
}
.hoveing-tabs .ant-tabs-nav-scroll {
  position: relative;
}
.hoveing-tabs .ant-tabs-nav-scroll::after {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 880px;
  height: 1px;
  background-color: #e5e5e5;
  z-index: -1;
}
.info-article-name {
  font-size: 24px;
  text-align: left;
  color: #293335;
}
.info-article-type {
  margin-top: 20px;
}
.info-type-date > span {
  margin-right: 100px;
}
.info-type-date > span:last-child {
  margin-right: 0;
}
.info-type-operate .icon {
  font-size: 14px;
  margin-left: 5px;
}
.info-type-operate span {
  margin-left: 20px;
  cursor: pointer;
  vertical-align: top;
}
.info-type-operate span:hover {
  color: #096dd9;
}
.info-type-operate span:active {
  color: #096dd9;
}
.info-type-operate span:first-child {
  margin-left: 0;
}
.ant-card-body .info-article-details {
  padding: 0;
  margin-top: 40px;
}
.info-article-details {
  font-size: 16px;
  line-height: 1.7;
  padding: 16px;
  color: #666666;
}
.info-article-details * {
  max-width: 100% !important;
}
.info-comment {
  margin-top: 30px;
}
.info-comment .paging {
  margin: 30px 0 40px;
}
.info-second-title {
  font-size: 18px;
  color: #333333;
  position: relative;
  padding-left: 12px;
}
.info-second-title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 18px;
  background-color: #096dd9;
  transform: translateY(-50%);
}
.comment-write {
  margin-top: 10px;
}
.comment-write .ant-form-item-control {
  text-align: right;
}
/*资讯详情end*/
/*圈子详情*/
.describe-three .ant-typography {
  height: 85px;
  line-height: 28px;
}
.paging-circle {
  margin: 35px 0;
}
.paging-circle .ant-pagination {
  text-align: center;
}
.circle-details-infor .img {
  display: block;
  width: 100%;
}
.circle-details-infor .icon-box {
  margin-bottom: 5px;
}
.circle-details-infor .icon-box em {
  display: inline-block;
  margin: 0 10px;
  color: #ddd;
}
.circle-details-infor .title {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0;
}
.circle-details-infor .row {
  margin-bottom: 5px;
}
.circle-details-infor .ant-btn {
  margin: 15px 0;
}
.circle-member-card .ant-card-head-title {
  font-size: 18px;
}
.circle-member-card .ant-list-item {
  border-bottom: 1px dashed #e5e5e5 !important;
}
.circle-member-card .ant-list-item:last-child {
  border-bottom: none !important;
}
.circle-member-card .ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0;
}
.circle-post-content .ant-list-item-action {
  padding-left: 48px;
}
.circle-post-content .ant-list-item-meta-description {
  color: #666;
}
.circle-post-col {
  margin-top: 10px;
}
.circle-post-col .ant-col-23 {
  padding-left: 14px;
}
.circle-carousel {
  height: 220px;
}
.circle-carousel .slick-list {
  height: 100%;
}
.circle-carousel .slick-slide img {
  height: 100%;
  object-fit: cover;
}
.circle-modal .ant-modal-close-x {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
.toolip-circle.ant-tooltip {
  width: auto;
  max-width: 600px;
}
/*圈子详情end*/
/*政策*/
.contact-form .ant-form-item {
  margin-bottom: 0;
}
.contact-form textarea.ant-input {
  margin-top: 3px;
}
.policy-tips {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 20px;
}
.policy-phone {
  text-align: center;
  color: #096dd9;
  font-size: 16px;
}
.policy-phone .icon {
  margin-right: 5px;
}
.info-tag span {
  margin: 0 10px 10px 0;
}
.info-article-item {
  margin-bottom: 20px;
}
.info-article-item:last-child {
  margin-bottom: 0;
}
/*政策end*/
/*会议室详情*/
.details-name div.ant-typography {
  margin-bottom: 10px;
}
.ant-list.details-intro .ant-list-item {
  padding: 1px 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.type-name {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  margin-right: 10px;
  width: 80px;
}
.details-content-title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}
.details-content-write {
  padding-left: 16px;
  font-size: 14px;
  color: #666666;
}
.details-content-write p {
  margin-bottom: 0;
}
.small-price {
  color: #cf2525;
  font-size: 16px;
}
.my-order-litstyle {
  display: flex;
  justify-content: space-between;
}
.my-order-litstyle .my-order-status {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.my-order-title {
  font-family: "Arial Normal", "Arial";
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: #d30f1b;
  text-align: right;
}
.btn-webstyle {
  text-align: right;
}
.btn-webstyle button {
  margin: 0 5px;
}
.coupons-box {
  width: 75px;
  height: 75px;
  position: relative;
  overflow: hidden;
  color: #ffffff;
  background-image: linear-gradient(138deg, #f7a72c 0%, #f88939 60%, #f88000 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
}
.coupons-mylist .ant-card-body .ant-list-item {
  padding: 16px 0;
}
.coupons {
  border: solid 1px #ff8300 !important;
}
.coupons-get {
  border: solid 1px #ff8300 !important;
}
.coupons-downlist.ant-collapse-borderless,
.coupons-downlist.ant-collapse {
  background-color: initial;
}
.coupons-downlist .ant-collapse-item {
  border-bottom: none !important;
}
.coupons-downlist .ant-collapse-header {
  padding: 0 !important;
}
.coupons-downlist .ant-collapse-arrow {
  position: absolute !important;
  top: 10px !important;
  left: 135px !important;
  font-size: 16px !important;
  color: #096dd9 !important;
}
.coupons-downlist .ant-collapse-content-box {
  padding: 0 !important;
}
div .btn-Jaune {
  background-color: #ff9900;
  float: right;
  border-radius: 17px;
  height: 26px;
  padding: 0 16px;
  line-height: 24px;
  border: #f37d00 solid 1px;
  cursor: pointer;
  margin-right: 0;
}
.conpountitle-num {
  display: flex;
  justify-content: space-between;
}
.coupons-mylist .ant-list-item {
  display: block !important;
}
.coupons-mylist .ant-list-item button:hover {
  background-color: unset;
  color: unset;
}
.coupons-mylist .mycoupon-btn-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.coupons-mylist .mycoupon-btn-style .coupon-touse {
  background-color: #f89235;
  border-color: #f89235;
  margin-left: 5px;
}
.coupons-mylist .mycoupon-btn-style .coupon-touse:hover {
  background-color: #ed790f !important;
  border-color: #ed790f !important;
  margin-left: 5px;
}
.coupons-mylist .coupons-box-end,
.coupons-mylist .coupon-touse-end {
  background: #eee !important;
  border-color: #eee !important;
}
.coupons-mylist .coupons-end {
  border: solid 1px #eee !important;
}
/*会议室详情end*/
/*入驻申请成功*/
.success-content {
  margin: 50px 0;
  text-align: center;
}
.success-content .success-icon .icon {
  color: #096dd9;
  font-size: 60px;
}
.success-content .success-name {
  font-size: 20px;
  margin-top: 10px;
}
.success-content .success-tips {
  margin-top: 10px;
}
.success-content .success-btn {
  margin-top: 20px;
}
/*入驻申请成功end*/
/*园区印象*/
.login-form-button {
  margin-top: 5px;
}
.impression-info {
  line-height: 26px;
  padding: 0 16px;
}
.impression-info .type-name {
  width: auto;
}
.impression-info .ant-col-12 {
  margin-bottom: 10px;
}
.check-map {
  font-style: normal;
  color: #096dd9;
  margin-left: 10px;
  cursor: pointer;
}
.check-map i {
  font-style: normal;
}
.impression-map {
  max-height: 400px;
}
.impression-map .ant-list-item {
  padding: 0;
}
/*园区印象end*/
/*广告位预约*/
.reserve-tag-content {
  margin-top: -5px;
  margin-bottom: 10px;
}
.reserve-tag-content .ant-tag {
  margin: 4px 8px 4px 0;
}
.reserve-tag-content .ant-tag-checkable {
  background-color: #ffffff;
  line-height: 30px;
}
.reserve-tag-content .ant-tag-checkable-checked {
  background-color: #096dd9;
}
.reserve-tag-content .ant-tag.active {
  background-color: #cf2525;
}
.state-tag-tips span {
  position: relative;
  padding-left: 20px;
  margin-right: 20px;
}
.state-tag-tips span:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  border: #e8e8e8 solid 1px;
}
.state-tag-tips span:nth-child(1):before {
  background-color: #ffffff;
}
.state-tag-tips span:nth-child(2):before {
  background-color: #cf2525;
}
.state-tag-tips span:nth-child(3):before {
  background-color: #096dd9;
}
.choice-tag-list {
  background-color: #f5f5f5;
  padding: 12px;
  margin-top: 5px;
}
/*广告位预约end*/
/*会议室时间段start*/
.room-details .bd {
  position: relative;
}
.room-details .bd .tit {
  display: flex;
}
.room-details .bd .tit span {
  display: inline-block;
  font-size: 14px;
  color: #ff9900;
  font-weight: bold;
}
.room-details .bd .tit span:first-child {
  flex: 1;
}
.room-details .bd .tit span:nth-of-type(2) {
  width: 80px;
  text-align: right;
}
.room-details .bd .tit span:nth-of-type(3) {
  width: 80px;
  text-align: right;
}
.room-details .bd .row {
  display: flex;
  padding: 5px 0;
}
.room-details .bd .row span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  font-size: 14px;
}
.room-details .bd .row span:first-child {
  flex: 1;
}
.room-details .bd .row span:nth-of-type(2) {
  width: 80px;
  text-align: right;
}
.room-details .bd .row span:nth-of-type(3) {
  width: 80px;
  text-align: right;
}
.room-details .bd .row span.red {
  color: #fd3301;
  font-weight: bold;
}
.room-details .bd .bt {
  position: relative;
  display: flex;
  padding: 10px 0 5px 0;
}
.room-details .bd .bt:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  color: #e5e5e5;
  top: 0;
  border-top: 1px solid #e5e5e5;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.room-details .bd .bt span {
  display: inline-block;
  font-size: 14px;
}
.room-details .bd .bt span:first-child {
  flex: 1;
}
.room-details .bd .bt span:nth-of-type(2) {
  width: 80px;
  text-align: right;
}
.room-details .bd .bt span:nth-of-type(3) {
  width: 80px;
  text-align: right;
  font-weight: bold;
  color: #333;
}
.select-time {
  position: relative;
  background-color: #ffffff;
}
.select-time .tit {
  display: flex;
  justify-content: center;
  padding: 10px 15px 5px 15px;
}
.select-time .tit span {
  display: inline-block;
  width: 60px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  color: #666;
  border-radius: 3px;
  transform: scale(0.8);
  margin-left: -5px;
}
.select-time .tit span:after {
  border-radius: 3px;
}
.select-time .tit span.off {
  background-color: #f4f4f4;
  color: #999999;
}
.select-time .tit span.actived {
  background-color: rgba(9, 109, 217, 0.36);
  color: #ffffff;
}
.select-time .tit span.on {
  background-color: #096dd9;
  color: #ffffff;
}
.select-time .getout-time {
  padding: 5px 15px 10px 15px;
  font-size: 12px;
  color: #ff9a1b;
}
.select-time .getout-time i {
  position: relative;
  vertical-align: middle;
  font-size: 14px;
  top: -1px;
}
.select-time .bd {
  position: relative;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin: 0 15px;
  padding: 5px 0;
}
.select-time .fd {
  display: flex;
  padding: 15px;
  justify-content: space-around;
  align-items: center;
}
.select-time .fd .timetit {
  font-size: 14px;
  flex: 1;
  text-align: center;
}
.select-time .fd .prev,
.select-time .fd .next {
  position: relative;
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #ff9a1b;
}
.select-time .fd .prev::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 2px;
  background-color: #fff;
  border-radius: 1px;
}
.select-time .fd .next::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 2px;
  background-color: #ffffff;
  border-radius: 1px;
}
.select-time .fd .next::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 1px;
}
.time-list {
  position: relative;
  white-space: nowrap;
}
.time-list:before {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-left: 1px solid #e5e5e5;
  color: #e5e5e5;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
}
.time-list li {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 100px;
}
.time-list li:before {
  content: " ";
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  color: #e5e5e5;
  bottom: 0;
  border-bottom: 1px solid #e5e5e5;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-transform: scaleY(0.5);
  transform: scaleY(0.5);
}
.time-list li:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-right: 1px solid #e5e5e5;
  color: #e5e5e5;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
}
.time-list li:nth-of-type(2n + 1):after {
  top: 35px;
}
.time-list li:last-child:after {
  content: " ";
  position: absolute;
  right: 0;
  top: 0;
  width: 1px;
  bottom: 0;
  border-right: 1px solid #e5e5e5;
  color: #e5e5e5;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
}
.time-list li .time {
  display: block;
  font-size: 10px;
  color: #000;
  text-align: center;
  margin-top: 5px;
}
.time-list li .main {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 35px;
}
.time-list li.off .main {
  background-color: #f4f4f4;
}
.time-list li.hide {
  display: none;
}
.time-list li.actived .main {
  background-color: rgba(9, 109, 217, 0.36);
}
.time-list li.on .main {
  background-color: #096dd9;
}
.time-list li:nth-of-type(2n) .time {
  color: #fff;
}
/*会议室end*/
.my-upload-box .my-upload-button {
  width: 180px;
  height: 180px;
  overflow: hidden;
  display: inline-block;
  position: relative;
  margin: 5px 0;
  text-align: center;
  border: 1px solid #bdbdbd;
  border-radius: 50%;
}
.my-upload-box .my-upload-button .my-upload-img-opacity {
  opacity: 0;
  z-index: 10;
}
.my-upload-box .my-upload-button input {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.my-upload-box .my-upload-button .images-auto {
  border-radius: 50%;
}
.bdshare_popup_bottom {
  display: none;
}
.share-badge .bdsharebuttonbox .bds_more {
  float: none;
  padding: 0;
  background: none;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  margin-bottom: 0;
}
.park-details * {
  max-width: 100% !important;
}
.attach .upload {
  padding-left: 15px;
  height: 44px;
}
.attach .upload > div {
  width: 100%;
}
.attach .upload > div {
  width: 100%;
}
.attach .upload .webuploader-pick span {
  display: flex;
  align-items: center;
}
.attach .upload .webuploader-pick i {
  margin-right: 5px;
  margin-top: 0;
}
.upload-box,
.webuploader-container {
  display: flex;
  align-items: center;
}
.upload-box i,
.webuploader-container i {
  margin-right: 3px;
  line-height: 1;
  margin-top: 2px;
}
.file-input,
.webuploader-element-invisible {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.type-box {
  position: relative;
}
.type-box span {
  position: absolute;
  font-size: 12px;
  bottom: 0;
  left: 5px;
  line-height: 1.2;
  -webkit-transform: scale(0.5);
  transform: scale(0.6);
  background-color: #ffffff;
  max-width: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.type-box span i {
  display: block;
  position: relative;
  top: 5px;
}
.no-notice {
  background-color: transparent;
  color: #333333;
}
.readonly .ant-upload.ant-upload-select-picture-card {
  display: none;
}
.readonly .ant-upload.ant-upload-disabled {
  display: none;
}
/*我的发票*/
.button-title {
  line-height: 32px;
}
/*我的发票end*/
.use-coupon-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}
.choseNo .coupons {
  border: solid 1px #f5f5f5 !important;
}
.choseNo .anticon-check {
  display: none;
}
.choseActive .coupons {
  border: solid 1px #ff9a1b !important;
}
.choseActive .anticon-check {
  display: inline-block;
}
.invioce-vire-shoice .ant-form-item {
  margin-bottom: 2px !important;
}
.invioce-vire-shoice .ant-select-lg .ant-select-selection--single {
  height: 31px !important;
}
.invioce-vire-shoice .ant-select-lg .ant-select-selection__rendered {
  line-height: 30px !important;
  font-size: 13px !important;
}
.invioce-vire-shoice .ant-form-item-children .ant-input[disabled] {
  height: 30px;
  padding: 0px 11px;
  font-size: 14px;
  line-height: 30px;
}
.position-timepicker .ant-calendar-picker {
  margin-right: 5px;
}
.ant-card.index-activity-card {
  transition: all 0.4s;
  cursor: pointer;
}
.ant-card.index-activity-card h4 {
  height: 56px;
}
.ant-card.index-activity-card:hover h4 {
  color: #096dd9;
}
/*我的-最新消息*/
.info-min {
  min-height: 454px;
}
.my-article {
  position: relative;
  overflow: hidden;
  padding: 15px;
  border: 1px solid #e5e5e5;
}
.my-article .ant-page-header {
  padding: 16px;
}
.my-article a {
  color: rgba(0, 0, 0, 0.85);
}
.my-article a:hover {
  color: #096dd9;
}
/*我的-最新消息end*/
/* 级联选择 */
.tree-list {
  margin-bottom: -1px;
}
.tree-list .tree-node .tree-node {
  position: relative;
}
div.sum-box {
  float: left;
  display: block;
  position: relative;
  width: 20%;
  overflow: hidden;
  padding-right: 5%;
  text-align: right;
}
.tree-box {
  display: block;
  position: relative;
  overflow: hidden;
}
.tree-node-title.active {
  color: #488be9;
}
.tree-node-title .tree-node-arrow {
  display: inline-block;
  width: 1.6rem;
  text-align: center;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.tree-node-title .tree-node-link {
  float: right;
  color: rgba(136, 136, 136, 0.45);
  font-size: 1.6rem;
}
.tree-node.active > .tree-box > .am-flexbox.am-flexbox-align-center > .tree-node-title > .am-flexbox.am-flexbox-align-center > .tree-node-arrow {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.tree-node > .tree-node {
  display: none;
}
.tree-node.active > .tree-node {
  display: block;
}
.dropdown-content .tree-list {
  margin-bottom: 0;
  border-top: 1px solid #ddd;
}
div.tree-node-title {
  display: block;
  padding: 0.6rem 0.6rem 0.6rem 0;
  text-align: left;
  color: #888;
}
div.tree-node-title .number {
  position: absolute;
  top: 50%;
  right: 5%;
  margin-top: -0.8rem;
  min-width: 10px;
  padding: 0 12px;
  color: #666;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 0.9rem;
  margin-right: 0;
}
a.tree-node-title {
  display: block;
  padding: 0.6rem 0.6rem 0.6rem 1.9rem;
  text-align: left;
  color: #888;
}
a.tree-node-title .number {
  position: absolute;
  top: 50%;
  right: 5%;
  margin-top: -0.8rem;
  min-width: 10px;
  padding: 0 12px;
  color: #666;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  font-size: 0.9rem;
  margin-right: 0;
}
.number {
  display: inline-block;
  min-width: 10px;
  padding: 0 12px;
  width: 30px;
  color: #666;
  white-space: nowrap;
  vertical-align: baseline;
  margin-top: 0.8rem;
  margin-right: 0;
  font-size: 0.9rem;
  text-align: center;
}
.tree-node.active > .tree-node {
  padding-left: 1rem;
}
.tree-node.active > .tree-node.active > .tree-node:last-child {
  border-bottom: 0;
}
.tree-model .showed {
  height: calc(100vh - 203px) !important;
}
/* END 级联选择 */
/* 评价tag */
.apply-modal-tag {
  margin: 8px 0 10px 0;
  max-height: 138px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.apply-modal-tag .tag-item {
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: 8px;
}
.apply-modal-tag .tag-item:nth-child(odd) {
  padding-right: 4px;
}
.apply-modal-tag .tag-item:nth-child(even) {
  padding-left: 4px;
}
.apply-modal-tag .tag-item.active span {
  background-color: #096dd9;
  color: #ffffff;
}
.apply-modal-tag span {
  background-color: #f2f2f2;
  color: rgba(0, 0, 0, 0.45);
  padding: 0 6px;
  border-radius: 5px;
  font-size: 14px;
  height: 38px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}
/*END 评价tag */
/* 显示隐藏搜索框 */
.select-hide {
  text-align: center;
  width: 77px;
  height: 40px;
  margin: 0 auto;
}
.select-hide .select-hide-box {
  width: 77px;
  height: 40px;
  line-height: 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 0 0 4px 4px;
  cursor: pointer;
  border-top: 0;
  margin-top: -1px;
  position: relative;
  z-index: 1;
}
.select-hide .select-hide-box span {
  color: rgba(0, 0, 0, 0.45);
}
.select-hide .select-hide-box span i {
  transform: scale(0.7);
  color: rgba(0, 0, 0, 0.45);
}
/* END 显示隐藏搜索框 */
/* 排序用a标签 */
.sortBox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.6;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
}
.sortBox .active {
  color: #fff !important;
  border: 1px solid #096dd9 !important;
  background-color: #096dd9 !important;
}
.sortBox .first {
  border: 1px solid #d9d9d9;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sortBox .last {
  border: 1px solid #d9d9d9;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sortBox .other {
  border: 1px solid #d9d9d9;
  border-left: 0;
}
.sortBox .sortButton {
  position: relative;
  display: inline-block;
  height: 40px;
  margin: 0;
  padding: 0 15px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 40px;
  background: #fff;
  border-top-width: 1.02px;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s;
}
/* END 排序用a标签 */
/* 分类用a标签 */
.classify {
  color: rgba(0, 0, 0, 0.85);
  height: 30px;
  line-height: 28px;
  margin: 10px 20px 10px 0;
  cursor: pointer;
  padding: 0 16px;
  font-size: 16px;
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  background-color: transparent;
}
.classify-checked {
  color: #fff;
  background-color: #096dd9 !important;
  height: 30px;
  line-height: 28px;
  font-size: 16px;
  border-radius: 4px;
  padding: 0 16px;
}
.classify-checked:hover {
  background-color: #4994fe !important;
  color: #fff;
}
/* END 分类用a标签 */
/* 园区切换a标签 */
.park-select-box {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  display: inline-block;
  line-height: unset;
  width: 100%;
}
.park-select-box .park-radio-box {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.6;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
  width: calc(50% - 8px);
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.park-select-box .park-radio-box :hover .park-radio-inner {
  border-color: #2289cb !important;
}
.park-select-box .park-radio-box .park-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.6;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: sub;
  outline: none;
  cursor: pointer;
}
.park-select-box .park-radio-box .park-radio .park-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.park-radio-checked .park-radio-inner {
  border-color: #2289cb;
}
.park-radio-checked .park-radio-inner:after {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.park-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #2289cb;
  border-radius: 50%;
  visibility: hidden;
  -webkit-animation: antRadioEffect 0.36s ease-in-out;
  animation: antRadioEffect 0.36s ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  content: "";
}
.park-radio-text {
  padding-right: 8px;
  padding-left: 8px;
}
.park-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  transition: all 0.3s;
}
.park-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #2289cb;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: " ";
}
/* END 园区切换a标签 */
.bd_weixin_popup {
  height: 300px !important;
}
.center-steps.ant-steps {
  padding: 50px 70px 20px;
}
.center-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  width: 160px;
  position: relative;
}
.center-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  margin-left: 60px;
  border-width: 2px;
  line-height: 42px;
  height: 42px;
  width: 42px;
}
.center-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-icon .ant-steps-icon {
  font-size: 20px;
  font-weight: bold;
  font-family: Arial;
}
.center-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-icon .ant-steps-icon .icon {
  font-size: 42px;
  line-height: 1;
  font-weight: bold;
}
.center-steps.ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 80px;
  top: 17px;
  padding: 3.5px 32px;
}
.center-steps.ant-steps .ant-btn {
  height: 24px;
  line-height: 22px;
  border-radius: 12px;
  padding: 0 27px;
}
.center-steps.ant-steps .ant-steps-item-title {
  font-size: 18px;
  color: #000 !important;
  position: absolute;
  width: 100%;
  left: 0;
  top: -95px;
}
.center-steps.ant-steps .ant-steps-item-tail::after {
  height: 2px;
}
.center-steps.ant-steps .ant-steps-item-description {
  margin-top: 12px;
}
.report-form {
  margin: 20px 0;
}
/*表格*/
.report-form {
  margin: 30px 0;
}
.report-form .ant-checkbox-wrapper {
  font-size: 16px;
}
.report-form .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0 15px 0 0;
}
.report-form input[type="text"],
.report-form input[type="number"] {
  border: 1px solid #d9d9d9;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  padding: 0 10px;
  width: 100%;
  box-sizing: border-box;
  outline: none;
}
.report-form input[type="text"].inline,
.report-form input[type="number"].inline {
  display: inline-block;
  width: auto;
  min-width: 200px;
  margin-right: 10px;
}
.report-form input[type="text"].small,
.report-form input[type="number"].small {
  display: inline-block;
  width: 60px;
  margin: 0 10px;
  border: none;
  border-bottom: #d9d9d9 solid 1px;
  border-radius: 0;
}
.report-form .chapter {
  height: 150px;
  margin-top: 20px;
}
.report-form .table-division {
  color: #096dd9;
}
.report-form-wd {
  padding: 50px;
  box-shadow: 0 0 15px #eee;
}
.report-form-title {
  text-align: center;
  font-size: 24px;
  color: #096dd9;
  line-height: 1.4;
}
.report-form-title p {
  margin: 0;
}
.report-form-type {
  font-size: 16px;
  margin: 20px 0 20px 0;
}
.table-list {
  width: 100%;
  border: #e6e6e6 solid 1px;
  font-size: 16px;
}
.table-list td {
  padding: 10px 15px;
  min-height: 20px;
  border: #e6e6e6 solid 1px;
}
.table-list td.bg {
  color: #000;
  width: 200px;
}
.table-list .table-title {
  font-size: 18px;
  color: #000;
  background-color: #f5f5f5;
}
/*表格end*/
/*自定义上传表格*/
.report-form .personlist {
  font-size: 16px;
}
.report-form .personlist .bt_1307_regTitle {
  margin: 25px 0 15px;
}
.report-form .personlist .title {
  font-size: 20px;
  color: #333333;
  position: relative;
  padding-left: 20px;
  display: inline-block;
}
.report-form .personlist .title:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 6px;
  height: 18px;
  background-color: #096dd9;
  border-radius: 4px;
}
.report-form .personlist td {
  padding: 10px;
  border: #e6e6e6 solid 1px;
}
.report-form .ant-row-flex-middle button {
  margin-left: 10px;
}
.report-form-detail {
  padding: 0 40px;
}
.report-form-detail .personlist {
  font-size: 14px;
}
.report-form-detail .personlist .title {
  font-size: 17px;
  padding-left: 0;
}
.report-form-detail .personlist .title::before {
  display: none;
}
.report-form-detail {
  padding: 0 40px;
}
.report-form-detail .personlist {
  font-size: 14px;
}
.report-form-detail .personlist .title {
  font-size: 17px;
  padding-left: 0;
}
.report-form-detail .personlist .title::before {
  display: none;
}
.personlist .ant-col > span {
  color: rgba(0, 0, 0, 0.85);
}
.personlist .ant-col > span:first-child {
  color: rgba(0, 0, 0, 0.45);
}
/*自定义上传表格end*/
.my-page .ant-page-header-heading-title {
  font-weight: unset;
}
.my-page .ant-page-header-ghost {
  background-color: #fff;
  border: #e5e5e5 solid 1px;
  border-radius: 2px;
  padding: 16px;
}
.my-page .ant-page-header-ghost .ant-page-header-heading-extra {
  margin-top: 6px;
}
.my-page .ant-page-header-ghost .ant-page-header-heading-sub-title {
  font-weight: unset;
}
.my-page .ant-page-header-ghost .ant-page-header-heading-sub-title a span {
  color: rgba(0, 0, 0, 0.65);
}
.my-page .ant-page-header-ghost .ant-page-header-heading-sub-title a span:hover {
  color: #096dd9;
}
.my-home-title {
  font-size: 18px;
}
.spacemap {
  z-index: 10 !important;
  margin: 0 10px;
}
.flowstate_select .ant-select-selection .ant-select-selection__rendered .ant-select-selection-selected-value,
.flowstate_select .ant-select-selection .ant-select-selection__rendered .ant-select-selection__placeholder {
  font-size: 16px;
}
.extra-button {
  width: 80px;
}
/* 圈子 */
.circle-comment.pinglun-box .ant-comment-actions > li:last-child {
  position: absolute;
  right: 0;
  top: 0;
}
.circle-comment.pinglun-box .ant-comment-content-detail {
  padding-right: 0;
}
.circle-comment .reply-box {
  margin-top: 10px;
  border-radius: 6px;
  padding: 0 16px;
  background-color: #f9f9f9;
}
.circle-comment .reply-box .ant-comment-content-author {
  display: none;
}
.circle-comment .reply-box .ant-comment-avatar {
  display: none;
}
.row-my-infos {
  padding: 10px 0;
  background-color: #fff;
}
.my-form .ant-form-item {
  margin-bottom: 0;
}
.my-form .ant-form-item .ant-form-item-label {
  text-align: left;
}
.my-form .ant-form-item .ant-col {
  width: 100%;
}
.my-form .ant-select-selection--single {
  height: 45px;
}
.my-form .ant-select-selection__rendered {
  line-height: 43px;
}
.my-form .ant-input {
  height: 45px;
}
.my-form textarea.ant-input {
  height: auto;
}
.my-form .ant-tag {
  background: #f2f2f2;
  color: #555555;
  border: none;
  border-radius: 0;
  padding: 3px 20px;
  margin-right: 15px;
  font-size: 14px;
  letter-spacing: 2px;
  cursor: pointer;
}
.my-form .ant-tag.active {
  color: #05b8ce !important;
  background-color: #eaf8fa !important;
}
.form-space {
  position: relative;
  overflow: hidden;
  padding: 30px 0;
}
.form-space::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 0;
  border-top: 1px dashed #ddd;
}
.extra-font {
  font-size: 16px;
  font-weight: normal !important;
  color: #bdbdbd !important;
}
.circle-dynamic-card .ant-card-body {
  padding: 16px;
}
.circle-dynamic-card .ant-card-body .ant-list-item {
  padding: 0;
}
/* 圈子 END */
/* 状态机 */
.drawer-detail {
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
}
.flowstate_table {
  display: block !important;
}
.approval-modal-form {
  width: 700px !important;
}
.service-detail-card-title {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.6;
  list-style: none;
  font-feature-settings: "tnum";
}
/* 状态机 END */
/* 研究者需求 */
.expert-list-item .ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.expert-list-item .ant-card-body .expert-name {
  height: 22px;
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.85);
  margin: 8px 0 10px 0;
}
.expert-list-item .ant-card-body .expert-tags {
  margin-top: 6px;
  padding-left: 8px;
  width: 100%;
  display: flex;
}
.expert-list-item .ant-card-body .expert-desc {
  width: 100%;
  max-height: 42px;
  padding-left: 4px;
  font-size: 13px;
  margin: 4px 0;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expert-list-item .ant-card-body .expert-company {
  width: 100%;
  padding-left: 4px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}
.expert-list-item .ant-card-body .ant-ribbon-wrapper {
  width: 100%;
  text-align: center;
}
.expert-details {
  padding: 28px !important;
  position: relative;
  margin-bottom: 18px !important;
}
.expert-details .expert-main {
  height: 200px;
  width: 400px;
  margin-left: 18px;
}
.expert-details .expert-main .expert-name {
  height: 22px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  margin: 8px 0 10px 0;
}
.expert-details .expert-main .expert-tags {
  margin: 36px 0;
  width: 100%;
  display: flex;
}
.expert-details .expert-main .expert-desc {
  width: 100%;
  max-height: 42px;
  font-size: 13px;
  margin: 4px 0;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
}
.expert-details .expert-main .expert-company {
  width: 100%;
  padding-left: 4px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}
.expert-details .expert-button {
  position: absolute;
  right: 68px;
  bottom: 36px;
}
/* 研究者需求 END */
/* 企业需求 START */
.demand-container {
  position: relative;
}
.demand-container .demand-list-button {
  position: absolute;
  right: 180px;
  top: 180px;
  z-index: 999;
}
.demand-list {
  margin: 20px 0 !important;
}
.demand-list .demand-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.demand-list .demand-top .demand-tags {
  margin: 10px 0;
}
.demand-list .demand-title {
  font-size: 18px;
}
.demand-list .demand-footer {
  display: flex;
  justify-content: space-around;
}
.demand-list .demand-footer .demand-button {
  margin-right: 22px;
}
.demand-list .demand-footer .ant-col-21 {
  padding-left: 0 !important;
}
.demand-list .demand-footer .ant-col {
  padding: 0 !important;
}
.demand-details {
  padding-left: 20px !important;
  margin-bottom: 12px !important;
}
.demand-details .demand-tags,
.demand-details .demand-during {
  margin: 12px 0;
}
/* 企业需求 END */
.market-container .list-box {
  height: 322px !important;
}
.list-box .img .way {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  color: #fff;
}
.list-box .img .way.green {
  background-color: #5ac18c;
}
.lable-text {
  display: inline-block;
  margin: 4px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px;
  height: 30px;
  line-height: 28px;
  font-style: normal;
}
.lable-text.type1 {
  border-color: #4476d8;
  color: #4476d8;
}
.my-demand-table .ant-table-body {
  font-size: 12px !important;
}
.my-demand-table .ant-table-body a {
  color: #2289cb !important;
}
.flex {
  display: flex;
  align-items: center;
}
.flex .flex-item {
  flex: 1;
}
.my-upload-box .my-upload-button .ant-avatar {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}
.my-upload-box .my-upload-button .ant-upload-list-item-actions {
  display: none;
}
.my-upload-box .my-upload-button .ant-upload-list-item-info {
  display: none;
}
.my-upload-box .ant-upload-list ant-upload-list-picture-card,
.my-upload-box ant-upload-list-picture-card-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
/*筛选*/
.page-components {
  position: relative;
}
.page-components .ant-card {
  border: none;
  border-top: 1px solid #F0F0F0;
  border-bottom: 1px solid #F0F0F0;
}
.page-components .ant-card .ant-card-body {
  padding: 0;
}
.page-components .ant-input {
  height: 40px;
}
.page-components .ant-btn {
  height: 40px;
}
.page-components .page-components-rows-label {
  width: 130px;
}
.page-components .page-components-rows {
  min-height: 72px;
  align-items: center;
  position: relative;
  display: flex;
  border-bottom: 1px dashed #e8e8e8;
  width: 100%;
}
.page-components .page-components-rows::after {
  content: "";
  position: absolute;
  background-color: #FAFAFA;
  width: 130px;
  height: 100%;
  left: 0;
  z-index: 0;
}
.page-components .page-components-rows .page-components-rows-label {
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  text-align: center;
  width: 130px;
  line-height: 72px;
  background-color: #FAFAFA;
  z-index: 1;
  padding: 0 10px;
}
.page-components .page-components-rows .page-components-rows-label .condition-name {
  font-size: 16px;
  color: #000000;
}
.page-components .page-components-rows .page-components-rows-content {
  flex: 1 1;
  max-height: 72px;
  overflow: hidden;
  padding-left: 24px;
}
.page-components .page-components-rows.page-components-rows-last,
.page-components .page-components-rows:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
}
.page-components .page-components-rows-select {
  position: relative;
  margin-left: -8px;
  overflow: hidden;
  line-height: 32px;
  transition: all 0.3s;
  user-select: none;
  padding: 10px 0;
}
.page-components .page-components-rows-select .ant-tag {
  margin-right: 24px;
  padding: 0 8px;
  font-size: 14px;
}
.small-card .ant-card-body {
  padding: 16px 24px;
}
.select-height .ant-btn {
  position: absolute;
  top: 23px;
  right: 0px;
  height: auto;
}
/*筛选end*/
.show-content {
  height: auto !important;
  max-height: inherit !important;
}
.form-footer {
  position: relative;
  padding: 24px 0;
}
[data-theme="compact"] .ant-advanced-search-form,
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
[data-theme="compact"] .ant-advanced-search-form .ant-form-item,
.ant-advanced-search-form .ant-form-item {
  display: flex;
}
[data-theme="compact"] .ant-advanced-search-form .ant-form-item-control-wrapper,
.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}
.survey-list-form .ant-form-item-control-input-content h3 {
  position: relative;
  padding-left: 8px;
}
.survey-list-form .ant-form-item-control-input-content h3 .color-red {
  position: absolute;
  left: 0;
  top: 3px;
}
/*list-box*/
.list-box .ant-card {
  border-radius: 4px;
  position: relative;
  border: 1px solid #e5e5e5;
}
.list-box .ant-card .images-auto {
  border-radius: 4px 4px 0 0;
}
.list-box .ant-card:hover .title {
  color: #096dd9 !important;
}
.list-box .ant-card:hover .list-title .ant-typography {
  color: #096dd9;
}
.list-box .ant-card-small > .ant-card-body {
  border-radius: 4px;
}
.list-box div.ant-typography {
  margin-bottom: 15px;
}
.list-box div.ant-typography:last-child {
  margin-bottom: 0;
}
.list-box .ant-list-item {
  padding: 8px 0;
}
.list-box .list-title .ant-typography {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 20px !important;
}
.list-box .list-title .ant-typography a {
  color: #333333;
}
.list-box .list-title .ant-typography a:hover {
  color: #096dd9;
}
.list-box .list-more {
  margin-top: 10px;
}
.price-img {
  position: relative;
}
.price-img i {
  background-color: #2289cb;
  color: #ffffff;
  line-height: 1;
  padding: 8px 10px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  font-style: normal;
}
.index-price-img {
  position: relative;
}
.index-price-img i {
  background-color: #096dd9;
  color: #ffffff;
  line-height: 1;
  width: 64px;
  height: 64px;
  text-align: center;
  line-height: 64px;
  font-size: 16px;
  position: absolute;
  left: -8px;
  top: -8px;
  z-index: 1;
  font-style: normal;
}
.index-price-img i.type1 {
  background-color: #ff9a1b;
}
.index-price-img i.type2 {
  background-color: #2289cb;
}
.index-price-img i.type3 {
  background-color: #c9c9c9;
}
.price-img-mini i {
  font-size: 12px;
  line-height: 1;
  padding: 5px;
}
/*list-box end*/
/*list-box1*/
.list-box1 .ant-card {
  border-radius: 4px;
  position: relative;
  border: 1px solid #e5e5e5;
  width: 100%;
}
.list-box1 .ant-card .images-auto {
  border-radius: 4px 4px 0 0;
}
.list-box1 .ant-card:hover .title {
  color: #096dd9 !important;
}
.list-box1 .ant-card-body {
  border-radius: 4px;
}
.list-box1 .word {
  padding: 10px 15px;
}
.list-box1 .title {
  font-size: 18px;
  color: #000000;
}
.list-box1 .icon {
  margin-right: 4px;
}
.list-box1 .ant-card-body {
  background-color: #ffffff;
}
.list-box1 div.ant-typography {
  margin-bottom: 0;
}
.list-box1 .ant-list-item {
  padding: 8px 0;
}
.list-box1 .primary {
  color: #096dd9;
}
/*list-box1 end*/
/*list-horizontal*/
.ant-list.list-horizontal {
  background-color: #ffffff;
  border-radius: 2px;
}
.ant-list.list-horizontal .ant-list-item {
  padding: 15px 20px;
}
.ant-list.list-horizontal .ant-list-item-meta-content {
  max-width: 100%;
}
.ant-list.list-horizontal .ant-list-item-meta {
  margin-bottom: 0;
}
.ant-list.list-horizontal .ant-list-item-meta-title a {
  color: #333333;
}
.ant-list.list-horizontal .ant-list-item-meta-title a:hover {
  color: #096dd9;
}
.ant-list.list-horizontal .ant-list-item-action {
  margin-top: 10px;
}
.ant-list.list-horizontal div.ant-typography,
.ant-list.list-horizontal .ant-typography p {
  margin-bottom: 0;
}
.info-icon {
  cursor: default;
}
.info-icon .icon {
  margin-right: 5px;
  font-size: 14px;
}
/*list-horizontal end*/
/*list-basic*/
.ant-list.list-basic .ant-list-header {
  padding: 0;
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 5px;
  border-bottom: none;
}
.ant-list.list-basic .ant-list-item {
  border-bottom: none;
  padding: 5px 0;
}
.ant-list.list-basic div.ant-typography,
.ant-list.list-basic .ant-typography p {
  margin-bottom: 0;
}
.ant-list.list-basic .ant-list-item-meta,
.ant-list.list-basic .ant-list-item-meta-content {
  max-width: 100%;
}
.ant-list.list-basic .ant-list-item-meta-title a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-list.list-basic .ant-list-item-meta-title a:hover {
  color: #096dd9;
}
.ant-list.list-basic .ant-list-item-meta-title i {
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #f0f0f0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  font-style: normal;
  margin-right: 13px;
}
.ant-list.list-basic .ant-list-item-meta-title i.bg-color1 {
  background-color: #096dd9;
  color: #ffffff;
}
/*list-basic end*/
/*list-commonly*/
.list-commonly .ant-list-item {
  background-color: #fff;
}
.list-commonly .ant-list-header {
  background-color: #fff;
}
.list-commonly .ant-comment:last-child {
  border-bottom: none;
}
.hover-link .ant-typography {
  color: #333333 !important;
}
.hover-link .ant-typography:hover {
  color: #096dd9 !important;
}
.hot-title {
  font-size: 18px;
  line-height: 30px;
}
/*list-commonly end*/
/*list-loose*/
.list-loose {
  position: relative;
}
.list-loose .ant-typography {
  margin-bottom: 0;
}
.list-loose p {
  margin-bottom: 0;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 115px;
  padding: 2.5px 0;
  text-align: center;
}
/*list-loose end*/
/*list-loose2*/
.list-loose2 .ant-typography {
  margin-bottom: 0;
}
/*list-loose2 end*/
/*list-loose3*/
.list-loose3 .ant-list-item-meta-title .ant-typography {
  font-size: 18px;
}
.list-loose3 div.ant-typography,
.list-loose3 .ant-typography p {
  margin-bottom: 0.3rem;
}
/*list-loose3 end*/
/*list-loose4*/
.list-reminder .ant-list-item-meta {
  margin-bottom: 0 !important;
}
.list-reminder .ant-list-item-meta i {
  color: #ff9a06;
}
.list-reminder .ant-list-item-action {
  margin-top: 0 !important;
}
/*list-loose4 end*/
/*list-compact*/
.list-compact div .ant-list-item-meta {
  margin-bottom: 0;
}
.list-compact div .ant-list-item-action {
  margin-top: 5px;
}
.list-compact div .ant-list-item-action li {
  cursor: auto;
}
.list-compact .ant-list-item-meta-content {
  width: 100%;
}
.list-compact .ant-list-item-meta-content h4 {
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%;
  text-overflow: ellipsis;
}
.list-compact div.ant-typography,
.list-compact .ant-typography p {
  margin-bottom: 0;
}
/*list-compact*/
/*list-title-down*/
.list-title-down .ant-list-item-meta-title {
  margin-bottom: 0;
}
.list-title-down h4 .list-down-title {
  width: 100%;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0;
}
.list-title-down h4 .list-down-title a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 360px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  float: left;
}
.list-title-down h4 .list-down-title a:hover {
  color: #096dd9;
}
.list-title-down h4 .list-down-title i {
  font-size: 12px;
  color: #9ba5ff;
  float: right;
  margin-top: 5px;
}
/*list-title-down end*/
/*list-commonly-small*/
.list-commonly-small {
  background-color: #fff;
}
.list-commonly-small .title {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.45);
}
.list-commonly-small .title:hover {
  color: #096dd9;
}
.list-commonly-small.ant-list-vertical .ant-list-item-action {
  margin-top: 10px;
}
/*list-commonly-small end*/
/*list-table*/
.list-table .ant-table {
  background-color: #ffffff;
  border: #e8e8e8 solid 1px;
}
.list-table .ant-table-thead > tr > th {
  text-align: center;
}
.list-table .ant-table-tbody > tr:last-child > td {
  border-bottom: none;
}
.list-table .ant-table-pagination.ant-pagination {
  text-align: center;
  float: none;
  margin: 50px 0 80px;
}
.list-table .name {
  color: #333333;
}
.list-table .name:hover {
  color: #096dd9;
}
/*list-table end*/
/*list-details*/
.list-details .ant-col {
  padding: 10px 0;
}
.list-details .ant-col .doc-pdf a {
  display: block;
  padding: 5px 10px;
  background-color: #f5f5f5;
  margin-bottom: 10px;
}
/*list-details end*/
/*list-comment*/
.list-comment .ant-comment-content-author-name {
  color: #096dd9;
}
.list-comment .ant-comment-content-author-time {
  color: rgba(0, 0, 0, 0.45);
}
.list-comment .ant-list-split .ant-list-header {
  position: relative;
  margin-bottom: 30px;
}
.list-comment .ant-list-split .ant-list-header:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 2px;
  width: 50px;
  background-color: #096dd9;
  border-radius: 2px;
}
.list-comment .ant-form-item-control {
  text-align: right;
}
.list-comment .ant-btn {
  margin-left: 10px;
}
.list-comment .ant-form-item {
  margin-bottom: 12px;
}
/*list-comment end*/
.ant-modal-body .ant-form-item:last-child {
  margin-bottom: 0;
}
/*list-relative*/
.iteam-relative-list .ant-list-item {
  position: relative;
  display: block;
}
/*list-relative-end*/
/* information-list*/
.information .ant-card-body {
  padding: 20px;
}
.information-infos {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 24px;
}
.information-infos .ant-avatar {
  margin-bottom: 8px;
}
.information-infos .information-name {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.information-detail div i {
  margin-right: 10px;
}
.information-detail div span {
  display: inline-block;
  width: 190px;
  overflow: hidden;
  /*超出部分隐藏*/
  white-space: nowrap;
  /*不换行*/
  text-overflow: ellipsis;
  /*超出部分文字以...显示*/
}
.information-detail div span:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
  /*合并空白符序列，但是保留换行符。*/
}
.information-detail div em {
  font-style: normal;
  color: #c8c8c8;
}
/* information-list -end*/
/*栅格列表 grid-card*/
.grid-card .ant-card-head {
  background-color: #f2f2f2;
  color: #666666;
  font-weight: normal;
}
.grid-card .grid-card-list {
  margin: 5px 0;
  min-height: 22px;
}
.grid-card .type-name {
  width: 70px;
}
.grid-card .grid-name {
  width: 85px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  margin-right: 10px;
}
.grid-card .ant-btn {
  margin-left: 5px;
}
/*栅格列表end*/
/*tabs-policy*/
.ant-tabs.tabs-policy .ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin-right: 8px;
}
/*tabs-policy end*/
/*首页走马灯*/
.home-box {
  background-size: cover;
  position: relative;
}
.home-box .ant-carousel {
  height: 100%;
  width: 100%;
}
.home-box .ant-carousel > div {
  height: 100%;
  width: 100%;
}
.home-box .slick-slider > div {
  height: 100%;
  width: 100%;
}
.home-box .slick-list > div {
  height: 100%;
  width: 100%;
}
.home-box .slick-slide {
  height: 400px !important;
  width: 100%;
}
.home-box .slick-slide > div {
  height: 100%;
  width: 100%;
}
.home-box .slick-slide > div .home-bkg {
  height: 100%;
  width: 100%;
}
.home-box .slick-slide > div .home-bkg .home-img-box {
  float: left;
  width: 100%;
  background-position: center;
  display: table;
  height: 100%;
  background-size: cover !important;
}
.home-box .slick-slide > div .home-bkg .home-inner {
  height: 100%;
  color: #fff;
  padding-top: 40px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.4);
}
.home-box .slick-slide > div .home-bkg .home-inner .home-content {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}
.home-box .slick-slide > div .home-bkg .home-inner .home-content .home-title {
  padding: 0;
  font-size: 42px;
  line-height: 1.4em;
  text-align: left;
  color: #fff;
}
.home-box .slick-slide > div .home-bkg .home-inner .home-content .home-sub-text {
  padding: 5px;
  font-weight: 300;
  line-height: 1.8em;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  font-size: 32px;
  color: #3aa4e8;
  margin-top: 30px;
}
.home-box .home-tab {
  height: 553px;
}
.home-sider-img-box {
  position: relative;
  height: 217px;
  margin: 15px;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.home-sider-img-box:hover .home-sider-tit-content {
  bottom: 0;
}
.home-sider-img-box:hover .home-sider-img-tit {
  transform: translateY(100%);
}
.home-sider-img-tit {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 44px;
  line-height: 44px;
  padding: 0 28px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 16px;
  transition: all 0.2s;
}
.home-sider-tit-content {
  position: absolute;
  bottom: -100%;
  height: 100%;
  width: 100%;
  padding: 5%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 0.5s;
}
.home-sider-tit-content .home-sider-tit1 {
  font-size: 18px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.home-sider-tit-content .home-sider-tit-p {
  font-size: 13px;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*首页走马灯-end*/
/*首页*/
.home-navigation {
  position: absolute;
  height: 100%;
  width: 10%;
  z-index: 9;
  overflow: hidden;
}
.home-notice .ant-tag {
  width: 71px;
  height: 32px;
  font-size: 14px;
  border-radius: 4px;
  line-height: 32px;
  text-align: center;
  color: #666666;
  background: #eeeeee !important;
}
.home-content .ant-btn:hover {
  color: #fff;
  background-color: #096dd9;
  border-color: #096dd9;
}
.home-text-content {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
}
.home-text-content t-card {
  border: 1px solid #e5e5e5;
}
.home-text-content .index-price-img .images-auto {
  border-radius: 0;
}
.index-activity-card {
  border: 1px solid #e5e5e5;
}
.index-activity-card:hover .title {
  color: #096dd9 !important;
}
.index-activity-card.ant-card-small > .ant-card-body {
  padding: 21px 18px;
}
.home-arrow {
  display: block;
  height: 100%;
  width: 90%;
  transition: all 0.2s ease-out;
}
.home-img-prev {
  left: 0;
}
.home-img-prev:hover .home-arrow {
  opacity: 0.5;
  margin: 0px 0px 0px 20%;
  cursor: pointer;
}
.home-img-prev .home-arrow {
  opacity: 0;
  margin: 0px 0px 0px -20%;
  font-size: 50px;
  color: #fff;
  display: flex;
  align-items: center;
}
.home-img-next {
  right: 0;
}
.home-img-next:hover .home-arrow {
  opacity: 0.5;
  margin: 0 0 0 20%;
  cursor: pointer;
}
.home-img-next .home-arrow {
  opacity: 0;
  margin: 0 0 0 70%;
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 50px;
  justify-content: center;
}
.home-header .home-description {
  font-size: 12px;
  line-height: 2;
  color: #9b9b9b;
  text-align: center;
  margin: 13px 0 0;
}
.index-container {
  margin-top: 25px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
}
.index-container .home-item {
  background-color: #fff;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  margin: 15px;
}
.index-container .home-item .home-activity-set {
  position: relative;
  height: 217px;
  margin-bottom: 2rem;
  overflow: hidden;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.home-features-title {
  font-size: 16px;
  color: #262626;
  margin: 8px 0 -10px;
  font-weight: bold;
}
.home-new .newtime {
  font-size: 12px;
  position: relative;
  margin: 0;
  padding-left: 15px;
}
.home-new .newtime span {
  background: #cccccc;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  position: absolute;
  left: -4px;
  top: 5px;
}
.home-new .newtime label {
  font-weight: normal;
  margin: 0 2% 0 2%;
  font-size: 14px;
  display: inline-block;
}
.home-new .box-bor {
  border-left: 2px solid #cccccc;
  padding-left: 15px;
  margin-top: -6px;
  padding-top: 15px;
}
.home-new .box-bor .home-news-title {
  color: #333333;
  font-size: 18px;
}
.home-new .box-bor .home-news-text {
  color: #9e9e9e;
  font-size: 14px;
}
.home-notice {
  position: relative;
  overflow: hidden;
  height: 64px;
  line-height: 64px;
  background-color: #f9f9f9;
}
.home-notice .home-notice-swiper {
  height: 64px;
  width: 100%;
}
.home-notice .home-notice-swiper .swiper-slide {
  text-align: left;
  line-height: 64px;
  font-size: 16px;
}
.home-body {
  position: relative;
  background-color: #fff;
}
.home-content {
  position: relative;
  overflow: hidden;
  margin-top: 48px;
}
.homt-title {
  position: relative;
  overflow: hidden;
  text-align: center;
  font-size: 36px;
  color: #000000;
  line-height: 34px;
  padding: 40px 0 40px;
  margin-bottom: 40px;
}
.homt-title::after {
  position: absolute;
  content: "";
  width: 42px;
  border-radius: 2px;
  height: 4px;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #2289cb;
  bottom: 16px;
}
.home-service-list {
  position: relative;
  width: 1200px;
  height: 180px;
  display: flex;
  justify-content: space-around;
}
.home-service-row {
  position: relative;
  display: flex;
  align-items: center;
  height: 122px;
  width: 280px;
  border-radius: 4px;
  color: #333;
  margin-bottom: 30px;
  border: 1px solid #e5e5e5;
  transition: all 0.3s;
}
.home-service-row > a {
  padding: 20px 25px;
  color: #333;
  display: block;
  width: 100%;
  height: 100%;
}
.home-service-row > a:hover {
  color: #fff;
}
.home-service-row > div {
  width: 100%;
}
.home-service-row .size-20 {
  line-height: 1;
}
.home-service-row:last-child {
  margin-bottom: 0;
}
.home-service-row:hover {
  background-color: #096dd9;
  color: #fff;
}
.home-service-row .home-service-a {
  cursor: pointer !important;
  background-color: #2eaaf9;
  width: 40px;
  height: 18px;
  line-height: 12px;
  font-size: 14px;
  color: #fff;
}
.home-service-row .home-service-a:hover {
  color: #fff;
}
.home-service-title {
  position: relative;
  overflow: hidden;
  padding: 15px 20px;
}
.home-service-title a {
  height: 19px;
  width: 19px;
  text-align: center;
  display: block;
  border: solid 1px #d2d2d2;
  line-height: 11px;
}
.home-service-title a i {
  font-size: 14px;
}
.home-service-title::after {
  content: "";
  position: absolute;
  height: 16px;
  width: 4px;
  background-color: #1890ff;
  border-radius: 15px;
  left: 4px;
  top: 24px;
}
.swiper-home-politique {
  height: 420px;
}
.swiper-home-politique .swiper-slide {
  position: relative;
  width: auto;
  border-bottom: solid 1px #e8e8e8;
  transition: all 0.3s;
}
.swiper-home-politique .swiper-slide:last-child {
  border-bottom: none;
}
.swiper-home-politique .swiper-slide:hover h4 {
  color: #096dd9;
}
.swiper-home-politique .swiper-slide .ant-row {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translate(0, -50%);
}
.swiper-home-politique .swiper-slide .ant-tag {
  transition: none;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  color: #0e0e0e;
  border: navajowhite;
  width: auto !important;
  background-color: #e5e5e5;
}
.swiper-home-politique .swiper-slide .ant-tag.ant-tag-has-color {
  color: #fff;
}
.home-date-box {
  border-radius: 5px;
  background-color: #fafafa;
  width: 73px;
  height: 73px;
  text-align: center;
}
.home-date-box > div {
  height: 100%;
}
.home-date-box .day {
  color: #000;
  line-height: 1.1;
}
.home-date-box.type2 {
  background-color: #9fc9b4;
  color: rgba(255, 255, 255, 0.85);
}
.home-date-box.type2 .day {
  color: rgba(255, 255, 255, 0.85);
}
.home-park .ant-list-split .ant-list-items a:last-child .ant-list-item {
  margin-bottom: 0;
}
.home-park .ant-list-split .ant-list-item {
  padding: 12px;
  border-bottom: 0;
  cursor: pointer;
  margin-bottom: 35px;
  border-radius: 4px;
}
.home-park .ant-list-split .ant-list-item .ant-typography {
  line-height: 28px;
  height: 86px;
}
.home-park .ant-list-split .ant-list-item .ant-list-item-meta-title {
  font-size: 22px;
  line-height: 25px;
  height: 50px;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 0;
}
.home-park .ant-list-split .ant-list-item:hover .ant-list-item-meta-title {
  color: #096dd9 !important;
}
.home-dynamic-icon {
  line-height: 14px;
}
.home-dynamic-icon i {
  vertical-align: bottom;
}
.enterprises-list {
  position: relative;
  text-align: center;
  height: 245px;
}
.enterprises-list-row {
  display: inline-block;
  vertical-align: middle;
  width: 165px;
  text-align: center;
  transition: all 0.3s;
  height: 215px;
  padding-top: 12px;
}
.enterprises-list-row:hover {
  padding: 0;
}
.enterprises-list-row:hover i {
  background-color: #096dd9;
  color: #fff;
  height: 160px;
  width: 160px;
}
.enterprises-list-row:hover span {
  margin-top: 10px;
}
.enterprises-list-row a {
  display: block;
  width: 100%;
  height: 100%;
}
.enterprises-list-row i {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  background-color: #fafafa;
  border-radius: 50%;
  font-size: 40px;
  color: #333;
  transition: all 0.3s;
}
.enterprises-list-row i::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.enterprises-list-row span {
  display: block;
  line-height: 21px;
  font-size: 22px;
  margin-top: 30px;
  color: #000;
  transition: all 0.3s;
}
.enterprises-list-row span::before {
  content: "";
  position: relative;
  top: -5px;
  margin: 0 auto;
  width: 10px;
  height: 2px;
  background-color: #096dd9;
  display: none;
}
.notice-title {
  position: relative;
  height: 64px;
  line-height: 64px;
  width: 125px;
  color: #010101;
}
.notice-title::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  height: 24px;
  width: 1px;
  background-color: #8c8c8c;
  transform: translate(-50%, -50%);
}
.home-modal .ant-modal-content {
  height: 100%;
}
.home-modal .ant-modal-content .ant-modal-body {
  height: calc(100% - 108px);
  overflow: auto;
}
.home-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}
.home-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0 0 #fff inset, 1px 0 0 #fff inset, 0 -1px 0 rgba(255, 255, 255, 0.9) inset, 0 1px 0 rgba(255, 255, 255, 0.9) inset;
}
.home-modal .ant-modal-content .ant-modal-body::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0);
}
@media (max-width: 1366px) {
  .home-box {
    zoom: 0.8;
  }
}
.a-title {
  color: black;
  font-size: 18px;
}
.a-title:hover {
  color: #096dd9;
}
/*首页 -- end*/
.loading-spin-box {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
}
.loading-spin-box .ant-spin {
  color: #ffffff;
}
.loading-spin-box .ant-spin .ant-spin-text {
  margin-top: 8px;
}
.loading-spin-box .loading-close {
  position: absolute;
  right: 24px;
  top: 24px;
  font-size: 22px;
  color: #FFFFFF;
  cursor: pointer;
}
.images-auto {
  background: center no-repeat;
  background-size: cover;
  border-radius: 4px;
}
.images-auto img {
  display: none;
}
.ReactModal__Overlay {
  z-index: 999;
}
.ReactModal__Content .swiper-container {
  width: 100%;
  height: 100%;
}
.ReactModal__Content .ant-modal-close {
  color: #ffffff;
  font-size: 28px;
}
.ReactModal__Content .swiper-button-prev,
.ReactModal__Content .swiper-button-next {
  z-index: 99;
}
.ReactModal__Content .swiper-pagination-bullet-active,
.ReactModal__Content .swiper-pagination-bullet {
  background-color: #ffffff;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: null !important;
}
.padding-0 {
  padding: 0 !important;
}
.margin-0 {
  margin: 0 !important;
}
.padding-xs {
  padding: 8px !important;
}
.margin-xs {
  margin: 8px !important;
}
.padding-sm {
  padding: 16px !important;
}
.margin-sm {
  margin: 16px !important;
}
.padding {
  padding: 24px !important;
}
.margin {
  margin: 24px !important;
}
.padding-lg {
  padding: 32px !important;
}
.margin-lg {
  margin: 32px !important;
}
.padding-xl {
  padding: 40px !important;
}
.margin-xl {
  margin: 40px !important;
}
.padding-h-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.margin-h-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.padding-h-xs {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.margin-h-xs {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.padding-h-sm {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.margin-h-sm {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.padding-h {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.margin-h {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.padding-h-lg {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.margin-h-lg {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.padding-h-xl {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.margin-h-xl {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.padding-v-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.margin-v-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.padding-v-xs {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.margin-v-xs {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.padding-v-sm {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.margin-v-sm {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.padding-v {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.margin-v {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.padding-v-lg {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.margin-v-lg {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.padding-v-xl {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.margin-v-xl {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.padding-top-0 {
  padding-top: 0 !important;
}
.margin-top-0 {
  margin-top: 0 !important;
}
.padding-top-xs {
  padding-top: 8px !important;
}
.margin-top-xs {
  margin-top: 8px !important;
}
.padding-top-sm {
  padding-top: 16px !important;
}
.margin-top-sm {
  margin-top: 16px !important;
}
.padding-top {
  padding-top: 24px !important;
}
.margin-top {
  margin-top: 24px !important;
}
.padding-top-lg {
  padding-top: 32px !important;
}
.margin-top-lg {
  margin-top: 32px !important;
}
.padding-top-xl {
  padding-top: 40px !important;
}
.margin-top-xl {
  margin-top: 40px !important;
}
.padding-right-0 {
  padding-right: 0 !important;
}
.margin-right-0 {
  margin-right: 0 !important;
}
.padding-right-xs {
  padding-right: 8px !important;
}
.margin-right-xs {
  margin-right: 8px !important;
}
.padding-right-sm {
  padding-right: 16px !important;
}
.margin-right-sm {
  margin-right: 16px !important;
}
.padding-right {
  padding-right: 24px !important;
}
.margin-right {
  margin-right: 24px !important;
}
.padding-right-lg {
  padding-right: 32px !important;
}
.margin-right-lg {
  margin-right: 32px !important;
}
.padding-right-xl {
  padding-right: 40px !important;
}
.margin-right-xl {
  margin-right: 40px !important;
}
.padding-bottom-0 {
  padding-bottom: 0 !important;
}
.margin-bottom-0 {
  margin-bottom: 0 !important;
}
.padding-bottom-xs {
  padding-bottom: 8px !important;
}
.margin-bottom-xs {
  margin-bottom: 8px !important;
}
.padding-bottom-sm {
  padding-bottom: 16px !important;
}
.margin-bottom-sm {
  margin-bottom: 16px !important;
}
.padding-bottom {
  padding-bottom: 24px !important;
}
.margin-bottom {
  margin-bottom: 24px !important;
}
.padding-bottom-lg {
  padding-bottom: 32px !important;
}
.margin-bottom-lg {
  margin-bottom: 32px !important;
}
.padding-bottom-xl {
  padding-bottom: 40px !important;
}
.margin-bottom-xl {
  margin-bottom: 40px !important;
}
.padding-left-0 {
  padding-left: 0 !important;
}
.margin-left-0 {
  margin-left: 0 !important;
}
.padding-left-xs {
  padding-left: 8px !important;
}
.margin-left-xs {
  margin-left: 8px !important;
}
.padding-left-sm {
  padding-left: 16px !important;
}
.margin-left-sm {
  margin-left: 16px !important;
}
.padding-left {
  padding-left: 24px !important;
}
.margin-left {
  margin-left: 24px !important;
}
.padding-left-lg {
  padding-left: 32px !important;
}
.margin-left-lg {
  margin-left: 32px !important;
}
.padding-left-xl {
  padding-left: 40px !important;
}
.margin-left-xl {
  margin-left: 40px !important;
}
.blank-xs {
  position: relative;
  overflow: hidden;
  height: 8px;
}
.blank-sm {
  position: relative;
  overflow: hidden;
  height: 16px;
}
.blank {
  position: relative;
  overflow: hidden;
  height: 24px;
}
.blank-lg {
  position: relative;
  overflow: hidden;
  height: 32px;
}
.blank-xl {
  position: relative;
  overflow: hidden;
  height: 40px;
}
/*1-50循环*/
.mt0 {
  margin-top: 0px !important;
}
.mr0 {
  margin-right: 0px !important;
}
.mb0 {
  margin-bottom: 0px !important;
}
.ml0 {
  margin-left: 0px !important;
}
.mg0 {
  margin: 0px !important;
}
.pt0 {
  padding-top: 0px !important;
}
.pr0 {
  padding-right: 0px !important;
}
.pb0 {
  padding-bottom: 0px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pg0 {
  padding: 0px !important;
}
.pv0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.mv0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.ph0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.mh0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.blank0 {
  position: relative;
  overflow: hidden;
  height: 0px !important;
}
.mt1 {
  margin-top: 1px !important;
}
.mr1 {
  margin-right: 1px !important;
}
.mb1 {
  margin-bottom: 1px !important;
}
.ml1 {
  margin-left: 1px !important;
}
.mg1 {
  margin: 1px !important;
}
.pt1 {
  padding-top: 1px !important;
}
.pr1 {
  padding-right: 1px !important;
}
.pb1 {
  padding-bottom: 1px !important;
}
.pl1 {
  padding-left: 1px !important;
}
.pg1 {
  padding: 1px !important;
}
.pv1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.mv1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}
.ph1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}
.mh1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}
.blank1 {
  position: relative;
  overflow: hidden;
  height: 1px !important;
}
.mt2 {
  margin-top: 2px !important;
}
.mr2 {
  margin-right: 2px !important;
}
.mb2 {
  margin-bottom: 2px !important;
}
.ml2 {
  margin-left: 2px !important;
}
.mg2 {
  margin: 2px !important;
}
.pt2 {
  padding-top: 2px !important;
}
.pr2 {
  padding-right: 2px !important;
}
.pb2 {
  padding-bottom: 2px !important;
}
.pl2 {
  padding-left: 2px !important;
}
.pg2 {
  padding: 2px !important;
}
.pv2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.mv2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}
.ph2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}
.mh2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}
.blank2 {
  position: relative;
  overflow: hidden;
  height: 2px !important;
}
.mt3 {
  margin-top: 3px !important;
}
.mr3 {
  margin-right: 3px !important;
}
.mb3 {
  margin-bottom: 3px !important;
}
.ml3 {
  margin-left: 3px !important;
}
.mg3 {
  margin: 3px !important;
}
.pt3 {
  padding-top: 3px !important;
}
.pr3 {
  padding-right: 3px !important;
}
.pb3 {
  padding-bottom: 3px !important;
}
.pl3 {
  padding-left: 3px !important;
}
.pg3 {
  padding: 3px !important;
}
.pv3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
.mv3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.ph3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.mh3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}
.blank3 {
  position: relative;
  overflow: hidden;
  height: 3px !important;
}
.mt4 {
  margin-top: 4px !important;
}
.mr4 {
  margin-right: 4px !important;
}
.mb4 {
  margin-bottom: 4px !important;
}
.ml4 {
  margin-left: 4px !important;
}
.mg4 {
  margin: 4px !important;
}
.pt4 {
  padding-top: 4px !important;
}
.pr4 {
  padding-right: 4px !important;
}
.pb4 {
  padding-bottom: 4px !important;
}
.pl4 {
  padding-left: 4px !important;
}
.pg4 {
  padding: 4px !important;
}
.pv4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.mv4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}
.ph4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}
.mh4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}
.blank4 {
  position: relative;
  overflow: hidden;
  height: 4px !important;
}
.mt5 {
  margin-top: 5px !important;
}
.mr5 {
  margin-right: 5px !important;
}
.mb5 {
  margin-bottom: 5px !important;
}
.ml5 {
  margin-left: 5px !important;
}
.mg5 {
  margin: 5px !important;
}
.pt5 {
  padding-top: 5px !important;
}
.pr5 {
  padding-right: 5px !important;
}
.pb5 {
  padding-bottom: 5px !important;
}
.pl5 {
  padding-left: 5px !important;
}
.pg5 {
  padding: 5px !important;
}
.pv5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.mv5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.ph5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.mh5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.blank5 {
  position: relative;
  overflow: hidden;
  height: 5px !important;
}
.mt6 {
  margin-top: 6px !important;
}
.mr6 {
  margin-right: 6px !important;
}
.mb6 {
  margin-bottom: 6px !important;
}
.ml6 {
  margin-left: 6px !important;
}
.mg6 {
  margin: 6px !important;
}
.pt6 {
  padding-top: 6px !important;
}
.pr6 {
  padding-right: 6px !important;
}
.pb6 {
  padding-bottom: 6px !important;
}
.pl6 {
  padding-left: 6px !important;
}
.pg6 {
  padding: 6px !important;
}
.pv6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}
.mv6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}
.ph6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.mh6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}
.blank6 {
  position: relative;
  overflow: hidden;
  height: 6px !important;
}
.mt7 {
  margin-top: 7px !important;
}
.mr7 {
  margin-right: 7px !important;
}
.mb7 {
  margin-bottom: 7px !important;
}
.ml7 {
  margin-left: 7px !important;
}
.mg7 {
  margin: 7px !important;
}
.pt7 {
  padding-top: 7px !important;
}
.pr7 {
  padding-right: 7px !important;
}
.pb7 {
  padding-bottom: 7px !important;
}
.pl7 {
  padding-left: 7px !important;
}
.pg7 {
  padding: 7px !important;
}
.pv7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.mv7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}
.ph7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}
.mh7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}
.blank7 {
  position: relative;
  overflow: hidden;
  height: 7px !important;
}
.mt8 {
  margin-top: 8px !important;
}
.mr8 {
  margin-right: 8px !important;
}
.mb8 {
  margin-bottom: 8px !important;
}
.ml8 {
  margin-left: 8px !important;
}
.mg8 {
  margin: 8px !important;
}
.pt8 {
  padding-top: 8px !important;
}
.pr8 {
  padding-right: 8px !important;
}
.pb8 {
  padding-bottom: 8px !important;
}
.pl8 {
  padding-left: 8px !important;
}
.pg8 {
  padding: 8px !important;
}
.pv8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.mv8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.ph8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.mh8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.blank8 {
  position: relative;
  overflow: hidden;
  height: 8px !important;
}
.mt9 {
  margin-top: 9px !important;
}
.mr9 {
  margin-right: 9px !important;
}
.mb9 {
  margin-bottom: 9px !important;
}
.ml9 {
  margin-left: 9px !important;
}
.mg9 {
  margin: 9px !important;
}
.pt9 {
  padding-top: 9px !important;
}
.pr9 {
  padding-right: 9px !important;
}
.pb9 {
  padding-bottom: 9px !important;
}
.pl9 {
  padding-left: 9px !important;
}
.pg9 {
  padding: 9px !important;
}
.pv9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}
.mv9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}
.ph9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}
.mh9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}
.blank9 {
  position: relative;
  overflow: hidden;
  height: 9px !important;
}
.mt10 {
  margin-top: 10px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}
.ml10 {
  margin-left: 10px !important;
}
.mg10 {
  margin: 10px !important;
}
.pt10 {
  padding-top: 10px !important;
}
.pr10 {
  padding-right: 10px !important;
}
.pb10 {
  padding-bottom: 10px !important;
}
.pl10 {
  padding-left: 10px !important;
}
.pg10 {
  padding: 10px !important;
}
.pv10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.mv10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.ph10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.mh10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.blank10 {
  position: relative;
  overflow: hidden;
  height: 10px !important;
}
.mt11 {
  margin-top: 11px !important;
}
.mr11 {
  margin-right: 11px !important;
}
.mb11 {
  margin-bottom: 11px !important;
}
.ml11 {
  margin-left: 11px !important;
}
.mg11 {
  margin: 11px !important;
}
.pt11 {
  padding-top: 11px !important;
}
.pr11 {
  padding-right: 11px !important;
}
.pb11 {
  padding-bottom: 11px !important;
}
.pl11 {
  padding-left: 11px !important;
}
.pg11 {
  padding: 11px !important;
}
.pv11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}
.mv11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}
.ph11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}
.mh11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}
.blank11 {
  position: relative;
  overflow: hidden;
  height: 11px !important;
}
.mt12 {
  margin-top: 12px !important;
}
.mr12 {
  margin-right: 12px !important;
}
.mb12 {
  margin-bottom: 12px !important;
}
.ml12 {
  margin-left: 12px !important;
}
.mg12 {
  margin: 12px !important;
}
.pt12 {
  padding-top: 12px !important;
}
.pr12 {
  padding-right: 12px !important;
}
.pb12 {
  padding-bottom: 12px !important;
}
.pl12 {
  padding-left: 12px !important;
}
.pg12 {
  padding: 12px !important;
}
.pv12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.mv12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}
.ph12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.mh12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}
.blank12 {
  position: relative;
  overflow: hidden;
  height: 12px !important;
}
.mt13 {
  margin-top: 13px !important;
}
.mr13 {
  margin-right: 13px !important;
}
.mb13 {
  margin-bottom: 13px !important;
}
.ml13 {
  margin-left: 13px !important;
}
.mg13 {
  margin: 13px !important;
}
.pt13 {
  padding-top: 13px !important;
}
.pr13 {
  padding-right: 13px !important;
}
.pb13 {
  padding-bottom: 13px !important;
}
.pl13 {
  padding-left: 13px !important;
}
.pg13 {
  padding: 13px !important;
}
.pv13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}
.mv13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}
.ph13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}
.mh13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}
.blank13 {
  position: relative;
  overflow: hidden;
  height: 13px !important;
}
.mt14 {
  margin-top: 14px !important;
}
.mr14 {
  margin-right: 14px !important;
}
.mb14 {
  margin-bottom: 14px !important;
}
.ml14 {
  margin-left: 14px !important;
}
.mg14 {
  margin: 14px !important;
}
.pt14 {
  padding-top: 14px !important;
}
.pr14 {
  padding-right: 14px !important;
}
.pb14 {
  padding-bottom: 14px !important;
}
.pl14 {
  padding-left: 14px !important;
}
.pg14 {
  padding: 14px !important;
}
.pv14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.mv14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
.ph14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}
.mh14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}
.blank14 {
  position: relative;
  overflow: hidden;
  height: 14px !important;
}
.mt15 {
  margin-top: 15px !important;
}
.mr15 {
  margin-right: 15px !important;
}
.mb15 {
  margin-bottom: 15px !important;
}
.ml15 {
  margin-left: 15px !important;
}
.mg15 {
  margin: 15px !important;
}
.pt15 {
  padding-top: 15px !important;
}
.pr15 {
  padding-right: 15px !important;
}
.pb15 {
  padding-bottom: 15px !important;
}
.pl15 {
  padding-left: 15px !important;
}
.pg15 {
  padding: 15px !important;
}
.pv15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.mv15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.ph15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.mh15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.blank15 {
  position: relative;
  overflow: hidden;
  height: 15px !important;
}
.mt16 {
  margin-top: 16px !important;
}
.mr16 {
  margin-right: 16px !important;
}
.mb16 {
  margin-bottom: 16px !important;
}
.ml16 {
  margin-left: 16px !important;
}
.mg16 {
  margin: 16px !important;
}
.pt16 {
  padding-top: 16px !important;
}
.pr16 {
  padding-right: 16px !important;
}
.pb16 {
  padding-bottom: 16px !important;
}
.pl16 {
  padding-left: 16px !important;
}
.pg16 {
  padding: 16px !important;
}
.pv16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}
.mv16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.ph16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}
.mh16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.blank16 {
  position: relative;
  overflow: hidden;
  height: 16px !important;
}
.mt17 {
  margin-top: 17px !important;
}
.mr17 {
  margin-right: 17px !important;
}
.mb17 {
  margin-bottom: 17px !important;
}
.ml17 {
  margin-left: 17px !important;
}
.mg17 {
  margin: 17px !important;
}
.pt17 {
  padding-top: 17px !important;
}
.pr17 {
  padding-right: 17px !important;
}
.pb17 {
  padding-bottom: 17px !important;
}
.pl17 {
  padding-left: 17px !important;
}
.pg17 {
  padding: 17px !important;
}
.pv17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}
.mv17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}
.ph17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}
.mh17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}
.blank17 {
  position: relative;
  overflow: hidden;
  height: 17px !important;
}
.mt18 {
  margin-top: 18px !important;
}
.mr18 {
  margin-right: 18px !important;
}
.mb18 {
  margin-bottom: 18px !important;
}
.ml18 {
  margin-left: 18px !important;
}
.mg18 {
  margin: 18px !important;
}
.pt18 {
  padding-top: 18px !important;
}
.pr18 {
  padding-right: 18px !important;
}
.pb18 {
  padding-bottom: 18px !important;
}
.pl18 {
  padding-left: 18px !important;
}
.pg18 {
  padding: 18px !important;
}
.pv18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}
.mv18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}
.ph18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}
.mh18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}
.blank18 {
  position: relative;
  overflow: hidden;
  height: 18px !important;
}
.mt19 {
  margin-top: 19px !important;
}
.mr19 {
  margin-right: 19px !important;
}
.mb19 {
  margin-bottom: 19px !important;
}
.ml19 {
  margin-left: 19px !important;
}
.mg19 {
  margin: 19px !important;
}
.pt19 {
  padding-top: 19px !important;
}
.pr19 {
  padding-right: 19px !important;
}
.pb19 {
  padding-bottom: 19px !important;
}
.pl19 {
  padding-left: 19px !important;
}
.pg19 {
  padding: 19px !important;
}
.pv19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}
.mv19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}
.ph19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}
.mh19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}
.blank19 {
  position: relative;
  overflow: hidden;
  height: 19px !important;
}
.mt20 {
  margin-top: 20px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mb20 {
  margin-bottom: 20px !important;
}
.ml20 {
  margin-left: 20px !important;
}
.mg20 {
  margin: 20px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.pr20 {
  padding-right: 20px !important;
}
.pb20 {
  padding-bottom: 20px !important;
}
.pl20 {
  padding-left: 20px !important;
}
.pg20 {
  padding: 20px !important;
}
.pv20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.mv20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.ph20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.mh20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.blank20 {
  position: relative;
  overflow: hidden;
  height: 20px !important;
}
.mt21 {
  margin-top: 21px !important;
}
.mr21 {
  margin-right: 21px !important;
}
.mb21 {
  margin-bottom: 21px !important;
}
.ml21 {
  margin-left: 21px !important;
}
.mg21 {
  margin: 21px !important;
}
.pt21 {
  padding-top: 21px !important;
}
.pr21 {
  padding-right: 21px !important;
}
.pb21 {
  padding-bottom: 21px !important;
}
.pl21 {
  padding-left: 21px !important;
}
.pg21 {
  padding: 21px !important;
}
.pv21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}
.mv21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}
.ph21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}
.mh21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}
.blank21 {
  position: relative;
  overflow: hidden;
  height: 21px !important;
}
.mt22 {
  margin-top: 22px !important;
}
.mr22 {
  margin-right: 22px !important;
}
.mb22 {
  margin-bottom: 22px !important;
}
.ml22 {
  margin-left: 22px !important;
}
.mg22 {
  margin: 22px !important;
}
.pt22 {
  padding-top: 22px !important;
}
.pr22 {
  padding-right: 22px !important;
}
.pb22 {
  padding-bottom: 22px !important;
}
.pl22 {
  padding-left: 22px !important;
}
.pg22 {
  padding: 22px !important;
}
.pv22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}
.mv22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}
.ph22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}
.mh22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}
.blank22 {
  position: relative;
  overflow: hidden;
  height: 22px !important;
}
.mt23 {
  margin-top: 23px !important;
}
.mr23 {
  margin-right: 23px !important;
}
.mb23 {
  margin-bottom: 23px !important;
}
.ml23 {
  margin-left: 23px !important;
}
.mg23 {
  margin: 23px !important;
}
.pt23 {
  padding-top: 23px !important;
}
.pr23 {
  padding-right: 23px !important;
}
.pb23 {
  padding-bottom: 23px !important;
}
.pl23 {
  padding-left: 23px !important;
}
.pg23 {
  padding: 23px !important;
}
.pv23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}
.mv23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}
.ph23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}
.mh23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}
.blank23 {
  position: relative;
  overflow: hidden;
  height: 23px !important;
}
.mt24 {
  margin-top: 24px !important;
}
.mr24 {
  margin-right: 24px !important;
}
.mb24 {
  margin-bottom: 24px !important;
}
.ml24 {
  margin-left: 24px !important;
}
.mg24 {
  margin: 24px !important;
}
.pt24 {
  padding-top: 24px !important;
}
.pr24 {
  padding-right: 24px !important;
}
.pb24 {
  padding-bottom: 24px !important;
}
.pl24 {
  padding-left: 24px !important;
}
.pg24 {
  padding: 24px !important;
}
.pv24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}
.mv24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}
.ph24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.mh24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}
.blank24 {
  position: relative;
  overflow: hidden;
  height: 24px !important;
}
.mt25 {
  margin-top: 25px !important;
}
.mr25 {
  margin-right: 25px !important;
}
.mb25 {
  margin-bottom: 25px !important;
}
.ml25 {
  margin-left: 25px !important;
}
.mg25 {
  margin: 25px !important;
}
.pt25 {
  padding-top: 25px !important;
}
.pr25 {
  padding-right: 25px !important;
}
.pb25 {
  padding-bottom: 25px !important;
}
.pl25 {
  padding-left: 25px !important;
}
.pg25 {
  padding: 25px !important;
}
.pv25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.mv25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.ph25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.mh25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.blank25 {
  position: relative;
  overflow: hidden;
  height: 25px !important;
}
.mt26 {
  margin-top: 26px !important;
}
.mr26 {
  margin-right: 26px !important;
}
.mb26 {
  margin-bottom: 26px !important;
}
.ml26 {
  margin-left: 26px !important;
}
.mg26 {
  margin: 26px !important;
}
.pt26 {
  padding-top: 26px !important;
}
.pr26 {
  padding-right: 26px !important;
}
.pb26 {
  padding-bottom: 26px !important;
}
.pl26 {
  padding-left: 26px !important;
}
.pg26 {
  padding: 26px !important;
}
.pv26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}
.mv26 {
  margin-top: 26px !important;
  margin-bottom: 26px !important;
}
.ph26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}
.mh26 {
  margin-left: 26px !important;
  margin-right: 26px !important;
}
.blank26 {
  position: relative;
  overflow: hidden;
  height: 26px !important;
}
.mt27 {
  margin-top: 27px !important;
}
.mr27 {
  margin-right: 27px !important;
}
.mb27 {
  margin-bottom: 27px !important;
}
.ml27 {
  margin-left: 27px !important;
}
.mg27 {
  margin: 27px !important;
}
.pt27 {
  padding-top: 27px !important;
}
.pr27 {
  padding-right: 27px !important;
}
.pb27 {
  padding-bottom: 27px !important;
}
.pl27 {
  padding-left: 27px !important;
}
.pg27 {
  padding: 27px !important;
}
.pv27 {
  padding-top: 27px !important;
  padding-bottom: 27px !important;
}
.mv27 {
  margin-top: 27px !important;
  margin-bottom: 27px !important;
}
.ph27 {
  padding-left: 27px !important;
  padding-right: 27px !important;
}
.mh27 {
  margin-left: 27px !important;
  margin-right: 27px !important;
}
.blank27 {
  position: relative;
  overflow: hidden;
  height: 27px !important;
}
.mt28 {
  margin-top: 28px !important;
}
.mr28 {
  margin-right: 28px !important;
}
.mb28 {
  margin-bottom: 28px !important;
}
.ml28 {
  margin-left: 28px !important;
}
.mg28 {
  margin: 28px !important;
}
.pt28 {
  padding-top: 28px !important;
}
.pr28 {
  padding-right: 28px !important;
}
.pb28 {
  padding-bottom: 28px !important;
}
.pl28 {
  padding-left: 28px !important;
}
.pg28 {
  padding: 28px !important;
}
.pv28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}
.mv28 {
  margin-top: 28px !important;
  margin-bottom: 28px !important;
}
.ph28 {
  padding-left: 28px !important;
  padding-right: 28px !important;
}
.mh28 {
  margin-left: 28px !important;
  margin-right: 28px !important;
}
.blank28 {
  position: relative;
  overflow: hidden;
  height: 28px !important;
}
.mt29 {
  margin-top: 29px !important;
}
.mr29 {
  margin-right: 29px !important;
}
.mb29 {
  margin-bottom: 29px !important;
}
.ml29 {
  margin-left: 29px !important;
}
.mg29 {
  margin: 29px !important;
}
.pt29 {
  padding-top: 29px !important;
}
.pr29 {
  padding-right: 29px !important;
}
.pb29 {
  padding-bottom: 29px !important;
}
.pl29 {
  padding-left: 29px !important;
}
.pg29 {
  padding: 29px !important;
}
.pv29 {
  padding-top: 29px !important;
  padding-bottom: 29px !important;
}
.mv29 {
  margin-top: 29px !important;
  margin-bottom: 29px !important;
}
.ph29 {
  padding-left: 29px !important;
  padding-right: 29px !important;
}
.mh29 {
  margin-left: 29px !important;
  margin-right: 29px !important;
}
.blank29 {
  position: relative;
  overflow: hidden;
  height: 29px !important;
}
.mt30 {
  margin-top: 30px !important;
}
.mr30 {
  margin-right: 30px !important;
}
.mb30 {
  margin-bottom: 30px !important;
}
.ml30 {
  margin-left: 30px !important;
}
.mg30 {
  margin: 30px !important;
}
.pt30 {
  padding-top: 30px !important;
}
.pr30 {
  padding-right: 30px !important;
}
.pb30 {
  padding-bottom: 30px !important;
}
.pl30 {
  padding-left: 30px !important;
}
.pg30 {
  padding: 30px !important;
}
.pv30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.mv30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.ph30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.mh30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.blank30 {
  position: relative;
  overflow: hidden;
  height: 30px !important;
}
.mt31 {
  margin-top: 31px !important;
}
.mr31 {
  margin-right: 31px !important;
}
.mb31 {
  margin-bottom: 31px !important;
}
.ml31 {
  margin-left: 31px !important;
}
.mg31 {
  margin: 31px !important;
}
.pt31 {
  padding-top: 31px !important;
}
.pr31 {
  padding-right: 31px !important;
}
.pb31 {
  padding-bottom: 31px !important;
}
.pl31 {
  padding-left: 31px !important;
}
.pg31 {
  padding: 31px !important;
}
.pv31 {
  padding-top: 31px !important;
  padding-bottom: 31px !important;
}
.mv31 {
  margin-top: 31px !important;
  margin-bottom: 31px !important;
}
.ph31 {
  padding-left: 31px !important;
  padding-right: 31px !important;
}
.mh31 {
  margin-left: 31px !important;
  margin-right: 31px !important;
}
.blank31 {
  position: relative;
  overflow: hidden;
  height: 31px !important;
}
.mt32 {
  margin-top: 32px !important;
}
.mr32 {
  margin-right: 32px !important;
}
.mb32 {
  margin-bottom: 32px !important;
}
.ml32 {
  margin-left: 32px !important;
}
.mg32 {
  margin: 32px !important;
}
.pt32 {
  padding-top: 32px !important;
}
.pr32 {
  padding-right: 32px !important;
}
.pb32 {
  padding-bottom: 32px !important;
}
.pl32 {
  padding-left: 32px !important;
}
.pg32 {
  padding: 32px !important;
}
.pv32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}
.mv32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}
.ph32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}
.mh32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}
.blank32 {
  position: relative;
  overflow: hidden;
  height: 32px !important;
}
.mt33 {
  margin-top: 33px !important;
}
.mr33 {
  margin-right: 33px !important;
}
.mb33 {
  margin-bottom: 33px !important;
}
.ml33 {
  margin-left: 33px !important;
}
.mg33 {
  margin: 33px !important;
}
.pt33 {
  padding-top: 33px !important;
}
.pr33 {
  padding-right: 33px !important;
}
.pb33 {
  padding-bottom: 33px !important;
}
.pl33 {
  padding-left: 33px !important;
}
.pg33 {
  padding: 33px !important;
}
.pv33 {
  padding-top: 33px !important;
  padding-bottom: 33px !important;
}
.mv33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}
.ph33 {
  padding-left: 33px !important;
  padding-right: 33px !important;
}
.mh33 {
  margin-left: 33px !important;
  margin-right: 33px !important;
}
.blank33 {
  position: relative;
  overflow: hidden;
  height: 33px !important;
}
.mt34 {
  margin-top: 34px !important;
}
.mr34 {
  margin-right: 34px !important;
}
.mb34 {
  margin-bottom: 34px !important;
}
.ml34 {
  margin-left: 34px !important;
}
.mg34 {
  margin: 34px !important;
}
.pt34 {
  padding-top: 34px !important;
}
.pr34 {
  padding-right: 34px !important;
}
.pb34 {
  padding-bottom: 34px !important;
}
.pl34 {
  padding-left: 34px !important;
}
.pg34 {
  padding: 34px !important;
}
.pv34 {
  padding-top: 34px !important;
  padding-bottom: 34px !important;
}
.mv34 {
  margin-top: 34px !important;
  margin-bottom: 34px !important;
}
.ph34 {
  padding-left: 34px !important;
  padding-right: 34px !important;
}
.mh34 {
  margin-left: 34px !important;
  margin-right: 34px !important;
}
.blank34 {
  position: relative;
  overflow: hidden;
  height: 34px !important;
}
.mt35 {
  margin-top: 35px !important;
}
.mr35 {
  margin-right: 35px !important;
}
.mb35 {
  margin-bottom: 35px !important;
}
.ml35 {
  margin-left: 35px !important;
}
.mg35 {
  margin: 35px !important;
}
.pt35 {
  padding-top: 35px !important;
}
.pr35 {
  padding-right: 35px !important;
}
.pb35 {
  padding-bottom: 35px !important;
}
.pl35 {
  padding-left: 35px !important;
}
.pg35 {
  padding: 35px !important;
}
.pv35 {
  padding-top: 35px !important;
  padding-bottom: 35px !important;
}
.mv35 {
  margin-top: 35px !important;
  margin-bottom: 35px !important;
}
.ph35 {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.mh35 {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
.blank35 {
  position: relative;
  overflow: hidden;
  height: 35px !important;
}
.mt36 {
  margin-top: 36px !important;
}
.mr36 {
  margin-right: 36px !important;
}
.mb36 {
  margin-bottom: 36px !important;
}
.ml36 {
  margin-left: 36px !important;
}
.mg36 {
  margin: 36px !important;
}
.pt36 {
  padding-top: 36px !important;
}
.pr36 {
  padding-right: 36px !important;
}
.pb36 {
  padding-bottom: 36px !important;
}
.pl36 {
  padding-left: 36px !important;
}
.pg36 {
  padding: 36px !important;
}
.pv36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}
.mv36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}
.ph36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}
.mh36 {
  margin-left: 36px !important;
  margin-right: 36px !important;
}
.blank36 {
  position: relative;
  overflow: hidden;
  height: 36px !important;
}
.mt37 {
  margin-top: 37px !important;
}
.mr37 {
  margin-right: 37px !important;
}
.mb37 {
  margin-bottom: 37px !important;
}
.ml37 {
  margin-left: 37px !important;
}
.mg37 {
  margin: 37px !important;
}
.pt37 {
  padding-top: 37px !important;
}
.pr37 {
  padding-right: 37px !important;
}
.pb37 {
  padding-bottom: 37px !important;
}
.pl37 {
  padding-left: 37px !important;
}
.pg37 {
  padding: 37px !important;
}
.pv37 {
  padding-top: 37px !important;
  padding-bottom: 37px !important;
}
.mv37 {
  margin-top: 37px !important;
  margin-bottom: 37px !important;
}
.ph37 {
  padding-left: 37px !important;
  padding-right: 37px !important;
}
.mh37 {
  margin-left: 37px !important;
  margin-right: 37px !important;
}
.blank37 {
  position: relative;
  overflow: hidden;
  height: 37px !important;
}
.mt38 {
  margin-top: 38px !important;
}
.mr38 {
  margin-right: 38px !important;
}
.mb38 {
  margin-bottom: 38px !important;
}
.ml38 {
  margin-left: 38px !important;
}
.mg38 {
  margin: 38px !important;
}
.pt38 {
  padding-top: 38px !important;
}
.pr38 {
  padding-right: 38px !important;
}
.pb38 {
  padding-bottom: 38px !important;
}
.pl38 {
  padding-left: 38px !important;
}
.pg38 {
  padding: 38px !important;
}
.pv38 {
  padding-top: 38px !important;
  padding-bottom: 38px !important;
}
.mv38 {
  margin-top: 38px !important;
  margin-bottom: 38px !important;
}
.ph38 {
  padding-left: 38px !important;
  padding-right: 38px !important;
}
.mh38 {
  margin-left: 38px !important;
  margin-right: 38px !important;
}
.blank38 {
  position: relative;
  overflow: hidden;
  height: 38px !important;
}
.mt39 {
  margin-top: 39px !important;
}
.mr39 {
  margin-right: 39px !important;
}
.mb39 {
  margin-bottom: 39px !important;
}
.ml39 {
  margin-left: 39px !important;
}
.mg39 {
  margin: 39px !important;
}
.pt39 {
  padding-top: 39px !important;
}
.pr39 {
  padding-right: 39px !important;
}
.pb39 {
  padding-bottom: 39px !important;
}
.pl39 {
  padding-left: 39px !important;
}
.pg39 {
  padding: 39px !important;
}
.pv39 {
  padding-top: 39px !important;
  padding-bottom: 39px !important;
}
.mv39 {
  margin-top: 39px !important;
  margin-bottom: 39px !important;
}
.ph39 {
  padding-left: 39px !important;
  padding-right: 39px !important;
}
.mh39 {
  margin-left: 39px !important;
  margin-right: 39px !important;
}
.blank39 {
  position: relative;
  overflow: hidden;
  height: 39px !important;
}
.mt40 {
  margin-top: 40px !important;
}
.mr40 {
  margin-right: 40px !important;
}
.mb40 {
  margin-bottom: 40px !important;
}
.ml40 {
  margin-left: 40px !important;
}
.mg40 {
  margin: 40px !important;
}
.pt40 {
  padding-top: 40px !important;
}
.pr40 {
  padding-right: 40px !important;
}
.pb40 {
  padding-bottom: 40px !important;
}
.pl40 {
  padding-left: 40px !important;
}
.pg40 {
  padding: 40px !important;
}
.pv40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.mv40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.ph40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.mh40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.blank40 {
  position: relative;
  overflow: hidden;
  height: 40px !important;
}
.mt41 {
  margin-top: 41px !important;
}
.mr41 {
  margin-right: 41px !important;
}
.mb41 {
  margin-bottom: 41px !important;
}
.ml41 {
  margin-left: 41px !important;
}
.mg41 {
  margin: 41px !important;
}
.pt41 {
  padding-top: 41px !important;
}
.pr41 {
  padding-right: 41px !important;
}
.pb41 {
  padding-bottom: 41px !important;
}
.pl41 {
  padding-left: 41px !important;
}
.pg41 {
  padding: 41px !important;
}
.pv41 {
  padding-top: 41px !important;
  padding-bottom: 41px !important;
}
.mv41 {
  margin-top: 41px !important;
  margin-bottom: 41px !important;
}
.ph41 {
  padding-left: 41px !important;
  padding-right: 41px !important;
}
.mh41 {
  margin-left: 41px !important;
  margin-right: 41px !important;
}
.blank41 {
  position: relative;
  overflow: hidden;
  height: 41px !important;
}
.mt42 {
  margin-top: 42px !important;
}
.mr42 {
  margin-right: 42px !important;
}
.mb42 {
  margin-bottom: 42px !important;
}
.ml42 {
  margin-left: 42px !important;
}
.mg42 {
  margin: 42px !important;
}
.pt42 {
  padding-top: 42px !important;
}
.pr42 {
  padding-right: 42px !important;
}
.pb42 {
  padding-bottom: 42px !important;
}
.pl42 {
  padding-left: 42px !important;
}
.pg42 {
  padding: 42px !important;
}
.pv42 {
  padding-top: 42px !important;
  padding-bottom: 42px !important;
}
.mv42 {
  margin-top: 42px !important;
  margin-bottom: 42px !important;
}
.ph42 {
  padding-left: 42px !important;
  padding-right: 42px !important;
}
.mh42 {
  margin-left: 42px !important;
  margin-right: 42px !important;
}
.blank42 {
  position: relative;
  overflow: hidden;
  height: 42px !important;
}
.mt43 {
  margin-top: 43px !important;
}
.mr43 {
  margin-right: 43px !important;
}
.mb43 {
  margin-bottom: 43px !important;
}
.ml43 {
  margin-left: 43px !important;
}
.mg43 {
  margin: 43px !important;
}
.pt43 {
  padding-top: 43px !important;
}
.pr43 {
  padding-right: 43px !important;
}
.pb43 {
  padding-bottom: 43px !important;
}
.pl43 {
  padding-left: 43px !important;
}
.pg43 {
  padding: 43px !important;
}
.pv43 {
  padding-top: 43px !important;
  padding-bottom: 43px !important;
}
.mv43 {
  margin-top: 43px !important;
  margin-bottom: 43px !important;
}
.ph43 {
  padding-left: 43px !important;
  padding-right: 43px !important;
}
.mh43 {
  margin-left: 43px !important;
  margin-right: 43px !important;
}
.blank43 {
  position: relative;
  overflow: hidden;
  height: 43px !important;
}
.mt44 {
  margin-top: 44px !important;
}
.mr44 {
  margin-right: 44px !important;
}
.mb44 {
  margin-bottom: 44px !important;
}
.ml44 {
  margin-left: 44px !important;
}
.mg44 {
  margin: 44px !important;
}
.pt44 {
  padding-top: 44px !important;
}
.pr44 {
  padding-right: 44px !important;
}
.pb44 {
  padding-bottom: 44px !important;
}
.pl44 {
  padding-left: 44px !important;
}
.pg44 {
  padding: 44px !important;
}
.pv44 {
  padding-top: 44px !important;
  padding-bottom: 44px !important;
}
.mv44 {
  margin-top: 44px !important;
  margin-bottom: 44px !important;
}
.ph44 {
  padding-left: 44px !important;
  padding-right: 44px !important;
}
.mh44 {
  margin-left: 44px !important;
  margin-right: 44px !important;
}
.blank44 {
  position: relative;
  overflow: hidden;
  height: 44px !important;
}
.mt45 {
  margin-top: 45px !important;
}
.mr45 {
  margin-right: 45px !important;
}
.mb45 {
  margin-bottom: 45px !important;
}
.ml45 {
  margin-left: 45px !important;
}
.mg45 {
  margin: 45px !important;
}
.pt45 {
  padding-top: 45px !important;
}
.pr45 {
  padding-right: 45px !important;
}
.pb45 {
  padding-bottom: 45px !important;
}
.pl45 {
  padding-left: 45px !important;
}
.pg45 {
  padding: 45px !important;
}
.pv45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.mv45 {
  margin-top: 45px !important;
  margin-bottom: 45px !important;
}
.ph45 {
  padding-left: 45px !important;
  padding-right: 45px !important;
}
.mh45 {
  margin-left: 45px !important;
  margin-right: 45px !important;
}
.blank45 {
  position: relative;
  overflow: hidden;
  height: 45px !important;
}
.mt46 {
  margin-top: 46px !important;
}
.mr46 {
  margin-right: 46px !important;
}
.mb46 {
  margin-bottom: 46px !important;
}
.ml46 {
  margin-left: 46px !important;
}
.mg46 {
  margin: 46px !important;
}
.pt46 {
  padding-top: 46px !important;
}
.pr46 {
  padding-right: 46px !important;
}
.pb46 {
  padding-bottom: 46px !important;
}
.pl46 {
  padding-left: 46px !important;
}
.pg46 {
  padding: 46px !important;
}
.pv46 {
  padding-top: 46px !important;
  padding-bottom: 46px !important;
}
.mv46 {
  margin-top: 46px !important;
  margin-bottom: 46px !important;
}
.ph46 {
  padding-left: 46px !important;
  padding-right: 46px !important;
}
.mh46 {
  margin-left: 46px !important;
  margin-right: 46px !important;
}
.blank46 {
  position: relative;
  overflow: hidden;
  height: 46px !important;
}
.mt47 {
  margin-top: 47px !important;
}
.mr47 {
  margin-right: 47px !important;
}
.mb47 {
  margin-bottom: 47px !important;
}
.ml47 {
  margin-left: 47px !important;
}
.mg47 {
  margin: 47px !important;
}
.pt47 {
  padding-top: 47px !important;
}
.pr47 {
  padding-right: 47px !important;
}
.pb47 {
  padding-bottom: 47px !important;
}
.pl47 {
  padding-left: 47px !important;
}
.pg47 {
  padding: 47px !important;
}
.pv47 {
  padding-top: 47px !important;
  padding-bottom: 47px !important;
}
.mv47 {
  margin-top: 47px !important;
  margin-bottom: 47px !important;
}
.ph47 {
  padding-left: 47px !important;
  padding-right: 47px !important;
}
.mh47 {
  margin-left: 47px !important;
  margin-right: 47px !important;
}
.blank47 {
  position: relative;
  overflow: hidden;
  height: 47px !important;
}
.mt48 {
  margin-top: 48px !important;
}
.mr48 {
  margin-right: 48px !important;
}
.mb48 {
  margin-bottom: 48px !important;
}
.ml48 {
  margin-left: 48px !important;
}
.mg48 {
  margin: 48px !important;
}
.pt48 {
  padding-top: 48px !important;
}
.pr48 {
  padding-right: 48px !important;
}
.pb48 {
  padding-bottom: 48px !important;
}
.pl48 {
  padding-left: 48px !important;
}
.pg48 {
  padding: 48px !important;
}
.pv48 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}
.mv48 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}
.ph48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}
.mh48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}
.blank48 {
  position: relative;
  overflow: hidden;
  height: 48px !important;
}
.mt49 {
  margin-top: 49px !important;
}
.mr49 {
  margin-right: 49px !important;
}
.mb49 {
  margin-bottom: 49px !important;
}
.ml49 {
  margin-left: 49px !important;
}
.mg49 {
  margin: 49px !important;
}
.pt49 {
  padding-top: 49px !important;
}
.pr49 {
  padding-right: 49px !important;
}
.pb49 {
  padding-bottom: 49px !important;
}
.pl49 {
  padding-left: 49px !important;
}
.pg49 {
  padding: 49px !important;
}
.pv49 {
  padding-top: 49px !important;
  padding-bottom: 49px !important;
}
.mv49 {
  margin-top: 49px !important;
  margin-bottom: 49px !important;
}
.ph49 {
  padding-left: 49px !important;
  padding-right: 49px !important;
}
.mh49 {
  margin-left: 49px !important;
  margin-right: 49px !important;
}
.blank49 {
  position: relative;
  overflow: hidden;
  height: 49px !important;
}
.mt50 {
  margin-top: 50px !important;
}
.mr50 {
  margin-right: 50px !important;
}
.mb50 {
  margin-bottom: 50px !important;
}
.ml50 {
  margin-left: 50px !important;
}
.mg50 {
  margin: 50px !important;
}
.pt50 {
  padding-top: 50px !important;
}
.pr50 {
  padding-right: 50px !important;
}
.pb50 {
  padding-bottom: 50px !important;
}
.pl50 {
  padding-left: 50px !important;
}
.pg50 {
  padding: 50px !important;
}
.pv50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.mv50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.ph50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.mh50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.blank50 {
  position: relative;
  overflow: hidden;
  height: 50px !important;
}
.mt51 {
  margin-top: 51px !important;
}
.mr51 {
  margin-right: 51px !important;
}
.mb51 {
  margin-bottom: 51px !important;
}
.ml51 {
  margin-left: 51px !important;
}
.mg51 {
  margin: 51px !important;
}
.pt51 {
  padding-top: 51px !important;
}
.pr51 {
  padding-right: 51px !important;
}
.pb51 {
  padding-bottom: 51px !important;
}
.pl51 {
  padding-left: 51px !important;
}
.pg51 {
  padding: 51px !important;
}
.pv51 {
  padding-top: 51px !important;
  padding-bottom: 51px !important;
}
.mv51 {
  margin-top: 51px !important;
  margin-bottom: 51px !important;
}
.ph51 {
  padding-left: 51px !important;
  padding-right: 51px !important;
}
.mh51 {
  margin-left: 51px !important;
  margin-right: 51px !important;
}
.blank51 {
  position: relative;
  overflow: hidden;
  height: 51px !important;
}
.mt52 {
  margin-top: 52px !important;
}
.mr52 {
  margin-right: 52px !important;
}
.mb52 {
  margin-bottom: 52px !important;
}
.ml52 {
  margin-left: 52px !important;
}
.mg52 {
  margin: 52px !important;
}
.pt52 {
  padding-top: 52px !important;
}
.pr52 {
  padding-right: 52px !important;
}
.pb52 {
  padding-bottom: 52px !important;
}
.pl52 {
  padding-left: 52px !important;
}
.pg52 {
  padding: 52px !important;
}
.pv52 {
  padding-top: 52px !important;
  padding-bottom: 52px !important;
}
.mv52 {
  margin-top: 52px !important;
  margin-bottom: 52px !important;
}
.ph52 {
  padding-left: 52px !important;
  padding-right: 52px !important;
}
.mh52 {
  margin-left: 52px !important;
  margin-right: 52px !important;
}
.blank52 {
  position: relative;
  overflow: hidden;
  height: 52px !important;
}
.mt53 {
  margin-top: 53px !important;
}
.mr53 {
  margin-right: 53px !important;
}
.mb53 {
  margin-bottom: 53px !important;
}
.ml53 {
  margin-left: 53px !important;
}
.mg53 {
  margin: 53px !important;
}
.pt53 {
  padding-top: 53px !important;
}
.pr53 {
  padding-right: 53px !important;
}
.pb53 {
  padding-bottom: 53px !important;
}
.pl53 {
  padding-left: 53px !important;
}
.pg53 {
  padding: 53px !important;
}
.pv53 {
  padding-top: 53px !important;
  padding-bottom: 53px !important;
}
.mv53 {
  margin-top: 53px !important;
  margin-bottom: 53px !important;
}
.ph53 {
  padding-left: 53px !important;
  padding-right: 53px !important;
}
.mh53 {
  margin-left: 53px !important;
  margin-right: 53px !important;
}
.blank53 {
  position: relative;
  overflow: hidden;
  height: 53px !important;
}
.mt54 {
  margin-top: 54px !important;
}
.mr54 {
  margin-right: 54px !important;
}
.mb54 {
  margin-bottom: 54px !important;
}
.ml54 {
  margin-left: 54px !important;
}
.mg54 {
  margin: 54px !important;
}
.pt54 {
  padding-top: 54px !important;
}
.pr54 {
  padding-right: 54px !important;
}
.pb54 {
  padding-bottom: 54px !important;
}
.pl54 {
  padding-left: 54px !important;
}
.pg54 {
  padding: 54px !important;
}
.pv54 {
  padding-top: 54px !important;
  padding-bottom: 54px !important;
}
.mv54 {
  margin-top: 54px !important;
  margin-bottom: 54px !important;
}
.ph54 {
  padding-left: 54px !important;
  padding-right: 54px !important;
}
.mh54 {
  margin-left: 54px !important;
  margin-right: 54px !important;
}
.blank54 {
  position: relative;
  overflow: hidden;
  height: 54px !important;
}
.mt55 {
  margin-top: 55px !important;
}
.mr55 {
  margin-right: 55px !important;
}
.mb55 {
  margin-bottom: 55px !important;
}
.ml55 {
  margin-left: 55px !important;
}
.mg55 {
  margin: 55px !important;
}
.pt55 {
  padding-top: 55px !important;
}
.pr55 {
  padding-right: 55px !important;
}
.pb55 {
  padding-bottom: 55px !important;
}
.pl55 {
  padding-left: 55px !important;
}
.pg55 {
  padding: 55px !important;
}
.pv55 {
  padding-top: 55px !important;
  padding-bottom: 55px !important;
}
.mv55 {
  margin-top: 55px !important;
  margin-bottom: 55px !important;
}
.ph55 {
  padding-left: 55px !important;
  padding-right: 55px !important;
}
.mh55 {
  margin-left: 55px !important;
  margin-right: 55px !important;
}
.blank55 {
  position: relative;
  overflow: hidden;
  height: 55px !important;
}
.mt56 {
  margin-top: 56px !important;
}
.mr56 {
  margin-right: 56px !important;
}
.mb56 {
  margin-bottom: 56px !important;
}
.ml56 {
  margin-left: 56px !important;
}
.mg56 {
  margin: 56px !important;
}
.pt56 {
  padding-top: 56px !important;
}
.pr56 {
  padding-right: 56px !important;
}
.pb56 {
  padding-bottom: 56px !important;
}
.pl56 {
  padding-left: 56px !important;
}
.pg56 {
  padding: 56px !important;
}
.pv56 {
  padding-top: 56px !important;
  padding-bottom: 56px !important;
}
.mv56 {
  margin-top: 56px !important;
  margin-bottom: 56px !important;
}
.ph56 {
  padding-left: 56px !important;
  padding-right: 56px !important;
}
.mh56 {
  margin-left: 56px !important;
  margin-right: 56px !important;
}
.blank56 {
  position: relative;
  overflow: hidden;
  height: 56px !important;
}
.mt57 {
  margin-top: 57px !important;
}
.mr57 {
  margin-right: 57px !important;
}
.mb57 {
  margin-bottom: 57px !important;
}
.ml57 {
  margin-left: 57px !important;
}
.mg57 {
  margin: 57px !important;
}
.pt57 {
  padding-top: 57px !important;
}
.pr57 {
  padding-right: 57px !important;
}
.pb57 {
  padding-bottom: 57px !important;
}
.pl57 {
  padding-left: 57px !important;
}
.pg57 {
  padding: 57px !important;
}
.pv57 {
  padding-top: 57px !important;
  padding-bottom: 57px !important;
}
.mv57 {
  margin-top: 57px !important;
  margin-bottom: 57px !important;
}
.ph57 {
  padding-left: 57px !important;
  padding-right: 57px !important;
}
.mh57 {
  margin-left: 57px !important;
  margin-right: 57px !important;
}
.blank57 {
  position: relative;
  overflow: hidden;
  height: 57px !important;
}
.mt58 {
  margin-top: 58px !important;
}
.mr58 {
  margin-right: 58px !important;
}
.mb58 {
  margin-bottom: 58px !important;
}
.ml58 {
  margin-left: 58px !important;
}
.mg58 {
  margin: 58px !important;
}
.pt58 {
  padding-top: 58px !important;
}
.pr58 {
  padding-right: 58px !important;
}
.pb58 {
  padding-bottom: 58px !important;
}
.pl58 {
  padding-left: 58px !important;
}
.pg58 {
  padding: 58px !important;
}
.pv58 {
  padding-top: 58px !important;
  padding-bottom: 58px !important;
}
.mv58 {
  margin-top: 58px !important;
  margin-bottom: 58px !important;
}
.ph58 {
  padding-left: 58px !important;
  padding-right: 58px !important;
}
.mh58 {
  margin-left: 58px !important;
  margin-right: 58px !important;
}
.blank58 {
  position: relative;
  overflow: hidden;
  height: 58px !important;
}
.mt59 {
  margin-top: 59px !important;
}
.mr59 {
  margin-right: 59px !important;
}
.mb59 {
  margin-bottom: 59px !important;
}
.ml59 {
  margin-left: 59px !important;
}
.mg59 {
  margin: 59px !important;
}
.pt59 {
  padding-top: 59px !important;
}
.pr59 {
  padding-right: 59px !important;
}
.pb59 {
  padding-bottom: 59px !important;
}
.pl59 {
  padding-left: 59px !important;
}
.pg59 {
  padding: 59px !important;
}
.pv59 {
  padding-top: 59px !important;
  padding-bottom: 59px !important;
}
.mv59 {
  margin-top: 59px !important;
  margin-bottom: 59px !important;
}
.ph59 {
  padding-left: 59px !important;
  padding-right: 59px !important;
}
.mh59 {
  margin-left: 59px !important;
  margin-right: 59px !important;
}
.blank59 {
  position: relative;
  overflow: hidden;
  height: 59px !important;
}
.mt60 {
  margin-top: 60px !important;
}
.mr60 {
  margin-right: 60px !important;
}
.mb60 {
  margin-bottom: 60px !important;
}
.ml60 {
  margin-left: 60px !important;
}
.mg60 {
  margin: 60px !important;
}
.pt60 {
  padding-top: 60px !important;
}
.pr60 {
  padding-right: 60px !important;
}
.pb60 {
  padding-bottom: 60px !important;
}
.pl60 {
  padding-left: 60px !important;
}
.pg60 {
  padding: 60px !important;
}
.pv60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.mv60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.ph60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.mh60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
.blank60 {
  position: relative;
  overflow: hidden;
  height: 60px !important;
}
/*1-50循环end*/
.social-share {
  font-family: "icon" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.social-share * {
  font-family: "icon" !important;
}
.social-share a {
  position: relative;
  text-decoration: none;
  margin: 4px;
  display: inline-block;
  outline: none;
}
.social-share .social-share-icon {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 16px;
  font-style: normal;
  border-radius: 50%;
  line-height: 32px;
  border: 1px solid #666;
  color: #666;
  text-align: center;
  vertical-align: middle;
  transition: background 0.6s ease-out 0s;
}
.social-share .social-share-icon:hover {
  background: #666;
  color: #fff;
}
.social-share .social-share-icon.icon-wechat::before {
  content: "\e697" !important;
}
.social-share .social-share-icon.icon-qq::before {
  content: "\e6bd" !important;
}
.social-share .social-share-icon.icon-weibo::before {
  content: "\e681" !important;
}
.social-share .social-share-icon.icon-qzone::before {
  content: "\e689" !important;
}
.social-share .icon-weibo {
  color: #ff763b;
  border-color: #ff763b;
}
.social-share .icon-weibo:hover {
  background: #ff763b;
}
.social-share .icon-tencent {
  color: #56b6e7;
  border-color: #56b6e7;
}
.social-share .icon-tencent:hover {
  background: #56b6e7;
}
.social-share .icon-qq {
  color: #56b6e7;
  border-color: #56b6e7;
}
.social-share .icon-qq:hover {
  background: #56b6e7;
}
.social-share .icon-qzone {
  color: #fdbe3d;
  border-color: #fdbe3d;
}
.social-share .icon-qzone:hover {
  background: #fdbe3d;
}
.social-share .icon-douban {
  color: #33b045;
  border-color: #33b045;
}
.social-share .icon-douban:hover {
  background: #33b045;
}
.social-share .icon-linkedin {
  color: #0077b5;
  border-color: #0077b5;
}
.social-share .icon-linkedin:hover {
  background: #0077b5;
}
.social-share .icon-facebook {
  color: #44619d;
  border-color: #44619d;
}
.social-share .icon-facebook:hover {
  background: #44619d;
}
.social-share .icon-google {
  color: #db4437;
  border-color: #db4437;
}
.social-share .icon-google:hover {
  background: #db4437;
}
.social-share .icon-twitter {
  color: #55acee;
  border-color: #55acee;
}
.social-share .icon-twitter:hover {
  background: #55acee;
}
.social-share .icon-diandian {
  color: #307dca;
  border-color: #307dca;
}
.social-share .icon-diandian:hover {
  background: #307dca;
}
.social-share .icon-wechat {
  position: relative;
  color: #7bc549;
  border-color: #7bc549;
}
.social-share .icon-wechat:hover {
  background: #7bc549;
}
.social-share .icon-wechat .wechat-qrcode {
  display: none;
  border: 1px solid #eee;
  position: absolute;
  z-index: 9;
  top: -205px;
  left: -84px;
  width: 200px;
  height: 192px;
  color: #666;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 10px #aaa;
  transition: all 200ms;
  -webkit-tansition: all 350ms;
  -moz-transition: all 350ms;
}
.social-share .icon-wechat .wechat-qrcode.bottom {
  top: 40px;
  left: -84px;
}
.social-share .icon-wechat .wechat-qrcode.bottom:after {
  display: none;
}
.social-share .icon-wechat .wechat-qrcode h4 {
  font-weight: normal;
  height: 26px;
  line-height: 26px;
  font-size: 12px;
  background-color: #f3f3f3;
  margin: 0;
  padding: 0;
  color: #777;
}
.social-share .icon-wechat .wechat-qrcode .qrcode {
  width: 105px;
  margin: 10px auto;
}
.social-share .icon-wechat .wechat-qrcode .qrcode table {
  margin: 0 !important;
}
.social-share .icon-wechat .wechat-qrcode .help p {
  font-weight: normal;
  line-height: 16px;
  padding: 0;
  margin: 0;
}
.social-share .icon-wechat .wechat-qrcode:after {
  content: "";
  position: absolute;
  left: 50%;
  margin-left: -6px;
  bottom: -13px;
  width: 0;
  height: 0;
  border-width: 8px 6px 6px 6px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.social-share .icon-wechat:hover .wechat-qrcode {
  display: block;
}
.upload-modal {
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.upload-modal .ant-modal {
  height: 100%;
  color: #ffffff;
  top: 0;
}
.upload-modal .ant-modal .ant-modal-header {
  background-color: transparent;
  border: none;
}
.upload-modal .ant-modal .ant-modal-header .ant-modal-title {
  color: #ffffff;
  margin-right: 32px;
}
.upload-modal .ant-modal .ant-modal-header .ant-modal-title a {
  color: #ffffff;
}
.upload-modal .ant-modal .ant-modal-header .ant-modal-title a:hover {
  color: #cccccc;
}
.upload-modal .ant-modal .ant-modal-close {
  color: #ffffff;
}
.upload-modal .ant-modal .ant-modal-content {
  height: 100%;
  background-color: transparent;
}
.upload-modal .ant-modal .ant-modal-body {
  max-height: calc(100% - 57px);
  height: calc(100% - 57px);
  background-color: transparent;
}
.reco-upload-item {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
}
.reco-upload-item img {
  display: block;
  max-height: 80vh;
  max-width: 100%;
}
.header-content {
  background-color: #fff;
  color: #666666;
  padding: 11px 0;
  border-top: 4px transparent solid;
  border-image: linear-gradient(-90deg, #68c19d 0%, #5dccbd 25%, #5bc1d3 50%, #4994fe 75%, #4476d8 100%) 1 10;
}
.header-content .user-info {
  font-size: 14px;
}
.header-content .user-info .info-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 12px;
  display: inline-block;
  vertical-align: middle;
}
.header-content .user-info em {
  margin: 0 5px;
}
.header-content .user-info .sign-out {
  color: #666666;
  cursor: pointer;
}
.header-content .user-info .sign-out:hover {
  color: #096dd9;
}
.header-content .user-info.header-login-white > a,
.header-content .user-info.header-login-white > span {
  color: #fff;
}
.header-content .name {
  color: #666666;
  cursor: pointer;
}
.header-content .name:hover {
  color: #096dd9;
}
.header-content .logo img {
  display: block;
}
.header-search .ant-input,
.header-search .ant-btn {
  border-radius: 32px;
}
.header-search .ant-input {
  background-color: #ffffff;
}
.header-search .ant-input-group .ant-input {
  text-align: left;
}
/*个人中心头部*/
.header-top {
  background-color: #f3f3f3;
  height: 40px;
  line-height: 40px;
}
.header-top .back-home {
  float: left;
  font-size: 14px;
  color: #5d5d5d;
}
.header-top .back-home .icon {
  margin-right: 10px;
}
.header-top .back-home:hover {
  color: #096dd9;
}
.header-top .top-nav {
  float: right;
}
.header-top .top-nav a {
  font-size: 14px;
  color: #878787;
}
.header-top .top-nav a:hover {
  color: #096dd9;
}
.header-top .top-nav span {
  margin: 0 10px;
  color: #b7b7b7;
}
.my-header-content {
  background-image: none;
  background-color: #ffffff;
  padding: 20px 0;
  height: 83px;
}
.my-header-content .logo img {
  max-height: 40px;
}
.my-header-content .header-search {
  margin: 2px 0 0 0;
}
.my-header-content .header-search .ant-input {
  border-radius: 4px 0 0 4px;
}
.my-header-content .header-search .ant-btn {
  border-radius: 0 4px 4px 0;
}
.my-content {
  min-height: calc(100vh - 245px);
}
@media (max-width: 1400px) {
  .my-header-content {
    height: 75px;
  }
  .my-content {
    min-height: calc(100vh - 187px);
  }
}
/*个人中心头部end*/
.home-logo {
  display: none;
}
.home-head {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
}
.home-head .nav {
  display: none;
}
.home-head .header-content {
  border-top: 0;
  background-color: rgba(11, 103, 109, 0.1);
}
.home-head .header-content .name,
.home-head .header-content .user-info .sign-out {
  color: #fff;
}
.home-head .h-none {
  display: none;
}
.home-head .logo {
  display: none;
}
.home-head .home-logo {
  display: block;
}
.header-content .search-box {
  transition: all 0.3s;
  width: 100%;
  height: 30px;
  border-radius: 41px;
}
.header-content .search-box i {
  color: #000000;
}
.header-content .search-box .anticon {
  display: none;
}
.guidance-tabs {
  position: relative;
  padding: 15px;
  background-color: #f3f3f3;
  display: flex;
  align-items: center;
  border-radius: 5px;
}
.guidance-tabs .title {
  display: inline-block;
  color: #333;
  margin-right: 5px;
}
.guidance-tabs .bd {
  flex: 1;
}
.guidance-tabs-result {
  position: relative;
  display: block;
  width: 553px;
  height: 111px;
  background: url("../images/activity2.jpg") no-repeat center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: zoomIn;
}
.guidance-tabs-result .text1 {
  position: absolute;
  left: 130px;
  top: 29px;
  font-size: 18px;
  color: #3173b5;
}
.guidance-tabs-result .text1 span {
  font-size: 22px;
  font-weight: bold;
}
.guidance-tabs-result .text2 {
  position: absolute;
  left: 130px;
  top: 80px;
  font-size: 16px;
  color: #3173b5;
}
.path-content {
  position: relative;
  width: 950px;
  margin: 0px auto;
}
.path-content .path-bd {
  position: relative;
}
.path-content .path-bd .first-img {
  position: absolute;
  right: -15px;
  top: 48px;
  display: none;
}
.path-content .path-bd .second-img {
  position: absolute;
  top: 243px;
  left: -15px;
  display: none;
}
.path-content .path-bd .three-img {
  position: absolute;
  right: -15px;
  top: 438px;
  display: none;
}
.path-content .path-bd .four-img {
  position: absolute;
  top: 633px;
  left: -15px;
  display: none;
}
.path-content .path-bd .five-img {
  position: absolute;
  top: 828px;
  left: -15px;
  display: none;
}
.path-content .path-bd .six-img {
  position: absolute;
  top: 1023px;
  left: -15px;
  display: none;
}
.path-content .path-bd .seven-img {
  position: absolute;
  top: 1218px;
  left: -15px;
  display: none;
}
.path-content .path-bd.three-more .first-img {
  display: block;
}
.path-content .path-bd.six-more .first-img {
  display: block;
}
.path-content .path-bd.six-more .second-img {
  display: block;
}
.path-content .path-bd.nine-more .first-img {
  display: block;
}
.path-content .path-bd.nine-more .second-img {
  display: block;
}
.path-content .path-bd.nine-more .three-img {
  display: block;
}
.path-content .path-bd.nine-more .four-img {
  display: block;
}
.path-content .path-bd.nine-more .five-img {
  display: block;
}
.path-content .path-bd.nine-more .six-img {
  display: block;
}
.path-content .path-bd.nine-more .seven-img {
  display: block;
}
.path-list {
  position: relative;
  margin: 30px 0 0 0;
  padding: 0 46px;
  z-index: 1;
  list-style: none;
}
.path-list:after {
  content: "";
  display: table;
  clear: both;
}
.path-list li {
  position: relative;
  float: left;
  width: 285px;
  height: 195px;
}
.path-list li .bd {
  position: relative;
  z-index: 1;
  display: block;
  margin: 0 10px;
  text-align: center;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-name: fadeInLeft;
}
.path-list li:nth-of-type(2) .bd {
  animation-delay: 0.15s;
}
.path-list li:nth-of-type(3) .bd {
  animation-delay: 0.3s;
}
.path-list li:nth-of-type(4) .bd {
  animation-name: fadeInRight;
  animation-delay: 0.45s;
}
.path-list li:nth-of-type(5) .bd {
  animation-name: fadeInRight;
  animation-delay: 0.6s;
}
.path-list li:nth-of-type(6) .bd {
  animation-name: fadeInRight;
  animation-delay: 0.75s;
}
.path-list li:nth-of-type(7) .bd {
  animation-delay: 0.9s;
}
.path-list li:nth-of-type(8) .bd {
  animation-delay: 1.05s;
}
.path-list li:nth-of-type(9) .bd {
  animation-delay: 1.2s;
}
.path-list li:nth-of-type(10) .bd {
  animation-name: fadeInRight;
  animation-delay: 1.35s;
}
.path-list li:nth-of-type(11) .bd {
  animation-name: fadeInRight;
  animation-delay: 1.5s;
}
.path-list li:nth-of-type(12) .bd {
  animation-name: fadeInRight;
  animation-delay: 1.65s;
}
.path-list li:nth-of-type(4),
.path-list li:nth-of-type(5),
.path-list li:nth-of-type(6),
.path-list li:nth-of-type(10),
.path-list li:nth-of-type(11),
.path-list li:nth-of-type(12) {
  float: right;
}
.path-list li:nth-of-type(4) .arrow,
.path-list li:nth-of-type(5) .arrow,
.path-list li:nth-of-type(10) .arrow,
.path-list li:nth-of-type(11) .arrow {
  right: auto;
  left: 0;
  background: url(../images/activity2.jpg) no-repeat center center;
}
.path-list li:nth-of-type(3n) .arrow,
.path-list li:last-child .arrow {
  display: none;
}
.path-list .tit {
  font-size: 18px;
}
.path-list .nmb {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #3586f4;
  border: 7px #d6e8ff solid;
  margin: 6px 0;
}
.path-list p {
  text-align: left;
  color: #858585;
  padding: 0 10px;
  margin: 0 0 5px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
}
.path-list p:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #36c8c1;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
.path-list .line {
  position: absolute;
  top: 48px;
  left: 0;
  right: 0;
  display: block;
  height: 3px;
  background-color: #c4e3f8;
}
.path-list .line.end-line {
  width: 50%;
}
.path-list .arrow {
  position: absolute;
  right: 0;
  top: 45px;
  display: inline-block;
  width: 6px;
  height: 10px;
  background: url(../images/activity2.jpg) no-repeat center center;
}
.path-list .jiedian {
  position: absolute;
  left: -52px;
  top: 29px;
  display: inline-block;
  width: 100px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  font-size: 16px;
  color: #fff;
  background-color: #36c8c1;
  z-index: 1;
  border-radius: 3px;
}
.path-list .jiedian.type2 {
  background-color: #1f71e0;
}
.path-list .moredian {
  display: inline-block;
  cursor: pointer;
  line-height: 1;
  position: relative;
  top: -5px;
}
.path-list .moredian i {
  font-size: 24px;
  color: #1f71e0;
}
.morebox {
  position: relative;
}
.morebox .textp {
  padding: 3px 0;
}
.morebox .textp:before {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #36c8c1;
  margin-right: 5px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}
/* 首页 */
.home-policy-box {
  position: relative;
  width: 100%;
  height: 144px;
  padding: 25px;
}
.home-policy-box.list-one {
  background: url("../images/activity2.jpg") no-repeat;
  background-size: 100% 100%;
}
.home-policy-box.list-one .btn a {
  background: linear-gradient(180deg, #2ce6ec 0%, #00bee6 100%);
  box-shadow: 0px 5px 13px 0px rgba(0, 255, 211, 0.3);
}
.home-policy-box.list-two {
  background: url("../images/activity2.jpg") no-repeat;
  background-size: 100% 100%;
}
.home-policy-box.list-two .btn a {
  background: linear-gradient(180deg, #ffc447 0%, #ffa500 100%);
  box-shadow: 0px 5px 15px 0px rgba(255, 133, 0, 0.3);
}
.home-policy-box .title {
  font-size: 22px;
  color: #333333;
}
.home-policy-box .text {
  font-size: 16px;
  color: #a8a8a8;
}
.home-policy-box .btn {
  margin-top: 5px;
}
.home-policy-box .btn a {
  display: inline-block;
  width: 80px;
  height: 26px;
  line-height: 26px;
  text-align: center;
  color: #fff;
  font-size: 12px;
  border-radius: 30px;
}
.home-tabs .ant-list-split .ant-list-item {
  padding: 15px 0;
  border-bottom: dotted 1px #686868;
}
.home-tabs .ant-list-split .ant-list-item .title {
  font-size: 16px;
  color: #595858;
}
.home-tabs .ant-list-split .ant-list-item .ant-tag {
  height: 24px;
  line-height: 22px;
  border-radius: 30px;
  padding: 0 20px;
  margin: 0 4px 4px;
}
.home-tabs .ant-list-split .ant-list-item .ant-tag.ant-tag-blue {
  border-width: 1px;
  border-color: #cde8ef !important;
  border-style: solid;
  color: #47a4e2 !important;
  background-color: #ebfbff;
}
.home-tabs .ant-tabs {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 2px 25px 0px rgba(38, 38, 38, 0.1);
  height: 306px;
}
.home-tabs .ant-tabs .ant-tabs-bar {
  margin: 0;
  padding-left: 140px;
}
.home-tabs .ant-tabs-extra-content {
  padding-top: 15px;
  padding-right: 25px;
  line-height: unset;
  margin-left: 15px;
}
.home-tabs .ant-tabs-extra-content span {
  color: #8f8f8f;
}
.home-tabs .tabs-name {
  font-size: 22px;
  color: #333333;
  position: absolute;
  top: 0;
  padding-left: 25px;
  height: 50px;
  z-index: 1;
  padding-top: 12px;
}
.home-tabs .ant-tabs-nav .ant-tabs-tab {
  margin: 0 35px 0 0;
  padding: 15px 0 0 0;
  height: 50px;
  color: #8f8f8f;
  font-size: 18px;
}
.home-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  color: #1f71e0;
}
.home-tabs .ant-tabs-tab-prev-icon,
.home-tabs .ant-tabs-tab-next-icon {
  margin-top: 5px;
}
.approval-container .approval-header {
  position: relative;
  overflow: hidden;
  height: 42px;
  line-height: 42px;
}
.approval-container .approval-header .approval-header-tag {
  display: inline-block;
  width: 130px;
  height: 42px;
  line-height: 42px;
  background-color: #68c0bb;
  color: #fff;
  font-size: 22px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.approval-container .approval-header .approval-header-tag.type2 {
  background-color: #6b8fe3;
}
.approval-container .approval-header .approval-heade-extra {
  font-size: 16px;
  color: #8f8f8f;
  cursor: pointer;
}
.approval-container .approval-header .approval-heade-extra:hover {
  color: #096dd9;
}
.approval-list {
  text-align: left;
}
.approval-list .ant-list-item {
  padding: 30px 0;
}
.approval-list .ant-list-item-meta-content {
  padding-top: 5px;
}
.approval-list .title {
  font-size: 16px;
  color: #595858;
}
.approval-list .text {
  font-size: 16px;
  color: #949393;
}
.approval-list .infos {
  font-size: 16px;
  color: #6c6c6c;
}
.approval-list .infos i {
  color: #266fd2;
  margin-right: 8px;
}
.rencai-content {
  position: relative;
  height: 562px;
  background-color: #fff;
  box-shadow: 0px 2px 25px 0px rgba(38, 38, 38, 0.1);
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  overflow: hidden;
}
.rencai-content .img-box {
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 0 25px;
}
.rencai-content .img-box span {
  font-size: 22px;
  color: #000;
  line-height: 1.1;
  display: block;
}
.rencai-content .img-box em {
  font-style: normal;
  font-size: 16px;
  color: #999;
}
.rencai-content .list1 .row {
  position: relative;
  margin: 0 20px;
  padding: 15px 0;
  border-bottom: 1px dashed #e5e5e5;
}
.rencai-content .list1 .row:last-child {
  border-bottom: none;
}
.rencai-content .list1 .row .title1 {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  padding-bottom: 5px;
  color: #333;
  cursor: pointer;
}
.rencai-content .list1 .row .title1:hover {
  color: #1f71e0;
}
.rencai-content .list1 .row .title1 .btn1 {
  display: inline-block;
  margin-left: 10px;
  font-size: 13px;
  color: #2fb279;
  border: 1px solid #c2ead4;
  background-color: #ebfff4;
  height: 22px;
  line-height: 20px;
  border-radius: 22px;
  padding: 0 10px;
}
.rencai-content .list1 .row .title1 .btn1.end {
  background-color: #ddd;
  border-color: #ddd;
  color: #ffffff;
}
.rencai-content .list1 .row .title1 .btn1.end.type1 {
  background-color: #096dd9;
  border-color: #096dd9;
}
.rencai-content .list1 .row .title1 .btn1.end.type2 {
  background-color: #ff9a1b;
  border-color: #ff9a1b;
}
.rencai-content .list1 .row .title1 .btn1.end.type3 {
  background-color: #c9c9c9;
  border-color: #c9c9c9;
}
.rencai-content .list1 .row .text1 {
  font-size: 16px;
  color: #8c8686;
}
.rencai-content .list1 .row .text1 i {
  display: inline-block;
  margin-right: 5px;
  font-size: 18px;
  color: #999;
}
.rencai-content .more {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 18px;
  padding: 15px 0;
  text-align: center;
  border-top: 1px solid #e5e5e5;
}
.rencai-content .more a {
  color: #666666;
  cursor: pointer;
}
.rencai-content .more a:hover {
  color: #096dd9;
}
.enter-content {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  overflow: hidden;
  padding: 0 24px;
  height: 406px;
}
.enter-content .hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.enter-content .hd .tit {
  display: inline-block;
  width: 130px;
  height: 42px;
  line-height: 42px;
  background-color: #68c0bb;
  color: #fff;
  font-size: 22px;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.enter-content .hd .tit.blue1 {
  background-color: #6b8fe3;
}
.enter-content .hd .more {
  font-size: 16px;
  cursor: pointer;
}
.enter-content .hd .more:hover {
  color: #1f71e0;
}
.enter-list .ant-row {
  padding: 25px 0;
  border-bottom: 1px dashed #e5e5e5;
}
.enter-list .ant-row:first-child {
  padding-top: 9px;
}
.enter-list .ant-row:last-child {
  border-bottom: none;
}
.enter-list .title {
  font-size: 16px;
  color: #595858;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  padding-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
}
.enter-list .title:hover {
  color: #1f71e0;
}
.enter-list .time {
  padding-top: 20px;
  color: #999;
}
.enter-list .time i {
  display: inline-block;
  margin-right: 5px;
}
.enter-list .more {
  cursor: pointer;
}
.enter-list .more:hover {
  color: #1f71e0;
}
.enter-list .more i {
  display: inline-block;
  margin-left: 5px;
}
.color-555 {
  color: #555;
}
.jiedianposition {
  right: -52px !important;
  left: auto !important;
}
.subscribe-modal {
  min-width: 935px !important;
  max-width: 935px !important;
}
.basic-info-recurit .ant-form-item-children {
  width: 100%;
  display: block;
}
.basic-info-recurit .ant-form-item-children .ant-calendar-picker {
  width: 100%;
  height: 40px;
}
.basic-info-recurit .ant-form-item-children .ant-calendar-picker .ant-calendar-picker-input {
  height: 40px;
}
.basic-info-recurit .ant-select {
  width: 100% !important;
}
.basic-info-recurit .ant-select-selection,
.basic-info-recurit .ant-select-selection__rendered {
  line-height: 40px;
  height: 40px;
}
.info-article-item-btn {
  display: flex;
  justify-content: space-between;
}
.center-info-my .ant-tabs-top-bar {
  display: none;
}
.item-requiredselect label:before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
.recriut-style {
  display: flex;
  justify-content: space-between;
}
.list-myproduct {
  display: flex !important;
  justify-content: space-between;
  width: 100%;
}
.my-sctivity-status {
  display: flex;
  justify-content: space-between;
}
.my-sctivity-status i {
  color: #fff;
  font-style: normal;
  padding: 4px;
}
.list-down-title-cancle {
  display: flex;
  justify-content: space-between;
}
.list-down-title-cancle button {
  height: 25px !important;
  padding: 0 6px !important;
}
/* information-list*/
.information .ant-card-body {
  padding: 20px;
}
.information-infos {
  position: relative;
  overflow: hidden;
  text-align: center;
  margin-bottom: 24px;
}
.information-infos .ant-avatar {
  margin-bottom: 8px;
}
.information-infos .information-name {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.information-detail div i {
  margin-right: 10px;
}
.information-detail div span {
  display: inline-block;
  width: 140px;
  overflow: hidden;
  /*超出部分隐藏*/
  white-space: nowrap;
  /*不换行*/
  text-overflow: ellipsis;
  /*超出部分文字以...显示*/
}
.information-detail div span:hover {
  text-overflow: inherit;
  overflow: visible;
  white-space: pre-line;
  /*合并空白符序列，但是保留换行符。*/
}
.information-detail div em {
  font-style: normal;
  color: #c8c8c8;
}
/* information-list -end*/
/* END 排序用a标签 */
/* 评价tag */
.apply-modal-tag {
  margin: 8px 0 10px 0;
  max-height: 138px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.apply-modal-tag .tag-item {
  min-width: 50%;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding-bottom: 8px;
}
.apply-modal-tag .tag-item:nth-child(odd) {
  padding-right: 4px;
}
.apply-modal-tag .tag-item:nth-child(even) {
  padding-left: 4px;
}
.apply-modal-tag .tag-item.active span {
  background-color: #096dd9;
  color: #ffffff;
}
.apply-modal-tag span {
  background-color: #f2f2f2;
  color: #666;
  padding: 0 6px;
  border-radius: 5px;
  font-size: 14px;
  height: 38px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
}
.guoji-search .ant-select-selection--single {
  height: 40px;
  line-height: 40px !important;
}
.guoji-search .ant-select-selection__rendered {
  line-height: 40px !important;
}
/*END 评价tag */
.card-tag {
  border-radius: 2px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  padding: 2px 8px;
  -webkit-justify-content: center;
  justify-content: center;
  float: left;
  font-size: 14px;
  white-space: nowrap;
}
.margin-right-xs {
  margin-right: 5px !important;
}
.card-tag.tg-color-c {
  background: rgba(74, 190, 189, 0.12);
  color: #4abebd;
}
.step_rate {
  margin-bottom: 0 !important;
}
.step_rate li {
  margin: 0 !important;
  padding: 0 !important;
}
.link-color {
  color: #096dd9 !important;
}
.link-color-red {
  color: red !important;
}
.types_1 .ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-block;
}
.home-nav-page {
  height: 600px;
  background: url("../images/index-banner.jpg") no-repeat center;
  padding-top: 155px;
}
.home-search {
  border-radius: 16px;
  overflow: hidden;
}
.home-search .ant-input-group-addon {
  background-color: #fff;
}
.home-search .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-left: 0;
}
.home-search .ant-select-selection--single .ant-select-selection__rendered {
  margin-right: 34px;
}
.icon-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 124px;
  height: 124px;
  border-radius: 50%;
  cursor: pointer;
}
.icon-nav.type1 {
  background: linear-gradient(-30deg, rgba(43, 105, 255, 0.9), rgba(140, 174, 255, 0.9));
}
.icon-nav.type2 {
  background: linear-gradient(-30deg, rgba(61, 69, 208, 0.9), rgba(112, 115, 240, 0.9));
}
.icon-nav.type3 {
  background: linear-gradient(-30deg, rgba(0, 130, 155, 0.9), rgba(41, 193, 223, 0.9));
}
.icon-nav.type4 {
  background: linear-gradient(-30deg, rgba(0, 156, 103, 0.9), rgba(25, 209, 147, 0.9));
}
.icon-nav.type5 {
  background: linear-gradient(-30deg, rgba(0, 156, 103, 0.9), rgba(74, 129, 10, 0.9), rgba(136, 217, 43, 0.9));
}
.icon-nav.type6 {
  background: linear-gradient(-30deg, rgba(74, 129, 10, 0.9), rgba(144, 129, 18, 0.9), rgba(217, 191, 0, 0.9));
}
.icon-nav.type7 {
  background: linear-gradient(-30deg, rgba(144, 129, 18, 0.9), rgba(144, 75, 18, 0.9), rgba(204, 121, 53, 0.9));
}
.icon-nav.type8 {
  background: linear-gradient(-30deg, rgba(74, 129, 10, 0.9), rgba(168, 66, 62, 0.9), rgba(207, 101, 101, 0.9));
}
.iocnimg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  margin: 0 auto;
}
.anchor {
  position: absolute;
  left: 80px;
  top: 70px;
  z-index: 6;
  transition: all 0.2s;
  background-color: #ffffff;
  box-shadow: 0px 14px 21px 0px rgba(103, 103, 103, 0.14);
  border-radius: 5px;
  overflow: hidden;
}
.anchor.ant-anchor-wrapper {
  margin: 0;
  padding: 0 15px;
}
.anchor .ant-anchor-link {
  border-bottom: #cdcdcd solid 1px;
  font-size: 24px;
  padding: 15px 0;
}
.anchor .ant-anchor-link p {
  margin: 5px 0 0 0;
  font-size: 16px;
}
.anchor .ant-anchor-link:last-child {
  border-bottom: none;
}
.anchor .ant-anchor-ink {
  display: none;
}
.anchor a:hover {
  color: #096dd9;
}
.project-content {
  position: relative;
  margin-top: -113px;
}
.project-content .row {
  position: relative;
  height: 290px;
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 48px;
  padding-top: 50px;
  text-align: center;
  padding: 50px 10px 0 10px;
  overflow: hidden;
}
.project-content .row:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  display: block;
  background-color: #fff;
  border-radius: 0 0 48px 48px;
}
.project-content .row.type1 {
  background: url("../images/iconbg1.png") no-repeat 150px -20px #fff;
}
.project-content .row.type1 .nmb {
  color: #4476d8;
}
.project-content .row.type1 .lable {
  border-color: #4476d8;
  color: #4476d8;
}
.project-content .row.type1:after {
  background-color: #4476d8;
}
.project-content .row.type2 {
  background: url("../images/iconbg2.png") no-repeat 150px -10px #fff;
}
.project-content .row.type2:after {
  background-color: #4994fe;
}
.project-content .row.type2 .nmb {
  color: #4994fe;
}
.project-content .row.type2 .lable {
  border-color: #4994fe;
  color: #4994fe;
}
.project-content .row.type3 {
  background: url("../images/iconbg3.png") no-repeat 150px -10px #fff;
}
.project-content .row.type3:after {
  background-color: #2bb3b1;
}
.project-content .row.type3 .nmb {
  color: #2bb3b1;
}
.project-content .row.type3 .lable {
  border-color: #2bb3b1;
  color: #2bb3b1;
}
.project-content .row.type4 {
  background: url("../images/iconbg4.png") no-repeat 130px -30px #fff;
}
.project-content .row.type4:after {
  background-color: #3dc881;
}
.project-content .row.type4 .nmb {
  color: #3dc881;
}
.project-content .row.type4 .lable {
  border-color: #3dc881;
  color: #3dc881;
}
.project-content .nmb {
  font-size: 52px;
  line-height: 1;
}
.project-content .text1 {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
  padding: 5px 0;
}
.project-content .title {
  font-size: 26px;
  padding-bottom: 12px;
}
.project-content .lable {
  display: inline-block;
  margin: 4px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
}
.project-content .arrow {
  position: absolute;
  top: 50%;
  right: -18px;
  display: inline-block;
  width: 23px;
  height: 18px;
  background-color: #a4cdfe;
  margin-top: -9px;
  z-index: 1;
}
.project-content .arrow:after {
  content: "";
  position: absolute;
  right: -20px;
  top: -6px;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 20px solid #a4cdfe;
  border-bottom: 15px solid transparent;
}
.project-content .ant-row .ant-col .arrow {
  display: none;
}
.project-content .ant-row .ant-col:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 1px;
  display: inline-block;
  width: 23px;
  height: 18px;
  background-color: #a4cdfe;
  margin-top: -9px;
  z-index: 1;
}
.project-content .ant-row .ant-col:after {
  content: "";
  position: absolute;
  top: 50%;
  right: -29px;
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-left: 30px solid #a4cdfe;
  border-bottom: 15px solid transparent;
  z-index: 1;
  margin-top: -15px;
}
.project-content .ant-row .ant-col:last-child::before,
.project-content .ant-row .ant-col:last-child::after {
  display: none;
}
.space-text {
  width: 88px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-style: italic;
  padding: 0 20px;
}
.space-text.type1 {
  color: #1c4eb0;
  background: url("../images/space1.png") no-repeat center;
}
.space-text.type2 {
  color: #046533;
  background: url("../images/space2.png") no-repeat center;
}
.lable-text {
  display: inline-block;
  margin: 4px;
  border: 1px solid #fff;
  border-radius: 4px;
  font-size: 14px;
  padding: 0 10px;
  height: 30px;
  line-height: 28px;
  font-style: normal;
}
.lable-text.type1 {
  border-color: #4476d8;
  color: #4476d8;
}
.lable-text.type2 {
  border-color: #4994fe;
  color: #4994fe;
}
.lable-text.type3 {
  border-color: #2bb3b1;
  color: #2bb3b1;
}
.lable-text.type4 {
  border-color: #3dc881;
  color: #3dc881;
}
.ant-tabs.my-tabs .ant-tabs-nav::before {
  display: none;
}
.ant-tabs.my-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
  height: 4px;
  border-radius: 0 0 4px 4px;
}
.ant-tabs.my-tabs .ant-tabs-nav-scroll {
  display: flex;
  justify-content: center;
}
.ant-tabs.my-tabs .ant-tabs-bar {
  border-bottom: none;
  width: 100%;
}
body .ant-tabs.my-tabs .ant-tabs-nav .ant-tabs-tab {
  font-size: 18px;
  margin: 0 45px 0 0;
}
body .ant-tabs.my-tabs .ant-tabs-nav {
  width: 100%;
}
.index-page {
  position: relative;
}
.index-page.type1 {
  background: url("../images/iconbg1.png") no-repeat center;
  background-size: cover;
  height: 623px;
}
.index-page.type2 {
  background: url("../images/iconbg2.png") no-repeat center;
  background-size: cover;
  height: 698px;
}
.index-page.type3 {
  background: url("../images/iconbg3.png") no-repeat center;
  background-size: cover;
  height: 1046px;
}
.list-box {
  position: relative;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 10px;
}
.list-box.border-none {
  border: none;
  border-radius: 0;
}
.list-box .img {
  position: relative;
}
.list-box .img .way {
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  color: #fff;
}
.list-box .img .way.blue {
  background-color: #5c97fe;
}
.list-box .img .way.green {
  background-color: #5ac18c;
}
.list-box .img .way.qing {
  background-color: #1cc7d6;
}
.index-title {
  position: relative;
  text-align: center;
  padding-top: 45px;
}
.index-title span {
  position: relative;
  z-index: 1;
  display: block;
  font-size: 36px;
  color: #000;
  line-height: 1;
}
.index-title em {
  position: relative;
  top: -22px;
  display: block;
  font-size: 36px;
  color: #ededed;
  letter-spacing: 15px;
  line-height: 1;
  font-style: normal;
}
.shadw-box {
  background-color: #fff;
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
  border-radius: 32px 0 32px 0;
  padding: 30px 20px;
  height: 191px;
}
.news-img {
  position: relative;
}
.news-img .title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(15, 154, 83, 0.8);
  height: 64px;
  line-height: 64px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
  color: #ffffff;
  padding: 0 30px;
}
.ant-carousel .news-carousel .slick-dots-bottom {
  bottom: 80px;
  text-align: right;
  padding-right: 15px;
}
.ant-carousel .news-carousel .slick-dots-bottom.slick-dots li.slick-active button {
  background: #0fb148;
  opacity: 1;
}
.gray-bottom-border {
  border-bottom: 1px solid #ececec;
}
.recommend-activity {
  margin-top: 40px;
  border-radius: 5px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
  border: #e5e5e5 solid 1px;
}
.recommend-activity .ant-typography a {
  color: #262626;
}
.recommend-activity .ant-typography a:hover {
  color: #096dd9;
}
.recommend-write {
  padding: 25px 30px;
  height: 400px;
  border-left: none;
}
.recommend-title {
  margin-top: 25px;
  font-size: 22px;
  color: #262626;
  line-height: 1.2;
}
.recommend-addr {
  margin-top: 10px;
}
.recommend-addr .icon {
  color: #85a8cb;
  margin-right: 5px;
}
.recommend-time {
  margin-top: 10px;
}
.recommend-time .time-item {
  display: inline-block;
  margin-right: 34px;
  position: relative;
}
.recommend-time .time-item:after {
  content: ":";
  position: absolute;
  top: 19px;
  right: -17px;
  font-size: 14px;
  color: #969696;
}
.recommend-time .time-item .time-number {
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  border-radius: 4px;
  border: #d5d5d5 solid 1px;
  font-size: 24px;
  color: #262626;
  font-weight: bold;
}
.recommend-time .time-item .time-number:last-child {
  margin-right: 0;
}
.recommend-time .time-item .time-write {
  font-size: 14px;
  color: #969696;
  margin-top: 8px;
  text-align: center;
}
.recommend-time .time-item:last-child:after {
  display: none;
}
.recommend-btn {
  margin-top: 15px;
}
.recommend-btn .ant-btn {
  height: 40px;
  line-height: 40px;
  padding: 0 28px;
  font-size: 16px;
  margin-right: 15px;
}
.recommend-btn .ant-btn.sign-btn {
  background-color: #85a8cb;
  color: #ffffff;
  border: none;
}
.recommend-btn .ant-btn.sign-btn:hover {
  background-color: #6e9cc9;
}
.home-policy {
  overflow: visible;
}
.activity-list.ant-list-split .ant-list-item {
  border: #e5e5e5 solid 1px;
  border-radius: 5px;
  margin-bottom: 24px;
  padding: 20px 50px;
}
.activity-list.ant-list-split .ant-list-item:last-child {
  border-bottom: #e5e5e5 solid 1px;
}
.activity-list.ant-list-split .ant-list-item:hover {
  border-color: #096dd9;
}
.activity-list.ant-list-split p {
  margin-bottom: 0;
}
.activity-list.ant-list-split .activity-date {
  font-size: 18px;
  color: #969696;
  text-align: center;
  padding-right: 50px;
  margin-right: 50px;
  position: relative;
}
.activity-list.ant-list-split .activity-date:after {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  height: 70px;
  width: 1px;
  background-color: #d5d5d5;
  transform: translateY(-50%);
}
.activity-list.ant-list-split .activity-date span {
  font-size: 48px;
  color: #85a8cb;
  line-height: 1.2;
}
.activity-list.ant-list-split .ant-list-item-meta-title {
  font-size: 20px;
  color: #262626;
}
.activity-list.ant-list-split .activity-describe {
  margin-top: 20px;
  font-size: 16px;
  color: #666666;
}
.activity-list.ant-list-split .sign-btn.ant-btn {
  height: 40px;
  line-height: 40px;
  padding: 0 35px;
  font-size: 16px;
  background-color: #85a8cb;
  color: #ffffff;
  border: none;
}
.activity-list.ant-list-split .sign-btn.ant-btn:hover {
  background-color: #6e9cc9;
}
.home-ancre {
  position: absolute;
  right: 80px;
  top: 150px;
  width: 72px;
  z-index: 6;
  transition: all 0.2s;
}
.home-ancre ul {
  padding: 0;
  margin: 0;
}
.home-ancre ul li {
  position: relative;
  list-style: none;
  text-align: center;
  width: 72px;
  height: 72px;
  border: #bcd8ff solid 1px;
  background-color: #f5f8fc;
  font-size: 14px;
  margin-bottom: 12px;
  box-shadow: 0px 0px 18px 0px rgba(178, 178, 178, 0.31);
  padding-top: 10px;
}
.home-ancre ul li a {
  color: #1f71e0;
}
.home-ancre ul li i {
  font-size: 28px;
  line-height: 1.2;
}
.home-ancre ul li img {
  height: 28px;
  background: #fff;
  border-radius: 2px;
  border: 1px solid;
}
.home-ancre ul li:last-child {
  margin-bottom: 0;
}
.home-ancre ul li:hover {
  background-color: #1f71e0;
  color: #fff;
}
.home-ancre ul li:hover a {
  color: #fff;
}
.home-ancre.effect {
  right: -40px;
}
.home-ancre ul li:first-child.spin {
  animation: glow 8s ease-in-out infinite;
}
.anchor-btn.ant-anchor-wrapper {
  display: inline-block;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  color: #fff;
  background-color: #1f71e0;
  margin: 0;
}
.anchor-btn.ant-anchor-wrapper .ant-anchor-ink {
  display: none;
}
.anchor-btn.ant-anchor-wrapper .ant-anchor-link {
  padding: 0;
  line-height: 32px;
}
.anchor-btn.ant-anchor-wrapper .ant-anchor-link .ant-anchor-link-title {
  color: #ffffff;
}
.anchor-btn.ant-anchor-wrapper:hover {
  background-color: #4792ed;
}
@media (max-width: 1600px) {
  .home-ancre {
    right: 15px;
    top: 60px;
  }
  .anchor {
    left: 5px;
    top: 0;
    transform: scale(0.8);
  }
  .ant-back-top.home-backTop {
    top: 540px;
    right: 30px;
  }
}
.nmbtext {
  position: absolute;
  right: 0;
  bottom: 0;
  background-color: rgba(31, 60, 139, 0.8);
  height: 56px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  padding: 0 15px;
  min-width: 56px;
  text-align: center;
  line-height: 1.3;
  z-index: 1;
}
.list-box2 {
  position: relative;
  border: 1px solid #f0f0f0;
  padding: 30px;
  border-radius: 6px;
}
.list-box2 .title {
  font-size: 20px;
  line-height: 1;
  color: #000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.list-box2 .text1 {
  font-size: 16px;
  line-height: 1.8;
  color: #949494;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  padding: 10px 0;
}
.list-box2 .time {
  font-size: 16px;
  color: #949494;
}
.list-box2 .time em {
  display: inline-block;
  color: #4994fe;
  margin-left: 10px;
  font-style: normal;
}
/*研究者合作*/
.research-state {
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
  border-radius: 8px;
}
.research-state.state-red {
  background-color: rgba(233, 52, 35, 0.06);
  color: #e93423;
}
.research-state.state-yellow {
  background-color: rgba(233, 190, 35, 0.06);
  color: #ecb51d;
}
.research-state.state-green {
  background-color: rgba(35, 233, 41, 0.06);
  color: #23e92d;
}
.research-state.state-gray {
  background-color: rgba(86, 54, 51, 0.06);
  color: #c3c1c1;
}
.ant-btn.compile-btn {
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
  border-radius: 8px;
  border: none;
}
.custom-divider.ant-divider-horizontal.ant-divider-with-text {
  font-size: 18px;
}
.custom-divider.ant-divider-horizontal.ant-divider-with-text::before,
.custom-divider.ant-divider-horizontal.ant-divider-with-text::after {
  border-color: #e6e6e6;
}
.custom-desc .ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 15px;
}
.custom-desc .ant-descriptions-item-content {
  font-size: 15px;
  color: #000000;
}
.custom-desc p,
.custom-desc div.ant-typography,
.custom-desc .ant-typography p {
  margin: 0;
}
.custom-desc .html-content {
  line-height: inherit;
}
.academic-write {
  font-size: 16px;
  color: #000000;
}
.academic-write p {
  margin: 10px 0;
}
.state-tag {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  padding: 0 10px;
  background-color: rgba(9, 109, 217, 0.1);
  border-radius: 4px;
  color: #096dd9;
  font-size: 13px;
}
.research-table .ant-table {
  font-size: 14px;
  border-color: rgba(9, 109, 217, 0.06);
}
.research-table .ant-table-thead > tr > th {
  height: 60px;
  background-color: rgba(9, 109, 217, 0.06);
}
.research-table .ant-table-thead > tr > th,
.research-table .ant-table-tbody > tr > td {
  padding: 10px 12px;
  border-color: rgba(9, 109, 217, 0.06);
}
.cooperation-table {
  width: 100%;
  border: rgba(9, 109, 217, 0.06) solid 1px;
  border-collapse: collapse;
}
.cooperation-table > tbody > tr > td {
  border: rgba(9, 109, 217, 0.06) solid 1px;
}
.cooperation-table > tbody > tr > td:nth-child(1) {
  background-color: rgba(9, 109, 217, 0.06);
  text-align: center;
  color: #096dd9;
  font-size: 18px;
}
.cooperation-table .ant-descriptions {
  padding: 20px;
}
.cooperation-table .ant-descriptions .ant-descriptions-item-label {
  display: inline-block;
  width: 160px;
}
.add-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: #096dd9;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.add-icon:hover {
  background-color: #4994fe;
}
.delete-icon {
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background-color: #d2d2d2;
  color: #ffffff;
  font-size: 14px;
  text-align: center;
  line-height: 28px;
  cursor: pointer;
}
.delete-icon:hover {
  background-color: #ddd;
}
.custom-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  border-radius: 4px;
}
.custom-form .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 37px;
}
.custom-form .ant-select-single .ant-select-selector .ant-select-selection-item,
.custom-form .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 36px;
}
.custom-form .ant-input {
  border-radius: 4px;
}
.cooperation-form {
  background-color: #f5f5f5;
  margin-bottom: 20px;
  position: relative;
}
.cooperation-form .cooperation-icon {
  position: absolute;
  right: 15px;
  top: 30px;
}
.patent-edit-table {
  border-collapse: collapse;
  font-size: 14px;
  border-color: rgba(9, 109, 217, 0.06);
}
.patent-edit-table thead th {
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid rgba(9, 109, 217, 0.06);
  height: 60px;
  background-color: rgba(9, 109, 217, 0.06);
}
.patent-edit-table tbody td {
  text-align: center;
  padding: 5px;
  border: rgba(9, 109, 217, 0.06) solid 1px;
  vertical-align: initial;
}
.patent-edit-table tbody td:nth-child(1) input {
  text-align: center;
}
.patent-edit-table tbody td .ant-form-item {
  margin-bottom: 0;
}
/*研究者合作end*/
.tag-tabs-item {
  background-color: #e9f2ff;
  font-size: 24px;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
  height: 120px;
  line-height: 120px;
  text-align: center;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.4s;
}
.tag-tabs-item:hover {
  transform: translateY(-2%);
  box-shadow: 0 4px 12px 0 #ddd;
}
.tag-tabs-item.active {
  color: #ffffff;
  font-weight: bold;
  box-shadow: 0 10px 30px 0 rgba(60, 88, 129, 0.51);
}
.custom-tabs .ant-col:nth-child(1) .tag-tabs-item {
  background: url("../images/sw/tabs1.jpg") no-repeat;
  background-size: cover;
}
.custom-tabs .ant-col:nth-child(2) .tag-tabs-item {
  background: url("../images/sw/tabs2.jpg") no-repeat;
  background-size: cover;
}
.custom-tabs .ant-col:nth-child(3) .tag-tabs-item {
  background: url("../images/sw/tabs3.jpg") no-repeat;
  background-size: cover;
}
.projectTab {
  width: 100%;
  border: #e6e6e6 solid 1px;
  text-align: center;
}
.projectTab td {
  border: #e6e6e6 solid 1px;
  padding: 10px 15px;
  height: 30px;
}
.platform .page-components-rows .page-components-rows-label {
  line-height: normal;
}
.platform-desc .ant-descriptions-item-label {
  color: #999;
  font-size: 15px;
}
.platform-desc .ant-descriptions-row > th,
.platform-desc .ant-descriptions-row > td {
  padding-bottom: 5px;
}
.platform-desc .ant-descriptions-item-content {
  font-size: 15px;
}
.popular-content .ant-card-head .ant-card-extra {
  padding: 0 0 16px 0 !important;
}
.platform-list.ant-list .ant-list-item {
  border-bottom: none !important;
  padding: 20px 30px;
  transition: all 0.4s;
}
.platform-list.ant-list .ant-list-item:nth-child(even) {
  background-color: #eff5fe;
}
.platform-list.ant-list .ant-list-item:hover {
  transform: translateY(-1%);
  box-shadow: 0 4px 12px 0 #ddd;
}
.platform-bg {
  background: url("../images/sw/pt-bg.jpg") no-repeat center top;
  background-size: cover;
}
.custom-color1 {
  color: #ff7112 !important;
}
.custom-color2 {
  color: #ff9600 !important;
}
.custom-color3 {
  color: #2c61c8 !important;
}
.tel-span {
  display: inline-block;
  min-width: 120px;
}
.platform-news-card .hot-title {
  padding-left: 12px;
  position: relative;
}
.platform-news-card .hot-title:before {
  position: absolute;
  content: "";
  left: 0;
  top: 7px;
  height: 16px;
  width: 4px;
  background-color: #096dd9;
  border-radius: 2px;
}
.dot-list.ant-list .ant-list-item-meta-title i.dot-rect {
  width: 4px;
  height: 4px;
  background-color: #096dd9;
  display: inline-block;
  vertical-align: 3px;
}
.dot-list.ant-list .ant-list-item-meta-title a {
  color: #333333;
}
.white-page {
  background-color: #ffffff;
  padding: 40px 0;
}
.custom-tag {
  display: inline-flex;
  min-height: 26px;
  align-items: center;
  padding: 0 12px;
  border-radius: 13px;
}
.custom-tag.tag1 {
  background-color: #e5f9ff;
  color: #018eb7;
}
.custom-tag.tag2 {
  background-color: #fffae5;
  color: #ff9b19;
}
a.info-a-btn {
  background-color: #096dd9;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 10px;
  height: 24px;
  line-height: 22px;
  font-size: 12px;
  display: inline-block;
}
a.info-a-btn .icon {
  margin-right: 3px;
}
a.info-a-btn:hover {
  color: #ffffff;
  background-color: #4994fe;
}
.container .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  padding: 0;
}
.spacemap.amap-container {
  z-index: 9 !important;
}
.circle-detail-card .ant-card-head-wrapper {
  align-items: baseline;
}
.circle-detail-card .ant-card-head-wrapper .ant-card-extra {
  padding: 0;
}
.intention-num {
  background-color: rgba(9, 109, 217, 0.06);
  display: flex !important;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #096dd9;
}
.ck-content {
  min-height: 200px;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.full-width {
  width: 100%;
}
.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.blue1 {
  color: blue;
}
.bg-blue1 {
  background-color: blue;
}
.blue2 {
  color: #2289cb;
}
.bg-blue2 {
  background-color: #2289cb;
}
.gray1 {
  color: #AEAEAE;
}
.bg-gray1 {
  background-color: #AEAEAE;
}
.gray2 {
  color: #e7e7e7;
}
.bg-gray2 {
  background-color: #e7e7e7;
}
.gray3 {
  color: #827d7d;
}
.bg-gray3 {
  background-color: #827d7d;
}
.gray4 {
  color: #786e6e;
}
.bg-gray4 {
  background-color: #786e6e;
}
.gray5 {
  color: #c5c5c5;
}
.bg-gray5 {
  background-color: #c5c5c5;
}
.gray6 {
  color: #f9f9f9;
}
.bg-gray6 {
  background-color: #f9f9f9;
}
.gray7 {
  color: #bbb;
}
.bg-gray7 {
  background-color: #bbb;
}
.black1 {
  color: #333;
}
.bg-black1 {
  background-color: #333;
}
.black2 {
  color: #555555;
}
.bg-black2 {
  background-color: #555555;
}
.black3 {
  color: #333;
}
.bg-black3 {
  background-color: #333;
}
.white1 {
  color: #fff;
}
.bg-white1 {
  background-color: #fff;
}
.white2 {
  color: #555555;
}
.bg-white2 {
  background-color: #555555;
}
.white3 {
  color: #333;
}
.bg-white3 {
  background-color: #333;
}
.red1 {
  color: red;
}
.bg-red1 {
  background-color: red;
}
.red2 {
  color: #f20;
}
.bg-red2 {
  background-color: #f20;
}
.red3 {
  color: #f9624b;
}
.bg-red3 {
  background-color: #f9624b;
}
.orange1 {
  color: orange;
}
.bg-orange1 {
  background-color: orange;
}
.orange2 {
  color: #555555;
}
.bg-orange2 {
  background-color: #555555;
}
.orange3 {
  color: #333;
}
.bg-orange3 {
  background-color: #333;
}
.phone-menu {
  background-color: #096dd9;
  color: #ffffff;
  border-radius: 0 4px 4px 0;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  height: 36px;
  width: 20px;
  text-align: center;
  line-height: 36px;
  display: none;
}
@media (max-width: 1200px) {
  .view.container-page.slideInRight {
    animation-name: none;
  }
  html > body {
    min-width: 0;
    -webkit-overflow-scrolling: touch;
  }
  .root {
    overflow-x: hidden;
  }
  .container {
    max-width: none;
    min-width: 0;
  }
  .anchor,
  .home-ancre {
    display: none;
  }
  .home-head {
    z-index: 100;
  }
  .home-head .header-content {
    border-image: none;
    border-bottom: rgba(255, 255, 255, 0.41) solid 1px;
    padding: 5px 15px;
  }
  .home-head .home-logo {
    height: 22px;
  }
  .home-head .home-logo img {
    height: 100%;
  }
  .home-head .head-info a {
    color: #ffffff;
  }
  .home-head .head-info span {
    color: #ffffff;
  }
  .home-head .header-mobile-menu {
    display: none;
  }
  .home-head .second-header .header-row {
    padding-right: 0;
  }
  .head-info * {
    vertical-align: top;
    font-size: 13px;
  }
  .head-info .ant-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .head-info .info-name {
    display: inline-block;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .head-info .margin-right {
    margin-right: 10px !important;
  }
  .head-info .icon {
    font-size: 13px;
    margin-right: 2px !important;
  }
  .head-info .user-info > a .icon,
  .head-info .user-info > a span {
    vertical-align: middle;
  }
  .header-row {
    min-height: 36px !important;
  }
  .home-nav-page {
    padding-top: 70px;
    height: 410px;
  }
  .home-nav-page .ant-col-xs-24 {
    padding: 30px 15px 0 !important;
  }
  .home-nav-page .ant-col-24 {
    padding: 20px 0 !important;
  }
  .home-search {
    border-radius: 8px;
  }
  .home-search .ant-select {
    font-size: 13px;
  }
  .home-search .ant-input-lg {
    font-size: 13px;
    height: 35px;
  }
  .home-search .ant-input-search-large .ant-input-search-button {
    height: 35px;
  }
  .hot-tips {
    margin-top: 12px !important;
    font-size: 13px !important;
  }
  .ant-row.grid-nav {
    margin: 0 -8px !important;
    padding: 0 15px;
  }
  .ant-row.grid-nav .ant-col-xs-6 {
    padding: 5px 8px !important;
  }
  .ant-row.grid-nav .icon-nav {
    width: 74px;
    height: 74px;
    margin: 0 auto;
  }
  .ant-row.grid-nav .iocnimg {
    width: 25px;
    height: 20px;
  }
  .ant-row.grid-nav .iocnimg img {
    max-height: 100%;
  }
  .ant-row.grid-nav .size-18 {
    font-size: 12px !important;
  }
  .project-content {
    margin-top: -40px;
    padding: 0 15px;
  }
  .project-content .ant-row {
    margin: 0 -7px !important;
  }
  .project-content .ant-row .ant-col-xs-12 {
    padding: 0 7px !important;
  }
  .project-content .ant-row .ant-col:before {
    width: 12px;
    height: 10px;
    margin-top: -5px;
  }
  .project-content .ant-row .ant-col:after {
    content: "";
    position: absolute;
    top: 50%;
    right: -15px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-left: 16px solid #a4cdfe;
    border-bottom: 8px solid transparent;
    z-index: 1;
    margin-top: -8px;
  }
  .project-content .ant-row .ant-col:nth-child(2):before {
    top: auto;
    bottom: 10px;
    left: 50%;
    transform: rotate(90deg);
  }
  .project-content .ant-row .ant-col:nth-child(2):after {
    top: auto;
    bottom: -5px;
    left: 50%;
    transform: rotate(90deg);
    margin-left: -2px;
  }
  .project-content .ant-row .ant-col:nth-child(3):before {
    right: -11px;
  }
  .project-content .ant-row .ant-col:nth-child(3):after {
    transform: rotate(180deg);
    right: 1px;
  }
  .project-content .row {
    padding: 18px 5px 0 10px;
    border-radius: 24px;
    height: 180px;
    margin-bottom: 10px;
  }
  .project-content .row:after {
    height: 5px;
    border-radius: 0 0 24px 24px;
  }
  .project-content .row.type1 {
    background-position: 80px -20px;
    background-size: 90px auto;
  }
  .project-content .row.type2 {
    background-position: 110px -10px;
    background-size: 90px auto;
  }
  .project-content .row.type3 {
    background-position: 110px -10px;
    background-size: 90px auto;
  }
  .project-content .row.type4 {
    background-position: 100px -20px;
    background-size: 90px auto;
  }
  .project-content .nmb {
    font-size: 26px;
  }
  .project-content .text1 {
    font-size: 12px;
  }
  .project-content .title {
    font-size: 14px;
  }
  .project-content .lable {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    margin: 0 4px 4px 0;
  }
  .space-text {
    width: 50px;
    height: 42px;
    font-size: 14px;
    padding: 0 6px;
    background-size: 100% auto !important;
    line-height: 1.2;
    text-align: center;
  }
  .ant-row.demand-row {
    padding: 0 15px;
  }
  .ant-row.demand-row > .ant-col {
    margin-bottom: 10px;
  }
  .ant-row.demand-row .ant-row {
    margin: 0 -5px !important;
  }
  .ant-row.demand-row .ant-row .ant-col {
    padding: 0 5px !important;
  }
  .ant-row.demand-row .size-16 {
    font-size: 14px !important;
  }
  .ant-row.demand-row .ant-col-20 .ant-row {
    display: none;
  }
  .ant-row.demand-row .ant-col-20 .ant-row:first-child {
    display: flex;
  }
  .ant-row.demand-row .omit-1 {
    -webkit-line-clamp: 2;
    line-height: 1.3;
  }
  .home-content {
    margin-top: 30px;
  }
  .index-title {
    padding-top: 30px;
  }
  .index-page.type1 {
    background-image: none;
    background-color: #fafafb;
    height: auto;
    padding-bottom: 30px;
  }
  .index-page.type1 .home-content {
    margin-bottom: 0 !important;
  }
  .index-page.type1 .list-box .images-auto {
    height: 90px !important;
  }
  .index-page.type1 .list-box .img .way {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
  }
  .index-page.type1 .list-box .pg20 {
    padding: 8px 10px !important;
  }
  .index-page.type1 .list-box .size-18 {
    font-size: 14px !important;
  }
  .index-page.type1 .list-box .size-16 {
    font-size: 12px !important;
  }
  .index-page.type1 .list-box .pv10 {
    padding-top: 4px !important;
    padding-bottom: 2px !important;
  }
  .index-page.type1 .list-box .lable-text {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    margin: 0 3px 3px 0;
  }
  body .ant-tabs.my-tabs {
    padding: 0 15px;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row {
    margin: 0 -6px !important;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row > .ant-col {
    padding: 0 6px !important;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row.mv40 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row.mv40 .ant-col {
    padding: 0 !important;
  }
  body .ant-tabs.my-tabs .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
    margin-right: 24px;
    padding: 6px 0;
  }
  body .ant-tabs.my-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    height: 2px;
  }
  .index-title span {
    font-size: 18px;
  }
  .index-title em {
    font-size: 18px;
    top: -10px;
    letter-spacing: 10px;
  }
  .home-btn.ant-btn {
    padding: 0;
    height: 30px;
    line-height: 28px;
    font-size: 14px;
    color: #096dd9;
    border-color: #096dd9;
    background-color: transparent;
  }
  #page2 .home-content {
    margin-top: 0;
  }
  #page2 .list-box {
    margin-bottom: 16px;
  }
  #page2 .list-box > .ant-row {
    margin: 0 -6px !important;
  }
  #page2 .list-box > .ant-row > .ant-col {
    padding: 0 6px !important;
  }
  #page2 .list-box .img .way {
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    padding: 0 8px;
  }
  #page2 .list-box .images-auto {
    height: 75px !important;
  }
  #page2 .list-box .size-18 {
    font-size: 14px !important;
  }
  #page2 .list-box .size-16 {
    font-size: 12px !important;
  }
  #page2 .list-box .icon {
    font-size: 12px !important;
  }
  #page2 .list-box .pv10 {
    padding-top: 4px !important;
    padding-bottom: 2px !important;
  }
  #page2 .list-box .lable-text {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    margin: 0 3px 3px 0;
  }
  .index-page.type2 {
    background-color: #edf5f5;
    margin-top: 20px;
    height: auto;
    padding-bottom: 10px;
  }
  .index-page.type2 .index-title em {
    color: rgba(43, 179, 177, 0.3);
  }
  .index-page.type2 .mv40 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .index-page.type2 .home-btn.ant-btn {
    color: #2bb3b1;
    border-color: #2bb3b1;
  }
  .home-demand-wd {
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .home-demand-list {
    padding: 0 9px;
  }
  .home-demand-list.ant-row {
    flex-wrap: nowrap;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .home-demand-list .ant-col-12 {
    display: block;
    flex: 0 0 85%;
    max-width: 85%;
    padding: 0 6px !important;
  }
  .shadw-box {
    padding: 15px 15px;
    height: 140px;
    border-radius: 16px 0 16px 0;
    box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.09);
  }
  .shadw-box .size-20 {
    font-size: 14px !important;
  }
  .shadw-box .size-16 {
    font-size: 13px !important;
    color: #666666;
  }
  .shadw-box .ant-row {
    margin: 0 -5px !important;
  }
  .shadw-box .ant-row .ant-col {
    padding: 0 5px !important;
  }
  .shadw-box .lable-text {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    margin: 0;
    text-align: center;
    display: block;
  }
  .shadw-box .ant-tag {
    width: 100% !important;
  }
  .shadw-box .blank20 {
    height: 15px !important;
  }
  .shadw-box .home-demand-type .size-16 {
    font-size: 12px !important;
  }
  #page4 .home-content {
    margin-top: 0;
  }
  #page4 .mv40 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .ant-carousel .news-carousel {
    margin-bottom: 15px;
  }
  .ant-carousel .news-carousel .news-img .images-auto {
    height: 200px !important;
  }
  .ant-carousel .news-carousel .news-img .images-auto img {
    display: none;
  }
  .ant-carousel .news-carousel .news-img .title {
    height: 38px;
    line-height: 38px;
    font-size: 14px;
    padding: 0 15px;
  }
  .ant-carousel .news-carousel .slick-dots-bottom {
    bottom: 46px;
    left: auto;
    margin: 0;
  }
  .ant-carousel .news-carousel .slick-dots li {
    width: 10px;
    height: 5px;
  }
  .ant-carousel .news-carousel .slick-dots li button {
    height: 5px;
    opacity: 0.6;
  }
  .home-date-box {
    width: 100%;
    height: 50px;
    border-radius: 2px;
  }
  .home-date-box .size-30 {
    font-size: 20px !important;
  }
  .home-date-box .size-14 {
    font-size: 12px !important;
    margin-top: 3px;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row > .ant-col.news-item {
    padding: 15px 6px !important;
  }
  .news-item {
    padding: 15px 0 !important;
    border-bottom: #f9f9f9 solid 1px;
  }
  .news-item:last-child {
    border-bottom: none;
  }
  .news-item > .ant-row {
    margin: 0 -6px !important;
  }
  .news-item > .ant-row > .ant-col {
    padding: 0 6px !important;
  }
  .news-item .size-20 {
    font-size: 14px !important;
  }
  .news-item .size-14.text {
    font-size: 12px !important;
  }
  .news-item.ant-col-20 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row.news-list-row {
    padding-top: 0;
  }
  .news-list-row {
    padding-top: 30px;
  }
  body .index-page.type3 .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row > .ant-col {
    padding: 0 !important;
  }
  body .ant-tabs.my-tabs .ant-tabs-tabpane > .ant-row.recommend-activity {
    margin: 0 !important;
  }
  .index-page.type3 {
    background: url("../images/hd-bg.jpg") no-repeat center top;
    background-size: 100% 360px;
    height: auto;
  }
  .index-page.type3 .recommend-activity .images-auto {
    height: 170px !important;
  }
  .index-page.type3 .recommend-write {
    padding: 10px 15px 15px;
    height: auto;
  }
  .index-page.type3 .recommend-write .size-16 {
    font-size: 12px !important;
  }
  .index-page.type3 .recommend-write .recommend-title {
    margin-top: 8px;
    font-size: 14px;
  }
  .index-page.type3 .recommend-write .recommend-addr {
    margin-top: 5px;
  }
  .index-page.type3 .recommend-write .margin-top {
    margin-top: 10px !important;
  }
  .index-page.type3 .recommend-time {
    margin-top: 5px;
  }
  .index-page.type3 .recommend-time .time-item {
    margin-right: 20px;
  }
  .index-page.type3 .recommend-time .time-item:after {
    top: 8px;
    right: -12px;
  }
  .index-page.type3 .recommend-time .time-item .time-number {
    width: 45px;
    height: 45px;
    line-height: 45px;
    font-size: 16px;
  }
  .index-page.type3 .recommend-time .time-item .time-write {
    font-size: 12px;
  }
  .index-page.type3 .recommend-btn .ant-btn {
    height: 32px;
    line-height: 32px;
    padding: 0 15px;
    font-size: 14px;
  }
  .activity-list.ant-list-split .ant-list-item {
    padding: 10px;
    position: relative;
    line-height: 1.3;
    box-shadow: 0 5px 12px 4px rgba(0, 0, 0, 0.09);
    border: none;
  }
  .activity-list.ant-list-split .activity-date {
    font-size: 12px;
    margin-right: 10px;
    padding-right: 10px;
  }
  .activity-list.ant-list-split .activity-date span {
    font-size: 20px;
  }
  .activity-list.ant-list-split .activity-date:after {
    height: 52px;
  }
  .activity-list.ant-list-split .ant-list-item-meta-title {
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.5;
  }
  .activity-list.ant-list-split .activity-describe {
    margin-top: 6px;
    font-size: 12px;
    line-height: 1.4;
  }
  .activity-list.ant-list-split .activity-describe.ant-row {
    margin: 0 !important;
  }
  .activity-list.ant-list-split .activity-describe.ant-row .ant-col {
    padding: 0 !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .activity-list.ant-list-split .activity-describe .icon {
    font-size: 12px !important;
    margin-right: 2px !important;
  }
  .activity-list.ant-list-split .ant-btn {
    font-size: 12px;
    height: 22px;
    line-height: 20px;
    padding: 0 4px;
  }
  .footer {
    padding: 10px 15px;
    font-size: 12px;
    text-align: left;
  }
  .footer .ant-text-center {
    text-align: left;
  }
  .ant-back-top.home-backTop {
    right: 10px;
    top: auto;
    bottom: 20px;
  }
  .index-header {
    display: block;
  }
  .header-mobile-menu {
    width: 16px;
    height: 48px;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 10px;
    z-index: 102;
    display: block;
    padding-top: 16px;
  }
  .header-mobile-menu em {
    display: block;
    width: 100%;
    height: 2px;
    background: #001529;
    margin-top: 4px;
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .header-mobile-menu :first-child {
    margin-top: 0;
  }
  .second-header {
    height: 50px;
  }
  .second-header .header-content {
    padding: 0 15px;
    border-left-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
    border-top-width: 2px;
    position: relative;
  }
  .second-header .logo {
    height: 22px;
  }
  .second-header .logo img {
    height: 100%;
  }
  .second-header .search-box {
    display: none;
  }
  .second-header .header-row {
    height: 48px;
    padding-right: 20px;
  }
  .second-header.open .header-mobile-menu em:nth-child(1) {
    transform: translateY(6px) rotate(45deg);
  }
  .second-header.open .header-mobile-menu em:nth-child(3) {
    transform: translateY(-6px) rotate(-45deg);
  }
  .second-header.open .header-mobile-menu em:nth-child(2) {
    opacity: 0;
  }
  .second-header.open .nav {
    height: auto;
    opacity: 1;
  }
  .second-header .ant-affix {
    position: static !important;
    width: auto !important;
    height: auto !important;
  }
  .nav {
    height: 0;
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    z-index: 101;
    overflow: hidden;
  }
  .nav a {
    font-size: 14px;
  }
  .nav .ant-menu-item,
  .nav .ant-menu-submenu-title {
    font-size: 14px;
    text-align: left;
    margin: 0;
  }
  .nav .ant-menu-inline .ant-menu-item {
    margin: 0;
  }
  .nav .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
  .nav .ant-menu-inline .ant-menu-submenu-title {
    margin: 0;
  }
  .banner-box {
    height: 170px;
  }
  .banner-box img {
    max-width: 50%;
  }
  .crumb-box {
    padding: 5px 15px;
  }
  .crumb-box .ant-breadcrumb {
    font-size: 13px;
  }
  .page-components .page-components-rows {
    min-height: 52px;
  }
  .page-components .page-components-rows .page-components-rows-label {
    width: 80px;
    line-height: 1.2;
  }
  .page-components .page-components-rows .page-components-rows-label .condition-name {
    font-size: 13px;
  }
  .page-components .page-components-rows::after {
    width: 80px;
  }
  .page-components .page-components-rows .page-components-rows-content {
    max-height: none !important;
    padding: 0 10px;
  }
  .page-components .page-components-rows .page-components-rows-content .page-components-rows-select {
    margin-left: 0;
    padding: 5px 0;
  }
  .page-components .page-components-rows .ant-input-search {
    width: 100% !important;
  }
  .page-components .page-components-rows .ant-input-search .ant-input {
    height: 30px;
    font-size: 13px;
  }
  .page-components .page-components-rows .ant-input-search .ant-btn {
    height: 30px;
  }
  .classify {
    height: 22px;
    line-height: 22px;
    margin: 0 5px 5px 0;
    padding: 0 10px;
    font-size: 12px;
    background: #f5f5f5;
  }
  .page-header {
    margin: 15px 0;
    padding: 0 15px;
  }
  .page-header .ant-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .page-header .ant-btn {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
  .sortBox {
    font-size: 12px;
  }
  .sortBox .sortButton {
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
  }
  .general-list {
    margin: 0 !important;
  }
  .general-list .list-box .img .images-auto {
    height: 180px !important;
  }
  .general-list .list-box .pg20 {
    padding: 12px 15px !important;
  }
  .general-list .list-box .size-18 {
    font-size: 14px !important;
  }
  .general-list .list-box .size-16 {
    font-size: 12px !important;
  }
  .general-list .list-box .icon {
    font-size: 12px;
  }
  .general-list .list-box .lable-text {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    margin: 0 5px 0 0;
    text-align: center;
  }
  .general-list .list-box .nmbtext {
    font-size: 14px;
  }
  .general-list.ant-list {
    padding: 0 15px;
  }
  .general-list.ant-list .ant-card.list-box {
    height: auto !important;
    border-radius: 4px;
  }
  .general-list.ant-list .ant-card.list-box .list-title .ant-typography {
    font-size: 16px;
    margin-bottom: 5px !important;
  }
  .paging,
  .list-paging {
    margin: 15px 0 30px;
  }
  .paging .ant-pagination .ant-pagination-item,
  .list-paging .ant-pagination .ant-pagination-item {
    min-width: 26px;
    height: 26px;
    line-height: 24px;
    margin-right: 5px;
  }
  .paging .ant-pagination .ant-pagination-prev,
  .list-paging .ant-pagination .ant-pagination-prev,
  .paging .ant-pagination .ant-pagination-next,
  .list-paging .ant-pagination .ant-pagination-next,
  .paging .ant-pagination .ant-pagination-jump-prev,
  .list-paging .ant-pagination .ant-pagination-jump-prev,
  .paging .ant-pagination .ant-pagination-jump-next,
  .list-paging .ant-pagination .ant-pagination-jump-next {
    min-width: 26px;
    height: 26px;
    line-height: 24px;
    margin-right: 5px;
  }
  .paging .ant-pagination .ant-pagination-next,
  .list-paging .ant-pagination .ant-pagination-next,
  .paging .ant-pagination .ant-pagination-jump-next,
  .list-paging .ant-pagination .ant-pagination-jump-next {
    margin-right: 0;
  }
  .paging.mt30,
  .list-paging.mt30 {
    margin-top: 15px !important;
  }
  .home-ancre.not-space {
    display: block;
    right: 10px !important;
    top: 225px !important;
  }
  .home-ancre.not-space .ant-btn {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
  .form-divider-text-size .ant-divider-inner-text {
    font-size: 18px;
  }
  .details-html .details-header {
    margin: 0 15px;
  }
  .details-html .details-header .images-auto {
    height: 200px !important;
  }
  .details-html .details-header .ant-card.ant-card-fill {
    margin-left: 0 !important;
  }
  .details-html .details-header h4.ant-typography,
  .details-html .details-header .ant-typography h4 {
    font-size: 16px;
  }
  .details-html .details-header .size-16 {
    font-size: 14px !important;
  }
  .details-html .details-header .size-14 {
    font-size: 13px !important;
  }
  .details-html .details-header .size-14 .mt8 {
    margin-top: 2px !important;
  }
  .details-html .details-header .ant-col-xs-24 {
    margin-left: 0 !important;
  }
  .details-html .details-header .ant-btn {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
  .details-html .details-header .gutter-row:last-child {
    padding-bottom: 15px;
  }
  .details-html .ant-card-head {
    min-height: 0;
  }
  .details-html .ant-card-head .ant-card-head-title {
    font-size: 16px;
    padding: 0 16px 10px 12px !important;
  }
  .details-html .ant-card-head .ant-card-head-title::after {
    top: 6px;
    height: 14px;
  }
  .details-html .ant-card.details-content {
    margin: 0 15px;
  }
  .details-html .ant-card.details-content > .ant-card-body {
    padding: 0 0 10px 0;
  }
  .details-html .ant-card.details-content .list-box {
    border: none;
  }
  .collect-badge {
    margin: 0 5px;
  }
  .collect-badge,
  .share-badge {
    font-size: 12px;
  }
  .collect-badge .icon,
  .share-badge .icon {
    font-size: 12px;
    margin-right: 3px !important;
  }
  body .ant-list-item-meta-title {
    font-size: 16px;
  }
  .list-commonly div.ant-typography,
  .list-commonly .ant-typography p {
    margin-bottom: 0;
  }
  .list-commonly p {
    margin: 0;
  }
  .list-commonly p.pt18 {
    padding-top: 5px !important;
  }
  .ant-card.card-p10 {
    padding: 0 15px;
  }
  .ant-card.card-p10 > .ant-card-body {
    padding: 0 0 12px 0;
  }
  .ant-card.card-p10 .ant-list-item .ant-card-body {
    padding: 10px 12px;
  }
  .list-box1 .ant-list-item:first-child {
    padding-top: 0;
  }
  .list-box1 .title {
    font-size: 16px;
  }
  .list-box1 .size-16 {
    margin-top: 0 !important;
    font-size: 14px !important;
    color: #666666;
  }
  .aptitude-box .ant-col {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-tag {
    margin-right: 5px;
  }
  .hot-list .ant-card-body {
    padding: 0 !important;
  }
  .hot-list .list-box {
    border: none;
  }
  .hot-list .list-box .img .images-auto {
    height: 180px !important;
  }
  .hot-list .list-box .pg20 {
    padding: 12px 15px !important;
  }
  .hot-list .list-box .size-18 {
    font-size: 14px !important;
  }
  .hot-list .list-box .size-16 {
    font-size: 12px !important;
  }
  .hot-list .list-box .icon {
    font-size: 12px;
  }
  .hot-list .list-box .lable-text {
    height: 20px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    margin: 0 5px 0 0;
    text-align: center;
  }
  .hot-list .list-box .nmbtext {
    font-size: 14px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
    line-height: 24px !important;
    font-size: 16px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    width: 24px;
    height: 24px;
    line-height: 24px;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 36px !important;
  }
  .phone-v {
    padding: 0 15px;
  }
  body .ant-list-vertical .ant-list-item-meta-title {
    font-size: 16px;
  }
  .info-article-name {
    font-size: 18px;
  }
  .success-content {
    margin: 30px 0;
  }
  .success-content .success-name {
    font-size: 18px;
  }
  body .ant-card-head {
    font-size: 16px;
  }
  .demand-container .page-components .page-components-rows .page-components-rows-content {
    max-height: none !important;
  }
  .demand-list {
    margin: 15px !important;
  }
  .demand-list:first-child {
    margin-top: 0 !important;
  }
  .demand-list .status .ant-tag {
    margin-right: 0;
  }
  .demand-list .describe-three .ant-typography {
    height: auto;
  }
  .demand-list .demand-title {
    font-size: 16px;
  }
  .demand-list .demand-footer {
    margin: 0 !important;
  }
  .demand-list .demand-footer .demand-button {
    margin-right: 0;
  }
  .demand-list .demand-footer .demand-button.ant-btn {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
  .demand-list .demand-footer .ant-col-21 {
    padding-left: 0 !important;
  }
  .list-box2 {
    padding: 15px;
  }
  .scoll-top .ant-back-top {
    right: 10px;
  }
  .card-tag {
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    font-size: 12px;
  }
  .check-map {
    height: 24px;
    line-height: 20px;
    padding: 0 5px;
    font-size: 12px;
    width: auto;
  }
  .s-mv15 {
    margin: 0 15px !important;
  }
  .popular-content.ant-card {
    padding: 0 15px;
  }
  .demand-details {
    height: auto !important;
    padding-left: 15px !important;
  }
  .expert-list-item .ant-card {
    height: auto !important;
    width: auto !important;
    margin: 0 15px !important;
  }
  .expert-list-item .ant-card-body .expert-name {
    font-size: 16px;
  }
  .expert-list-item .ant-card-body .expert-desc {
    margin-top: 6px;
  }
  .details-html .expert-details {
    padding: 15px !important;
  }
  .details-html .expert-details .images-auto {
    width: 100% !important;
  }
  .details-html .expert-details .expert-main {
    margin-left: 0;
    width: auto;
    height: auto;
    padding-bottom: 28px;
  }
  .details-html .expert-details .expert-main .expert-tags {
    flex-wrap: wrap;
    margin: 15px 0;
  }
  .details-html .expert-details .expert-button {
    right: 20px;
    bottom: 15px;
  }
  .index-price-img i {
    font-size: 13px;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .details-html .details-header .index-price-img,
  .details-html .details-header .price-img {
    min-height: 200px;
  }
  .details-intro-header .rows em {
    margin-right: 5px;
  }
  .ant-list.s-list-horizontal .ant-steps .ant-list-item {
    padding: 15px 0;
  }
  .ant-list.s-list-horizontal.body-v .ant-list-item {
    padding: 12px 0;
  }
  .ant-list.s-list-horizontal .ant-list-item {
    padding: 15px;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta {
    display: block;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta .ant-list-item-meta-title {
    margin: 5px 0;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta .ant-list-item-meta-avatar {
    margin-right: 0;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta .images-auto {
    width: 100% !important;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta-content {
    width: 100%;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta-content .mr30 {
    margin-right: 15px !important;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta-content .icon.mr10 {
    font-size: 14px !important;
    margin-right: 2px !important;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta-content .ant-row.mt12 {
    margin-top: 5px !important;
  }
  .ant-list.s-list-horizontal .ant-list-item-meta-description .ant-tag {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
  }
  .info-comment .ant-avatar-image {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
  }
  .comment-box .comment-submit .ant-btn {
    height: 24px;
    font-size: 13px;
  }
  .info-article-type {
    margin-top: 10px;
  }
  .info-article-type .info-type-date > span {
    display: block;
  }
  .info-type-operate.mt10 {
    margin-top: 0 !important;
  }
  .info-type-operate span {
    margin-left: 8px;
  }
  .info-second-title {
    font-size: 16px;
    color: #000;
  }
  .info-second-title:before {
    width: 4px;
    height: 16px;
    border-radius: 2px;
  }
  .hot-title {
    font-size: 16px;
  }
  .popular-content .ant-card-head .ant-card-head-title {
    font-size: 16px;
    padding: 0 16px 10px 12px !important;
  }
  .custom-table {
    overflow-x: auto;
    width: 100%;
  }
  .custom-table .ant-table {
    min-width: 750px;
    border: none;
    font-size: 14px;
  }
  .custom-table .ant-table-thead > tr > th {
    padding: 10px;
  }
  .custom-table .ant-table-tbody > tr > td {
    padding: 10px;
  }
  .info-details .info-article-type.mt30 {
    margin-top: 10px !important;
  }
  .info-details .info-article-type .info-article-item {
    margin-bottom: 10px;
  }
  .ant-card.info-details {
    margin: 0 15px;
  }
  .info-article.mt46 {
    margin-top: 0 !important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 5px 10px;
    font-size: 14px;
  }
  .info-article-page .ant-typography {
    font-size: 14px;
  }
  .ant-list.circle-list .ant-list-item {
    padding: 15px !important;
  }
  .ant-list.circle-list .ant-list-item .images-auto {
    width: 100px !important;
    height: 80px !important;
  }
  .ant-list.circle-list.ant-list-vertical .ant-list-item-meta-title {
    margin-bottom: 5px;
  }
  .ant-list.circle-list.ant-list-vertical .ant-list-item-extra {
    margin-top: 0;
  }
  .ant-list.circle-list.ant-list-vertical .ant-list-item-extra .ant-btn-sm {
    font-size: 12px;
  }
  .ant-list.circle-list .circle-type {
    width: 100% !important;
  }
  .ant-list.circle-list .ant-list-item-meta-description {
    font-size: 12px;
  }
  .circle-operation .icon {
    font-size: 14px;
  }
  .circle-operation .mr30 {
    margin-right: 15px !important;
  }
  .circle-comment-img .ant-col {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .circle-comment .ant-avatar-image {
    width: 40px !important;
    height: 40px !important;
  }
  .circle-comment .circle-comment.pinglun-box .ant-comment-actions > li:last-child {
    position: static;
    font-size: 13px;
  }
  .pinglun-box .ant-comment-content-author {
    padding-right: 80px;
  }
  .document-list {
    margin: 15px !important;
  }
  .document-list .ant-col {
    padding-top: 0 !important;
  }
  .list-box2 .title {
    font-size: 16px;
  }
  .list-box2 .text1 {
    font-size: 14px;
    padding: 0;
    margin: 5px 0;
  }
  .list-box2 .time {
    font-size: 14px;
  }
  .list-box2 .time em {
    font-style: normal;
  }
  .document-title h2 {
    font-size: 18px;
  }
  .document-title .document-type {
    margin-top: 10px !important;
  }
  .document-title .document-type .ant-col:nth-child(4) {
    margin-top: 10px !important;
  }
  .document-title .document-type .ant-col:nth-child(4) .ant-btn {
    height: 28px;
    line-height: 28px;
    font-size: 12px;
    padding: 0 10px;
  }
  .ant-list.list-ph0 .ant-list-item {
    padding: 15px 0;
  }
  .ant-menu.my-menu + .blank16 {
    height: 5px !important;
  }
  .ant-menu.my-menu .ant-menu-item {
    margin: 0 !important;
  }
  .menu-drawer .ant-drawer-body {
    padding: 15px;
  }
  .ant-affix {
    z-index: 101;
  }
  .ant-affix .second-header .header-content {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .home-head .ant-affix .second-header .header-content {
    background-color: rgba(92, 151, 254, 0.9);
    box-shadow: none;
  }
  .phone-menu {
    display: inline-block;
  }
  body .ant-card-head-tabs .ant-tabs-tab {
    font-size: 16px !important;
  }
  .swiper-img .ant-col-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .swiper-img .ant-col-4 .images-auto {
    height: 90px !important;
  }
  .search-col {
    position: static !important;
    margin-bottom: 15px;
  }
  .ant-typography ul li,
  .ant-typography ol li {
    margin-left: 5px !important;
  }
  .ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
    margin-right: 12px !important;
  }
  .list-loose3 .ant-list-item-meta-title .ant-typography {
    font-size: 16px;
  }
  .agree-modal .ant-modal {
    width: 90% !important;
  }
  .ant-form-item {
    margin-bottom: 16px !important;
  }
  .register-box .ant-checkbox + span {
    font-size: 13px;
  }
  .login-box video {
    display: none;
  }
  .login-box .login-content-box {
    width: 95%;
    z-index: 10;
  }
  .content {
    min-height: calc(100vh - 166px);
  }
  .header-affix .ant-affix {
    z-index: 101;
  }
  .custom-tabs {
    padding: 0 10px;
  }
  .tag-tabs-item {
    font-size: 12px;
    height: 60px;
    line-height: 60px;
    border-radius: 5px;
  }
  .platform-result {
    padding: 0 15px;
  }
  .platform-result.size-18 {
    font-size: 16px !important;
  }
  .platform-row .ant-list {
    margin: 0 15px;
  }
  .platform-row .size-18 {
    font-size: 16px !important;
  }
  .platform-row .size-15 {
    font-size: 13px !important;
  }
  .platform-row.mt15 {
    margin-top: 6px !important;
  }
  .platform-row .ant-list.list-horizontal .ant-list-item {
    padding: 10px 15px;
  }
  .white-page {
    margin: 0 15px !important;
  }
  .ant-card.platform-news-card.card-margin {
    margin: 15px;
  }
}
@media (max-width: 350px) {
  .second-header .logo {
    height: 18px;
  }
  .head-info * {
    font-size: 12px;
  }
  .head-info .info-name {
    width: 90px;
  }
  .head-info .margin-right {
    margin-right: 5px !important;
  }
  .head-info .icon {
    margin-right: 0 !important;
  }
  .ant-row.grid-nav .icon-nav {
    width: 60px;
    height: 60px;
  }
  .second-header .head-info .info-name {
    width: 80px;
  }
}
