.social-share {
    font-family: "icon" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    * {
        font-family: "icon" !important;
    }

    a {
        position: relative;
        text-decoration: none;
        margin: 4px;
        display: inline-block;
        outline: none;
    }

    .social-share-icon {
        position: relative;
        display: inline-block;
        width: 32px;
        height: 32px;
        font-size: 16px;
        font-style: normal;
        border-radius: 50%;
        line-height: 32px;
        border: 1px solid #666;
        color: #666;
        text-align: center;
        vertical-align: middle;
        transition: background 0.6s ease-out 0s;

        &:hover {
            background: #666;
            color: #fff;
        }

        &.icon-wechat {
            &::before {
                content: "\e697" !important;
            }
        }
        &.icon-qq {
            &::before {
                content: "\e6bd" !important;
            }
        }
        &.icon-weibo {
            &::before {
                content: "\e681" !important;
            }
        }
        &.icon-qzone {
            &::before {
                content: "\e689" !important;
            }
        }
    }

    .icon-weibo {
        color: #ff763b;
        border-color: #ff763b;
        &:hover {
            background: #ff763b;
        }
    }
    .icon-tencent {
        color: #56b6e7;
        border-color: #56b6e7;
        &:hover {
            background: #56b6e7;
        }
    }
    .icon-qq {
        color: #56b6e7;
        border-color: #56b6e7;
        &:hover {
            background: #56b6e7;
        }
    }
    .icon-qzone {
        color: #fdbe3d;
        border-color: #fdbe3d;
        &:hover {
            background: #fdbe3d;
        }
    }
    .icon-douban {
        color: #33b045;
        border-color: #33b045;
        &:hover {
            background: #33b045;
        }
    }
    .icon-linkedin {
        color: #0077b5;
        border-color: #0077b5;
        &:hover {
            background: #0077b5;
        }
    }
    .icon-facebook {
        color: #44619d;
        border-color: #44619d;
        &:hover {
            background: #44619d;
        }
    }
    .icon-google {
        color: #db4437;
        border-color: #db4437;
        &:hover {
            background: #db4437;
        }
    }
    .icon-twitter {
        color: #55acee;
        border-color: #55acee;
        &:hover {
            background: #55acee;
        }
    }
    .icon-diandian {
        color: #307dca;
        border-color: #307dca;

        &:hover {
            background: #307dca;
        }
    }
    .icon-wechat {
        position: relative;
        color: #7bc549;
        border-color: #7bc549;
        &:hover {
            background: #7bc549;
        }
    }
    .icon-wechat .wechat-qrcode {
        display: none;
        border: 1px solid #eee;
        position: absolute;
        z-index: 9;
        top: -205px;
        left: -84px;
        width: 200px;
        height: 192px;
        color: #666;
        font-size: 12px;
        text-align: center;
        background-color: #fff;
        box-shadow: 0 2px 10px #aaa;
        transition: all 200ms;
        -webkit-tansition: all 350ms;
        -moz-transition: all 350ms;
    }
    .icon-wechat .wechat-qrcode.bottom {
        top: 40px;
        left: -84px;
        &:after {
            display: none;
        }
    }
    .icon-wechat .wechat-qrcode h4 {
        font-weight: normal;
        height: 26px;
        line-height: 26px;
        font-size: 12px;
        background-color: #f3f3f3;
        margin: 0;
        padding: 0;
        color: #777;
    }
    .icon-wechat .wechat-qrcode .qrcode {
        width: 105px;
        margin: 10px auto;
    }
    .icon-wechat .wechat-qrcode .qrcode table {
        margin: 0 !important;
    }
    .icon-wechat .wechat-qrcode .help p {
        font-weight: normal;
        line-height: 16px;
        padding: 0;
        margin: 0;
    }
    .icon-wechat .wechat-qrcode:after {
        content: "";
        position: absolute;
        left: 50%;
        margin-left: -6px;
        bottom: -13px;
        width: 0;
        height: 0;
        border-width: 8px 6px 6px 6px;
        border-style: solid;
        border-color: #fff transparent transparent transparent;
    }
    .icon-wechat:hover .wechat-qrcode {
        display: block;
    }
}
