@import "themes/default";

.home-nav-page {
    height: 600px;
    background: url("../images/index-banner.jpg") no-repeat center;
    padding-top: 155px;
}
.home-search {
    border-radius: 16px;
    overflow: hidden;
    .ant-input-group-addon {
        background-color: #fff;
    }
    .ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
        border-left: 0;
    }
    .ant-select-selection--single .ant-select-selection__rendered {
        margin-right: 34px;
    }
}
.icon-nav {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    cursor: pointer;
    &.type1 {
        background: linear-gradient(-30deg, rgba(43, 105, 255, 0.9), rgba(140, 174, 255, 0.9));
    }
    &.type2 {
        background: linear-gradient(-30deg, rgba(61, 69, 208, 0.9), rgba(112, 115, 240, 0.9));
    }
    &.type3 {
        background: linear-gradient(-30deg, rgba(0, 130, 155, 0.9), rgba(41, 193, 223, 0.9));
    }
    &.type4 {
        background: linear-gradient(-30deg, rgba(0, 156, 103, 0.9), rgba(25, 209, 147, 0.9));
    }
    &.type5 {
        background: linear-gradient(-30deg, rgba(0, 156, 103, 0.9), rgba(74, 129, 10, 0.9), rgba(136, 217, 43, 0.9));
    }
    &.type6 {
        background: linear-gradient(-30deg, rgba(74, 129, 10, 0.9), rgba(144, 129, 18, 0.9), rgba(217, 191, 0, 0.9));
    }
    &.type7 {
        background: linear-gradient(-30deg, rgba(144, 129, 18, 0.9), rgba(144, 75, 18, 0.9), rgba(204, 121, 53, 0.9));
    }
    &.type8 {
        background: linear-gradient(-30deg, rgba(74, 129, 10, 0.9), rgba(168, 66, 62, 0.9), rgba(207, 101, 101, 0.9));
    }
}
.iocnimg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 40px;
    margin: 0 auto;
}

.anchor {
    position: absolute;
    left: 80px;
    top: 70px;
    z-index: 6;
    transition: all 0.2s;
    background-color: @white-color;
    box-shadow: 0px 14px 21px 0px rgba(103, 103, 103, 0.14);
    border-radius: 5px;
    overflow: hidden;
    &.ant-anchor-wrapper {
        margin: 0;
        padding: 0 15px;
    }
    .ant-anchor-link {
        border-bottom: #cdcdcd solid 1px;
        font-size: 24px;
        padding: 15px 0;
        p {
            margin: 5px 0 0 0;
            font-size: 16px;
        }
        &:last-child {
            border-bottom: none;
        }
    }
    .ant-anchor-ink {
        display: none;
    }
    a:hover {
        color: @primary-color;
    }
}

.project-content {
    position: relative;
    margin-top: -113px;
    .row {
        position: relative;
        height: 290px;
        box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
        border-radius: 48px;
        padding-top: 50px;
        text-align: center;
        padding: 50px 10px 0 10px;
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 8px;
            display: block;
            background-color: #fff;
            border-radius: 0 0 48px 48px;
        }
        &.type1 {
            background: url("../images/iconbg1.png") no-repeat 150px -20px #fff;
            .nmb {
                color: #4476d8;
            }
            .lable {
                border-color: #4476d8;
                color: #4476d8;
            }
            &:after {
                background-color: #4476d8;
            }
        }
        &.type2 {
            background: url("../images/iconbg2.png") no-repeat 150px -10px #fff;
            &:after {
                background-color: #4994fe;
            }
            .nmb {
                color: #4994fe;
            }
            .lable {
                border-color: #4994fe;
                color: #4994fe;
            }
        }
        &.type3 {
            background: url("../images/iconbg3.png") no-repeat 150px -10px #fff;
            &:after {
                background-color: #2bb3b1;
            }
            .nmb {
                color: #2bb3b1;
            }
            .lable {
                border-color: #2bb3b1;
                color: #2bb3b1;
            }
        }
        &.type4 {
            background: url("../images/iconbg4.png") no-repeat 130px -30px #fff;
            &:after {
                background-color: #3dc881;
            }
            .nmb {
                color: #3dc881;
            }
            .lable {
                border-color: #3dc881;
                color: #3dc881;
            }
        }
    }
    .nmb {
        font-size: 52px;
        line-height: 1;
    }
    .text1 {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
        padding: 5px 0;
    }
    .title {
        font-size: 26px;
        padding-bottom: 12px;
    }
    .lable {
        display: inline-block;
        margin: 4px;
        border: 1px solid #fff;
        border-radius: 4px;
        font-size: 14px;
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
    }
    .arrow {
        position: absolute;
        top: 50%;
        right: -18px;
        display: inline-block;
        width: 23px;
        height: 18px;
        background-color: #a4cdfe;
        margin-top: -9px;
        z-index: 1;
        &:after {
            content: "";
            position: absolute;
            right: -20px;
            top: -6px;
            display: inline-block;
            width: 0;
            height: 0;
            border-top: 15px solid transparent;
            border-left: 20px solid #a4cdfe;
            border-bottom: 15px solid transparent;
        }
    }
    .ant-row {
        .ant-col {
            .arrow {
                display: none;
            }
            &:before {
                content: "";
                position: absolute;
                top: 50%;
                right: 1px;
                display: inline-block;
                width: 23px;
                height: 18px;
                background-color: #a4cdfe;
                margin-top: -9px;
                z-index: 1;
            }
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                right: -29px;
                display: inline-block;
                width: 0;
                height: 0;
                border-top: 15px solid transparent;
                border-left: 30px solid #a4cdfe;
                border-bottom: 15px solid transparent;
                z-index: 1;
                margin-top: -15px;
            }
        }
        .ant-col:last-child {
            &::before,
            &::after {
                display: none;
            }
        }
    }
}
.space-text {
    width: 88px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-style: italic;
    padding: 0 20px;
    &.type1 {
        color: #1c4eb0;
        background: url("../images/space1.png") no-repeat center;
    }
    &.type2 {
        color: #046533;
        background: url("../images/space2.png") no-repeat center;
    }
}
.lable-text {
    display: inline-block;
    margin: 4px;
    border: 1px solid #fff;
    border-radius: 4px;
    font-size: 14px;
    padding: 0 10px;
    height: 30px;
    line-height: 28px;
    font-style: normal;
    &.type1 {
        border-color: #4476d8;
        color: #4476d8;
    }
    &.type2 {
        border-color: #4994fe;
        color: #4994fe;
    }
    &.type3 {
        border-color: #2bb3b1;
        color: #2bb3b1;
    }
    &.type4 {
        border-color: #3dc881;
        color: #3dc881;
    }
}
.ant-tabs.my-tabs {
    .ant-tabs-nav {
        &::before {
            display: none;
        }
        .ant-tabs-nav-wrap .ant-tabs-nav-list {
            .ant-tabs-ink-bar {
                height: 4px;
                border-radius: 0 0 4px 4px;
            }
        }
    }

    .ant-tabs-nav-scroll {
        display: flex;
        justify-content: center;
    }
    .ant-tabs-bar {
        border-bottom: none;
        width: 100%;
    }
}
body {
    .ant-tabs {
        &.my-tabs {
            .ant-tabs-nav .ant-tabs-tab {
                font-size: 18px;
                margin: 0 45px 0 0;
            }
            .ant-tabs-nav {
                width: 100%;
            }
        }
    }
}
.index-page {
    position: relative;
    &.type1 {
        background: url("../images/iconbg1.png") no-repeat center;
        background-size: cover;
        height: 623px;
    }
    &.type2 {
        background: url("../images/iconbg2.png") no-repeat center;
        background-size: cover;
        height: 698px;
    }
    &.type3 {
        background: url("../images/iconbg3.png") no-repeat center;
        background-size: cover;
        height: 1046px;
    }
}

.list-box {
    position: relative;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 10px;
    &.border-none {
        border: none;
        border-radius: 0;
    }
    .img {
        position: relative;
        .way {
            position: absolute;
            bottom: 0;
            right: 0;
            display: inline-block;
            height: 30px;
            line-height: 30px;
            padding: 0 15px;
            color: #fff;
            &.blue {
                background-color: #5c97fe;
            }
            &.green {
                background-color: #5ac18c;
            }
            &.qing {
                background-color: #1cc7d6;
            }
        }
    }
}
.index-title {
    position: relative;
    text-align: center;
    padding-top: 45px;
    span {
        position: relative;
        z-index: 1;
        display: block;
        font-size: 36px;
        color: #000;
        line-height: 1;
    }
    em {
        position: relative;
        top: -22px;
        display: block;
        font-size: 36px;
        color: #ededed;
        letter-spacing: 15px;
        line-height: 1;
        font-style: normal;
    }
}

.shadw-box {
    background-color: #fff;
    box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-radius: 32px 0 32px 0;
    padding: 30px 20px;
    height: 191px;
}

.news-img {
    position: relative;
    .title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(15, 154, 83, 0.8);
        height: 64px;
        line-height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 20px;
        color: #ffffff;
        padding: 0 30px;
    }
}
.ant-carousel {
    .news-carousel {
        .slick-dots-bottom {
            bottom: 80px;
            text-align: right;
            padding-right: 15px;
            &.slick-dots li.slick-active button {
                background: #0fb148;
                opacity: 1;
            }
        }
    }
}
.gray-bottom-border {
    border-bottom: 1px solid #ececec;
}

.recommend-activity {
    margin-top: 40px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 5px 12px 4px rgba(0, 0, 0, 0.09);
    border: #e5e5e5 solid 1px;
    .ant-typography a {
        color: #262626;

        &:hover {
            color: @primary-color;
        }
    }
}

.recommend-write {
    padding: 25px 30px;
    height: 400px;

    border-left: none;
}

.recommend-title {
    margin-top: 25px;
    font-size: 22px;
    color: #262626;
    line-height: 1.2;
}

.recommend-addr {
    margin-top: 10px;

    .icon {
        color: #85a8cb;
        margin-right: 5px;
    }
}

.recommend-time {
    margin-top: 10px;

    .time-item {
        display: inline-block;
        margin-right: 34px;
        position: relative;

        &:after {
            content: ":";
            position: absolute;
            top: 19px;
            right: -17px;
            font-size: 14px;
            color: #969696;
        }

        .time-number {
            width: 60px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            border-radius: 4px;
            border: #d5d5d5 solid 1px;
            font-size: 24px;
            color: #262626;
            font-weight: bold;

            &:last-child {
                margin-right: 0;
            }
        }

        .time-write {
            font-size: 14px;
            color: #969696;
            margin-top: 8px;
            text-align: center;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}

.recommend-btn {
    margin-top: 15px;

    .ant-btn {
        height: 40px;
        line-height: 40px;
        padding: 0 28px;
        font-size: 16px;
        margin-right: 15px;

        &.sign-btn {
            background-color: #85a8cb;
            color: @white-color;
            border: none;

            &:hover {
                background-color: #6e9cc9;
            }
        }
    }
}

.home-policy {
    overflow: visible;
}

.activity-list.ant-list-split {
    .ant-list-item {
        border: #e5e5e5 solid 1px;
        border-radius: 5px;
        margin-bottom: 24px;
        padding: 20px 50px;

        &:last-child {
            border-bottom: #e5e5e5 solid 1px;
        }

        &:hover {
            border-color: @primary-color;
        }
    }

    p {
        margin-bottom: 0;
    }

    .activity-date {
        font-size: 18px;
        color: #969696;
        text-align: center;
        padding-right: 50px;
        margin-right: 50px;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 50%;
            height: 70px;
            width: 1px;
            background-color: #d5d5d5;
            transform: translateY(-50%);
        }

        span {
            font-size: 48px;
            color: #85a8cb;
            line-height: 1.2;
        }
    }

    .ant-list-item-meta-title {
        font-size: 20px;
        color: #262626;
    }

    .activity-describe {
        margin-top: 20px;
        font-size: 16px;
        color: @gray-two-color;
    }

    .sign-btn.ant-btn {
        height: 40px;
        line-height: 40px;
        padding: 0 35px;
        font-size: 16px;
        background-color: #85a8cb;
        color: @white-color;
        border: none;

        &:hover {
            background-color: #6e9cc9;
        }
    }
}

.home-ancre {
    position: absolute;
    right: 80px;
    top: 150px;
    width: 72px;
    z-index: 6;
    transition: all 0.2s;
    ul {
        padding: 0;
        margin: 0;
        li {
            position: relative;
            list-style: none;
            text-align: center;
            width: 72px;
            height: 72px;
            border: #bcd8ff solid 1px;
            background-color: #f5f8fc;
            font-size: 14px;
            margin-bottom: 12px;
            box-shadow: 0px 0px 18px 0px rgba(178, 178, 178, 0.31);
            padding-top: 10px;
            a {
                color: #1f71e0;
            }
            i {
                font-size: 28px;
                line-height: 1.2;
            }

            img {
                height: 28px;
                background: #fff;
                border-radius: 2px;
                border: 1px solid;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                background-color: #1f71e0;
                color: #fff;
                a {
                    color: #fff;
                }
            }
        }
    }
    &.effect {
        right: -40px;
    }
}

.home-ancre ul li:first-child.spin {
    animation: glow 8s ease-in-out infinite;
}

.anchor-btn.ant-anchor-wrapper {
    display: inline-block;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    color: #fff;
    background-color: #1f71e0;
    margin: 0;

    .ant-anchor-ink {
        display: none;
    }

    .ant-anchor-link {
        padding: 0;
        line-height: 32px;

        .ant-anchor-link-title {
            color: @white-color;
        }
    }

    &:hover {
        background-color: #4792ed;
    }
}
@media (max-width: 1600px) {
    .home-ancre {
        right: 15px;
        top: 60px;
    }
    .anchor {
        left: 5px;
        top: 0;
        transform: scale(0.8);
    }
    .ant-back-top.home-backTop {
        top: 540px;
        right: 30px;
    }
}

.nmbtext {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: rgba(31, 60, 139, 0.8);
    height: 56px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    padding: 0 15px;
    min-width: 56px;
    text-align: center;
    line-height: 1.3;
    z-index: 1;
}
.list-box2 {
    position: relative;
    border: 1px solid #f0f0f0;
    padding: 30px;
    border-radius: 6px;
    .title {
        font-size: 20px;
        line-height: 1;
        color: #000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .text1 {
        font-size: 16px;
        line-height: 1.8;
        color: #949494;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        padding: 10px 0;
    }
    .time {
        font-size: 16px;
        color: #949494;
        em {
            display: inline-block;
            color: #4994fe;
            margin-left: 10px;
            font-style: normal;
        }
    }
}

/*研究者合作*/
.research-state {
    display: inline-block;
    height: 36px;
    line-height: 36px;
    padding: 0 24px;
    border-radius: 8px;
    &.state-red {
        background-color: rgba(233, 52, 35, 0.06);
        color: #e93423;
    }
    &.state-yellow {
        background-color: rgba(233, 190, 35, 0.06);
        color: #ecb51d;
    }
    &.state-green {
        background-color: rgba(35, 233, 41, 0.06);
        color: #23e92d;
    }
    &.state-gray {
        background-color: rgba(86, 54, 51, 0.06);
        color: #c3c1c1;
    }
}
.ant-btn.compile-btn {
    height: 36px;
    line-height: 36px;
    padding: 0 24px;
    border-radius: 8px;
    border: none;
}
.custom-divider {
    &.ant-divider-horizontal.ant-divider-with-text {
        font-size: 18px;
        &::before,
        &::after {
            border-color: #e6e6e6;
        }
    }
}
.custom-desc {
    .ant-descriptions-item-label {
        color: rgba(0, 0, 0, 0.6);
        font-size: 15px;
    }
    .ant-descriptions-item-content {
        font-size: 15px;
        color: @gray-color;
    }
    p, div.ant-typography, .ant-typography p{
        margin: 0;
    }
    .html-content{
        line-height: inherit;
    }
}
.academic-write {
    font-size: 16px;
    color: @gray-color;
    p {
        margin: 10px 0;
    }
}
.state-tag {
    display: inline-block;
    height: 28px;
    line-height: 28px;
    padding: 0 10px;
    background-color: rgba(9, 109, 217, 0.1);
    border-radius: 4px;
    color: #096dd9;
    font-size: 13px;
}
.research-table {
    .ant-table {
        font-size: 14px;
        border-color: rgba(9, 109, 217, 0.06);
    }
    .ant-table-thead > tr > th {
        height: 60px;
        background-color: rgba(9, 109, 217, 0.06);
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        padding: 10px 12px;
        border-color: rgba(9, 109, 217, 0.06);
    }
}
.cooperation-table {
    width: 100%;
    border: rgba(9, 109, 217, 0.06) solid 1px;
    border-collapse: collapse;
    > tbody > tr > td {
        border: rgba(9, 109, 217, 0.06) solid 1px;
        &:nth-child(1) {
            background-color: rgba(9, 109, 217, 0.06);
            text-align: center;
            color: #096dd9;
            font-size: 18px;
        }
    }
    .ant-descriptions {
        padding: 20px;
        .ant-descriptions-item-label {
            display: inline-block;
            width: 160px;
        }
    }
}
.add-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: @primary-color;
    color: @white-color;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    &:hover {
        background-color: @primaryHover-color;
    }
}
.delete-icon {
    display: inline-block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    background-color: #d2d2d2;
    color: @white-color;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
    cursor: pointer;
    &:hover {
        background-color: #ddd;
    }
}
.custom-form {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 38px;
        border-radius: 4px;
    }
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
        height: 37px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
        line-height: 36px;
    }
    .ant-input {
        border-radius: 4px;
    }
}
.cooperation-form {
    background-color: #f5f5f5;
    // padding: 30px 0 15px;
    margin-bottom: 20px;
    position: relative;
    .cooperation-icon {
        position: absolute;
        right: 15px;
        top: 30px;
    }
}
.patent-edit-table {
    border-collapse: collapse;
    font-size: 14px;
    border-color: rgba(9, 109, 217, 0.06);
    thead {
        th {
            text-align: center;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 500;
            border-bottom: 1px solid rgba(9, 109, 217, 0.06);
            height: 60px;
            background-color: rgba(9, 109, 217, 0.06);
        }
    }
    tbody {
        td {
            text-align: center;
            padding: 5px;
            border: rgba(9, 109, 217, 0.06) solid 1px;
            vertical-align: initial;

            &:nth-child(1) {
                input {
                    text-align: center;
                }
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }
}
/*研究者合作end*/


.tag-tabs-item{
    background-color: rgba(233, 242, 255, 1);
    font-size: 24px;
    position: relative;
    color:rgba(255,255,255,0.8);
    height: 120px;
    line-height: 120px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.4s;
    &:hover{
        transform: translateY(-2%);
        box-shadow: 0 4px 12px 0 #ddd;
    }
    &.active{
        color:@white-color;
        font-weight: bold;
        box-shadow: 0 10px 30px 0 rgba(60, 88, 129, 0.51);
    }
}
.custom-tabs{
    .ant-col{
        &:nth-child(1){
            .tag-tabs-item{
                background: url("../images/sw/tabs1.jpg") no-repeat;
                background-size: cover;
            }
        }
        &:nth-child(2){
            .tag-tabs-item{
                background: url("../images/sw/tabs2.jpg") no-repeat;
                background-size: cover;
            }
        }
        &:nth-child(3){
            .tag-tabs-item{
                background: url("../images/sw/tabs3.jpg") no-repeat;
                background-size: cover;
            }
        }
    }
}

.projectTab{
    width: 100%;
    border: #e6e6e6 solid 1px;
    text-align: center;
    td{
        border: #e6e6e6 solid 1px;
        padding: 10px 15px;
        height: 30px;
    }
}

    .platform .page-components-rows .page-components-rows-label{
        line-height: normal;
    }

.platform-desc{
    .ant-descriptions-item-label{
        color:@gray-three-color;
        font-size: 15px;
    }
    .ant-descriptions-row > th, .ant-descriptions-row > td{
        padding-bottom: 5px;
    }
    .ant-descriptions-item-content{
        font-size: 15px;
    }
}
.popular-content .ant-card-head .ant-card-extra{
    padding: 0 0 16px 0 !important;
}
.platform-list.ant-list{
    .ant-list-item{
        border-bottom: none !important;
        padding: 20px 30px;
        transition: all 0.4s;
        &:nth-child(even){
            background-color: #eff5fe;
        }
        &:hover{
            transform: translateY(-1%);
            box-shadow: 0 4px 12px 0 #ddd;
        }
    }
}
.platform-bg{
    background: url("../images/sw/pt-bg.jpg") no-repeat center top;
    background-size: cover;
}
.custom-color1{
    color:#ff7112 !important;
}
.custom-color2{
    color:#ff9600 !important;
}
.custom-color3{
    color:#2c61c8 !important;
}
.tel-span{
    display: inline-block;
    min-width:120px;
}
.platform-news-card{
    .hot-title{
        padding-left: 12px;
        position: relative;
        &:before{
            position: absolute;
            content: "";
            left: 0;
            top: 7px;
            height: 16px;
            width: 4px;
            background-color: #096dd9;
            border-radius: 2px;
        }
    }
}
.dot-list.ant-list{
    .ant-list-item-meta-title i.dot-rect{
        width: 4px;
        height: 4px;
        background-color: @primary-color;
        display: inline-block;
        vertical-align: 3px;
    }
    .ant-list-item-meta-title a{
        color:@gray-one-color;
    }
}
.white-page{
    background-color: @white-color;
    padding: 40px 0;
}
.custom-tag{
    display: inline-flex;
    min-height: 26px;
    align-items: center;
    padding: 0 12px;
    border-radius: 13px;
    &.tag1{
        background-color: #e5f9ff;
        color:#018eb7;
    }
    &.tag2{
        background-color: #fffae5;
        color:#ff9b19;
    }
}
a.info-a-btn{
    background-color: @primary-color;
    color:@white-color;
    border-radius: 4px;
    padding: 0 10px;
    height: 24px;
    line-height: 22px;
    font-size: 12px;
    display: inline-block;
    .icon{
        margin-right: 3px;
    }
    &:hover{
        color:@white-color;
        background-color:@primaryHover-color;
    }
}
