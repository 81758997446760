

@blue:blue;
@blue1:blue;
@blue2: #2289cb;
@blueList: @blue1,@blue2;

.background-card(@i,@name,@color){
    .@{name}@{i}{
        color: @color;
      }
    .bg-@{name}@{i}{
      background-color: @color;
    }
  }
  .loop(@i,@name,@list) when (@i < (length(@list) + 1)) {
    .background-card(@i,@name,extract(@list, @i));
    .loop(@i + 1,@name,@list);
   }

  .loop(1,@blue,@blueList);


@gray:gray;
@gray1:#AEAEAE;
@gray2: #e7e7e7;
@gray3: #827d7d;
@gray4:#786e6e;
@gray5:#c5c5c5;
@gray6:#f9f9f9;
@gray7:#bbb;
@grayList: @gray1,@gray2,@gray3,@gray4,@gray5,@gray6,@gray7;

.loop(1,@gray,@grayList);


@black:black;
@black1:#333;
@black2: #555555;
@black3: #333;
@blackList: @black1,@black2,@black3;

.loop(1,@black,@blackList);

@white:white;
@white1:#fff;
@white2: #555555;
@white3: #333;
@whiteList: @white1,@white2,@white3;
.loop(1,@white,@whiteList);

@red:red;
@red1:red;
@red2: #f20;
@red3: #f9624b;
@redList: @red1,@red2,@red3;
.loop(1,@red,@redList);

@orange:orange;
@orange1:orange;
@orange2: #555555;
@orange3: #333;
@orangeList: @orange1,@orange2,@orange3;
.loop(1,@orange,@orangeList);



