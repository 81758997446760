@import "themes/default";

.nav {
    background-color: @primary-color;
    color: @nav-color;
    font-size: 12px;

    a {
        color: @white-color;
        text-decoration: none;
        font-size: 18px;
        display: inline-block;
        vertical-align: top;
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected,
    .ant-menu.ant-menu-dark .ant-menu-item-active,
    .ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
    .ant-menu-dark .ant-menu-submenu-active,
    .ant-menu-dark .ant-menu-submenu-open,
    .ant-menu-dark .ant-menu-submenu-selected,
    .ant-menu-dark .ant-menu-submenu-title:hover {
        background-color: #3586f4;
    }

    .ant-menu-dark,
    .ant-menu-dark .ant-menu-sub {
        background-color: transparent;
        color: @white-color;
    }

    .ant-menu-dark .ant-menu-item,
    .ant-menu-dark .ant-menu-item-group-title,
    .ant-menu-dark .ant-menu-item > a {
        color: @white-color;
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        width: 130px;
        text-align: center;
        font-size: 18px;
        color: #fff;
    }
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
    background: @primary-color !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
    background-color: #3586f4 !important;
}

.head-logo {
    height: 32px;
    float: left;
    margin-right: 30px;

    img {
        height: 100%;
    }
}

.header {
    border-bottom: 1px solid #e8e8e8;
    background-color: @white-color;
    transition: all 0.2s;

    .ant-menu-horizontal {
        border-bottom: none;
    }
    .container {
        display: flex;
        align-items: center;

        .ant-menu {
            flex: 1;
        }
    }

    .ant-menu {
        li {
            &:hover {
                & > a {
                    color: #00c1fb !important;
                }

                .ant-menu-submenu-title {
                    color: #00c1fb !important;
                }

                color: #00c1fb !important;
                border-bottom: 2px solid #00c1fb;
            }
        }
    }
}

.global-search {
    width: 250px;

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input:not(:last-child) {
        padding-right: 62px;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix {
        right: 0;
    }

    &.ant-select-auto-complete .ant-input-affix-wrapper .ant-input-suffix button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .global-search-item-count {
        position: absolute;
        right: 16px;
    }
}

.ant-affix {
    .header {
        box-shadow: 4px 4px 5px rgba(0, 0, 0, 0.05);
    }
}

.head-menu {
    position: relative;
    overflow: hidden;
    z-index: 100;
}

/*申报项目*/
.theme-content {
    margin-top: -56px;
    position: relative;
    z-index: 2;

    .ant-menu-dark {
        background-color: @white-color;

        .ant-menu-item:hover {
            color: @primary-color;
        }
    }

    .ant-menu {
        border-radius: 6px;
        box-shadow: 0px 3px 16px 0px rgba(184, 201, 243, 0.6) !important;
        overflow: hidden;
        min-height: 590px;
    }

    .ant-menu-inline {
        .ant-menu-item {
            color: #556a7a;
            font-size: 16px;
            height: 56px;
            line-height: 56px;
            margin: 0;

            .name {
                display: inline-block;
                width: 125px;
            }

            .icon {
                display: inline-block;
                font-size: 20px;
                margin-right: 10px;
                width: 20px;
                text-align: center;
            }

            &:not(:last-child) {
                margin-bottom: 0;
            }
        }
    }

    .ant-menu.ant-menu-dark .ant-menu-item-selected {
        background-color: @primary-color;
        color: @white-color;
    }
}

.logo {
    span {
        font-size: 16px;
        margin-left: 10px;
        padding-left: 10px;
        position: relative;
        line-height: 58px;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 2px;
            height: 30px;
            background-color: #000;
        }
    }
}
.project-content {
    position: relative;
}
