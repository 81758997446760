@import "themes/default";
.footer {
    background-color: #686c73;
    color: rgba(255, 255, 255, 0.54);
    padding: 52px 0;
    line-height: 2;
    .ant-typography {
        color: #ffffff;
    }
    .footer-desc {
        margin-top: 10px;
        font-size: 14px;
    }
    .footer-content {
        margin-right: 30px;
        ul,
        li {
            margin: 0;
            padding: 0 0 5px;
            list-style: none;
        }
        a {
            color: @foot-link-color;
            &:hover {
                color: @foot-link-color-hover;
            }
        }
    }
    .ant-col:last-child {
        .footer-content {
            margin-right: 0;
        }
    }
    .footer-desc {
        p {
            margin-bottom: 0;
            i {
                margin-right: 3px;
                &.icon-phone {
                    margin-right: 6px;
                }
            }
        }
        padding-bottom: 15px;
    }
}
