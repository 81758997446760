// .page-components .page-components-rows .page-components-rows-content {
//     max-height: 120px !important;
// }

.container .ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
    padding: 0;
}

.spacemap.amap-container {
    z-index: 9 !important; // 地图层级低于header
}

.circle-detail-card {
    .ant-card-head-wrapper {
        align-items: baseline;
        .ant-card-extra {
            padding: 0;
        }
    }
}

// 研究者发布-合作意向前面的数字
.intention-num {
    background-color: rgba(9, 109, 217, 0.06);
    display: flex !important;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #096dd9;
}

.ck-content {
    min-height: 200px;
}
