@import "themes/default";

.guidance-tabs {
    position: relative;
    padding: 15px;
    background-color: #f3f3f3;
    display: flex;
    align-items: center;
    border-radius: 5px;

    .title {
        display: inline-block;
        color: #333;
        margin-right: 5px;
    }

    .bd {
        flex: 1;
    }
}

.guidance-tabs-result {
    position: relative;
    display: block;
    width: 553px;
    height: 111px;
    background: url("../images/activity2.jpg") no-repeat center;
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-name: zoomIn;

    .text1 {
        position: absolute;
        left: 130px;
        top: 29px;
        font-size: 18px;
        color: #3173b5;

        span {
            font-size: 22px;
            font-weight: bold;
        }
    }

    .text2 {
        position: absolute;
        left: 130px;
        top: 80px;
        font-size: 16px;
        color: #3173b5;
    }
}

.path-content {
    position: relative;
    width: 950px;
    margin: 0px auto;

    .path-bd {
        position: relative;

        .first-img {
            position: absolute;
            right: -15px;
            top: 48px;
            display: none;
        }

        .second-img {
            position: absolute;
            top: 243px;
            left: -15px;
            display: none;
        }

        .three-img {
            position: absolute;
            right: -15px;
            top: 438px;
            display: none;
        }

        .four-img {
            position: absolute;
            top: 633px;
            left: -15px;
            display: none;
        }

        .five-img {
            position: absolute;
            top: 828px;
            left: -15px;
            display: none;
        }

        .six-img {
            position: absolute;
            top: 1023px;
            left: -15px;
            display: none;
        }

        .seven-img {
            position: absolute;
            top: 1218px;
            left: -15px;
            display: none;
        }

        &.three-more {
            .first-img {
                display: block;
            }
        }

        &.six-more {
            .first-img {
                display: block;
            }

            .second-img {
                display: block;
            }
        }

        &.nine-more {
            .first-img {
                display: block;
            }

            .second-img {
                display: block;
            }

            .three-img {
                display: block;
            }

            .four-img {
                display: block;
            }

            .five-img {
                display: block;
            }

            .six-img {
                display: block;
            }

            .seven-img {
                display: block;
            }
        }
    }
}

.path-list {
    position: relative;
    margin: 30px 0 0 0;
    padding: 0 46px;
    z-index: 1;
    list-style: none;

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    li {
        position: relative;
        float: left;
        width: 285px;
        height: 195px;

        .bd {
            position: relative;
            z-index: 1;
            display: block;
            margin: 0 10px;
            text-align: center;
            animation-duration: 0.5s;
            animation-fill-mode: both;
            animation-name: fadeInLeft;
        }

        &:nth-of-type(2) {
            .bd {
                animation-delay: 0.15s;
            }
        }

        &:nth-of-type(3) {
            .bd {
                animation-delay: 0.3s;
            }
        }

        &:nth-of-type(4) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 0.45s;
            }
        }

        &:nth-of-type(5) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 0.6s;
            }
        }

        &:nth-of-type(6) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 0.75s;
            }
        }

        &:nth-of-type(7) {
            .bd {
                animation-delay: 0.9s;
            }
        }

        &:nth-of-type(8) {
            .bd {
                animation-delay: 1.05s;
            }
        }

        &:nth-of-type(9) {
            .bd {
                animation-delay: 1.2s;
            }
        }

        &:nth-of-type(10) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 1.35s;
            }
        }

        &:nth-of-type(11) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 1.5s;
            }
        }

        &:nth-of-type(12) {
            .bd {
                animation-name: fadeInRight;
                animation-delay: 1.65s;
            }
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(6),
        &:nth-of-type(10),
        &:nth-of-type(11),
        &:nth-of-type(12) {
            float: right;
        }

        &:nth-of-type(4),
        &:nth-of-type(5),
        &:nth-of-type(10),
        &:nth-of-type(11) {
            .arrow {
                right: auto;
                left: 0;
                background: url(../images/activity2.jpg) no-repeat center center;
            }
        }

        &:nth-of-type(3n),
        &:last-child {
            .arrow {
                display: none;
            }
        }
    }

    .tit {
        font-size: 18px;
    }

    .nmb {
        position: relative;
        z-index: 1;
        display: inline-block;
        width: 30px;
        height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #3586f4;
        border: 7px #d6e8ff solid;
        margin: 6px 0;
    }

    p {
        text-align: left;
        color: #858585;
        padding: 0 10px;
        margin: 0 0 5px 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 15px;

        &:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #36c8c1;
            margin-right: 5px;
            vertical-align: middle;
            position: relative;
            top: -1px;
        }
    }

    .line {
        &.end-line {
            width: 50%;
        }

        position: absolute;
        top: 48px;
        left: 0;
        right: 0;
        display: block;
        height: 3px;
        background-color: #c4e3f8;
    }

    .arrow {
        position: absolute;
        right: 0;
        top: 45px;
        display: inline-block;
        width: 6px;
        height: 10px;
        background: url(../images/activity2.jpg) no-repeat center center;
    }

    .jiedian {
        position: absolute;
        left: -52px;
        top: 29px;
        display: inline-block;
        width: 100px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        color: #fff;
        background-color: #36c8c1;
        z-index: 1;
        border-radius: 3px;

        &.type2 {
            background-color: #1f71e0;
        }
    }

    .moredian {
        display: inline-block;
        cursor: pointer;
        line-height: 1;
        position: relative;
        top: -5px;

        i {
            font-size: 24px;
            color: #1f71e0;
        }
    }
}

.morebox {
    position: relative;

    .textp {
        padding: 3px 0;

        &:before {
            content: "";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: #36c8c1;
            margin-right: 5px;
            vertical-align: middle;
            position: relative;
            top: -1px;
        }
    }
}

/* 首页 */
.home-policy-box {
    position: relative;
    width: 100%;
    height: 144px;
    padding: 25px;

    &.list-one {
        background: url("../images/activity2.jpg") no-repeat;
        background-size: 100% 100%;

        .btn {
            a {
                background: linear-gradient(180deg, rgb(44, 230, 236) 0%, rgb(0, 190, 230) 100%);
                box-shadow: 0px 5px 13px 0px rgba(0, 255, 211, 0.3);
            }
        }
    }

    &.list-two {
        background: url("../images/activity2.jpg") no-repeat;
        background-size: 100% 100%;

        .btn {
            a {
                background: linear-gradient(180deg, rgb(255, 196, 71) 0%, rgb(255, 165, 0) 100%);
                box-shadow: 0px 5px 15px 0px rgba(255, 133, 0, 0.3);
            }
        }
    }

    .title {
        font-size: 22px;
        color: #333333;
    }

    .text {
        font-size: 16px;
        color: #a8a8a8;
    }

    .btn {
        margin-top: 5px;

        a {
            display: inline-block;
            width: 80px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            color: #fff;
            font-size: 12px;
            border-radius: 30px;
        }
    }
}

.home-tabs {
    .ant-list-split .ant-list-item {
        padding: 15px 0;
        border-bottom: dotted 1px #686868;

        .title {
            font-size: 16px;
            color: #595858;
        }

        .ant-tag {
            height: 24px;
            line-height: 22px;
            border-radius: 30px;
            padding: 0 20px;
            margin: 0 4px 4px;

            &.ant-tag-blue {
                border-width: 1px;
                border-color: #cde8ef !important;
                border-style: solid;
                color: #47a4e2 !important;
                background-color: rgb(235, 251, 255);
            }
        }
    }

    .ant-tabs {
        border: 1px solid #e5e5e5;
        border-radius: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 2px 25px 0px rgba(38, 38, 38, 0.1);
        height: 306px;

        .ant-tabs-bar {
            margin: 0;
            padding-left: 140px;
        }
    }

    .ant-tabs-extra-content {
        padding-top: 15px;
        padding-right: 25px;
        line-height: unset;
        margin-left: 15px;

        span {
            color: #8f8f8f;
        }
    }

    .tabs-name {
        font-size: 22px;
        color: #333333;
        position: absolute;
        top: 0;
        padding-left: 25px;
        height: 50px;
        z-index: 1;
        padding-top: 12px;
    }

    .ant-tabs-nav .ant-tabs-tab {
        margin: 0 35px 0 0;
        padding: 15px 0 0 0;
        height: 50px;
        color: #8f8f8f;
        font-size: 18px;

        &.ant-tabs-tab-active {
            color: #1f71e0;
        }
    }

    .ant-tabs-tab-prev-icon,
    .ant-tabs-tab-next-icon {
        margin-top: 5px;
    }
}

.approval-container {
    .approval-header {
        position: relative;
        overflow: hidden;
        height: 42px;
        line-height: 42px;

        .approval-header-tag {
            display: inline-block;
            width: 130px;
            height: 42px;
            line-height: 42px;
            background-color: rgb(104, 192, 187);
            color: #fff;
            font-size: 22px;
            text-align: center;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &.type2 {
                background-color: #6b8fe3;
            }
        }

        .approval-heade-extra {
            font-size: 16px;
            color: #8f8f8f;
            cursor: pointer;

            &:hover {
                color: @primary-color;
            }
        }
    }
}

.approval-list {
    text-align: left;

    .ant-list-item {
        padding: 30px 0;
    }

    .ant-list-item-meta-content {
        padding-top: 5px;
    }

    .title {
        font-size: 16px;
        color: #595858;
    }

    .text {
        font-size: 16px;
        color: #949393;
    }

    .infos {
        font-size: 16px;
        color: #6c6c6c;

        i {
            color: #266fd2;
            margin-right: 8px;
        }
    }
}

.rencai-content {
    position: relative;
    height: 562px;
    background-color: #fff;
    box-shadow: 0px 2px 25px 0px rgba(38, 38, 38, 0.1);
    border-radius: 5px;
    border: 1px solid #e5e5e5;
    overflow: hidden;

    .img-box {
        position: relative;
        height: 160px;
        display: flex;
        align-items: center;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 0 25px;

        span {
            font-size: 22px;
            color: #000;
            line-height: 1.1;
            display: block;
        }

        em {
            font-style: normal;
            font-size: 16px;
            color: #999;
        }
    }

    .list1 {
        .row {
            position: relative;
            margin: 0 20px;
            padding: 15px 0;
            border-bottom: 1px dashed #e5e5e5;

            &:last-child {
                border-bottom: none;
            }

            .title1 {
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /* autoprefixer: off */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                padding-bottom: 5px;
                color: #333;
                cursor: pointer;

                &:hover {
                    color: #1f71e0;
                }

                .btn1 {
                    display: inline-block;
                    margin-left: 10px;
                    font-size: 13px;
                    color: #2fb279;
                    border: 1px solid #c2ead4;
                    background-color: #ebfff4;
                    height: 22px;
                    line-height: 20px;
                    border-radius: 22px;
                    padding: 0 10px;

                    &.end {
                        background-color: #ddd;
                        border-color: #ddd;
                        color: @white-color;

                        &.type1 {
                            background-color: @primary-color;
                            border-color: @primary-color;
                        }

                        &.type2 {
                            background-color: #ff9a1b;
                            border-color: #ff9a1b;
                        }

                        &.type3 {
                            background-color: #c9c9c9;
                            border-color: #c9c9c9;
                        }
                    }
                }
            }

            .text1 {
                font-size: 16px;
                color: #8c8686;

                i {
                    display: inline-block;
                    margin-right: 5px;
                    font-size: 18px;
                    color: #999;
                }
            }
        }
    }

    .more {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 18px;
        padding: 15px 0;
        text-align: center;

        border-top: 1px solid #e5e5e5;

        a {
            color: @gray-two-color;
            cursor: pointer;

            &:hover {
                color: @primary-color;
            }
        }
    }
}

.enter-content {
    position: relative;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    overflow: hidden;
    padding: 0 24px;
    height: 406px;

    .hd {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tit {
            display: inline-block;
            width: 130px;
            height: 42px;
            line-height: 42px;
            background-color: #68c0bb;
            color: #fff;
            font-size: 22px;
            text-align: center;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;

            &.blue1 {
                background-color: #6b8fe3;
            }
        }

        .more {
            font-size: 16px;
            cursor: pointer;

            &:hover {
                color: #1f71e0;
            }
        }
    }
}

.enter-list {
    .ant-row {
        padding: 25px 0;
        border-bottom: 1px dashed #e5e5e5;

        &:first-child {
            padding-top: 9px;
        }

        &:last-child {
            border-bottom: none;
        }
    }

    .title {
        font-size: 16px;
        color: #595858;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        /* autoprefixer: off */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding-bottom: 5px;
        padding-top: 3px;
        cursor: pointer;

        &:hover {
            color: #1f71e0;
        }
    }

    .time {
        padding-top: 20px;
        color: #999;

        i {
            display: inline-block;
            margin-right: 5px;
        }
    }

    .more {
        cursor: pointer;

        &:hover {
            color: #1f71e0;
        }

        i {
            display: inline-block;
            margin-left: 5px;
        }
    }
}

.color-555 {
    color: #555;
}

.jiedianposition {
    right: -52px !important;
    left: auto !important;
}

.subscribe-modal {
    min-width: 935px !important;
    max-width: 935px !important;
}

.basic-info-recurit {
    .ant-form-item-children {
        width: 100%;
        display: block;

        .ant-calendar-picker {
            width: 100%;
            height: 40px;

            .ant-calendar-picker-input {
                height: 40px;
            }
        }
    }

    .ant-select {
        width: 100% !important;
    }

    .ant-select-selection,
    .ant-select-selection__rendered {
        line-height: 40px;
        height: 40px;
    }
}

.info-article-item-btn {
    display: flex;
    justify-content: space-between;
}

.center-info-my {
    .ant-tabs-top-bar {
        display: none;
    }
}

.item-requiredselect {
    label:before {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: "*";
    }
}

.recriut-style {
    display: flex;
    justify-content: space-between;
}

.list-myproduct {
    display: flex !important;
    justify-content: space-between;
    width: 100%;
}

.my-sctivity-status {
    display: flex;
    justify-content: space-between;

    i {
        color: #fff;
        font-style: normal;
        padding: 4px;
    }
}

.list-down-title-cancle {
    display: flex;
    justify-content: space-between;

    button {
        height: 25px !important;
        padding: 0 6px !important;
    }
}

/* information-list*/
.information {
    .ant-card-body {
        padding: 20px;
    }
}

.information-infos {
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 24px;

    .ant-avatar {
        margin-bottom: 8px;
    }

    .information-name {
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
    }
}

.information-detail {
    div {
        i {
            margin-right: 10px;
        }

        span {
            display: inline-block;
            width: 140px;
            overflow: hidden;
            /*超出部分隐藏*/
            white-space: nowrap;
            /*不换行*/
            text-overflow: ellipsis;
            /*超出部分文字以...显示*/
        }

        span:hover {
            text-overflow: inherit;
            overflow: visible;
            white-space: pre-line;
            /*合并空白符序列，但是保留换行符。*/
        }

        em {
            font-style: normal;
            color: #c8c8c8;
        }
    }
}

/* information-list -end*/

/* END 排序用a标签 */

/* 评价tag */
.apply-modal-tag {
    margin: 8px 0 10px 0;
    max-height: 138px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    .tag-item {
        min-width: 50%;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding-bottom: 8px;

        &:nth-child(odd) {
            padding-right: 4px;
        }

        &:nth-child(even) {
            padding-left: 4px;
        }

        &.active {
            span {
                background-color: @primary-color;
                color: @white-color;
            }
        }
    }

    span {
        background-color: #f2f2f2;
        color: #666;
        padding: 0 6px;
        border-radius: 5px;
        font-size: 14px;
        height: 38px;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        box-sizing: border-box;
    }
}

.guoji-search {
    .ant-select-selection--single {
        height: 40px;
        line-height: 40px !important;
    }

    .ant-select-selection__rendered {
        line-height: 40px !important;
    }
}

/*END 评价tag */

.card-tag {
    border-radius: 2px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 2px 8px;
    -webkit-justify-content: center;
    justify-content: center;
    float: left;
    font-size: 14px;
    white-space: nowrap;
}

.margin-right-xs {
    margin-right: 5px !important;
}

.card-tag.tg-color-c {
    background: rgba(74, 190, 189, 0.12);
    color: #4abebd;
}

.step_rate {
    margin-bottom: 0 !important;

    & li {
        margin: 0 !important;
        padding: 0 !important;
    }
}

.link-color {
    color: #096dd9 !important;
}

.link-color-red {
    color: red !important;
}

.types_1 {
    .ant-descriptions-item-container .ant-descriptions-item-content {
        display: inline-block;
    }
}
