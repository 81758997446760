@import "themes/default";

.header-content {
    background-color: #fff;
    color: @gray-two-color;
    padding: 11px 0;
    border-top: 4px transparent solid;
    border-image: linear-gradient(-90deg, #68c19d 0%, #5dccbd 25%, #5bc1d3 50%, #4994fe 75%, #4476d8 100%) 1 10;

    .user-info {
        font-size: 14px;

        .info-img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            margin-right: 12px;
            display: inline-block;
            vertical-align: middle;
        }

        em {
            margin: 0 5px;
        }

        .sign-out {
            color: @gray-two-color;
            cursor: pointer;

            &:hover {
                color: @primary-color;
            }
        }

        &.header-login-white {
            > a,
            > span {
                color: #fff;
            }
        }
    }

    .name {
        color: @gray-two-color;
        cursor: pointer;

        &:hover {
            color: @primary-color;
        }
    }

    .logo {
        img {
            display: block;
        }
    }
}

.header-search {
    .ant-input,
    .ant-btn {
        border-radius: 32px;
    }

    .ant-input {
        background-color: @white-color;
    }

    .ant-input-group .ant-input {
        text-align: left;
    }
}

/*个人中心头部*/
.header-top {
    background-color: #f3f3f3;
    height: 40px;
    line-height: 40px;

    .back-home {
        float: left;
        font-size: 14px;
        color: #5d5d5d;

        .icon {
            margin-right: 10px;
        }

        &:hover {
            color: @primary-color;
        }
    }

    .top-nav {
        float: right;

        a {
            font-size: 14px;
            color: #878787;

            &:hover {
                color: @primary-color;
            }
        }

        span {
            margin: 0 10px;
            color: #b7b7b7;
        }
    }
}

.my-header-content {
    background-image: none;
    background-color: @white-color;
    padding: 20px 0;
    height: 83px;

    .logo img {
        max-height: 40px;
    }

    .header-search {
        margin: 2px 0 0 0;

        .ant-input {
            border-radius: 4px 0 0 4px;
        }

        .ant-btn {
            border-radius: 0 4px 4px 0;
        }
    }
}

.my-content {
    min-height: calc(~"100vh - 245px");
}

@media (max-width: 1400px) {
    .my-header-content {
        height: 75px;
    }

    .my-content {
        min-height: calc(~"100vh - 187px");
    }
}

/*个人中心头部end*/

.home-logo {
    display: none;
}
.home-head {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
    .nav {
        display: none;
    }
    .header-content {
        border-top: 0;
        background-color: rgba(11, 103, 109, 0.1);

        .name,
        .user-info .sign-out {
            color: #fff;
        }
    }
    .h-none {
        display: none;
    }
    .logo {
        display: none;
    }
    .home-logo {
        display: block;
    }
}
.header-content {
    .search-box {
        transition: all 0.3s;
        width: 100%;
        height: 30px;
        border-radius: 41px;
        i {
            color: #000000;
        }
        .anticon {
            display: none;
        }
    }
}
